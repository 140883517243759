import * as React from "react";
//import Link from "next/link";
import {
  CartPopupBody,
  PopupHeader,
  PopupItemCount,
  CloseButton,
  CheckoutButtonWrapper,
  CheckoutButton,
  Title,
  PriceBox,
  NoProductMsg,
  NoProductImg,
  ItemWrapper,
} from "./cart.style";
import { CloseIcon } from "assets/icons/CloseIcon";
import { ShoppingBagLarge } from "assets/icons/ShoppingBagLarge";
import { NoCartBag } from "assets/icons/NoCartBag";
import { CURRENCY } from "utils/constant";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Scrollbar } from "components/scrollbar/scrollbar";

import { CartItem } from "components/Cart/cart-item/CartItem";
import { Link } from "react-router-dom";
import { useMst } from "stores/RootStore";
//import Coupon from 'features/coupon/coupon';

type CartPropsType = {
  style?: any;
  className?: string;
  scrollbarHeight?: string;
};

const Cart: React.FC<CartPropsType> = ({
  style,
  className,
  scrollbarHeight,
}) => {
  const { shop, user } = useMst();

  return (
    <CartPopupBody className={className} style={style}>
      <PopupHeader>
        <PopupItemCount>
          {/* <ShoppingBagLarge width="19px" height="24px" /> */}
          <AddShoppingCartIcon />
          <span>
            {shop.cartItemsCount}
            {shop.cartItemsCount !== 1 ? (
              <span>פריטים</span>
            ) : (
              <span>פריט</span>
            )}
          </span>
        </PopupItemCount>

        <CloseButton onClick={shop.closeCart}>
          <CloseIcon />
        </CloseButton>
      </PopupHeader>

      <Scrollbar className="cart-scrollbar">
        <ItemWrapper className="items-wrapper">
          {!shop.isCartEmpty ? (
            shop.cart.map((item: CartItem) => (
              <CartItem
                key={`cartItem-${item.product.id}`}
                onIncrement={() => shop.addToCart(item.product.id, 1)}
                onDecrement={() => shop.removeFromCart(item.product.id, 1)}
                onRemove={() => shop.clearItemFromCart(item.product.id)}
                data={item}
              />
            ))
          ) : (
            <>
              <NoProductImg>
                <NoCartBag />
              </NoProductImg>
              <NoProductMsg>
                <span>לא נמצאו פריטים</span>
              </NoProductMsg>
            </>
          )}
        </ItemWrapper>
      </Scrollbar>

      <CheckoutButtonWrapper>
        {/* <PromoCode>
          {!coupon?.discountInPercent ? (
            <>
              {!hasCoupon ? (
                <button onClick={() => setCoupon((prev) => !prev)}>
                  <span>יש לך קוד קופון?</span>
                </button>
              ) : (
                <CouponBoxWrapper>
                  <Coupon
                    disabled={!items.length}
                    style={{
                      boxShadow: "0 3px 6px rgba(0, 0, 0, 0.06)",
                    }}
                  />
                </CouponBoxWrapper>
              )}
            </>
          ) : (
            <CouponCode>
              <FormattedMessage
                id="couponApplied"
                defaultMessage="Coupon Applied"
              />
              <span>{coupon.code}</span>
            </CouponCode>
          )}
        </PromoCode> */}

        {shop.cartItemsCount !== 0 ? (
          <Link to={"/checkout"} style={{ textDecoration: "none" }}>
            <CheckoutButton onClick={shop.closeCart}>
              <>
                <Title className="title">
                  <span>לקופה</span>
                  {user.isMarketer && (
                    <span>{`${shop.cartVp.toFixed(2)} vp`}</span>
                  )}
                </Title>
                <PriceBox>
                  {CURRENCY}
                  {shop.cartPrice.toFixed(2)}
                </PriceBox>
              </>
            </CheckoutButton>
          </Link>
        ) : (
          <CheckoutButton>
            <>
              <Title className="title">
                <span>לקופה</span>
              </Title>
              <PriceBox>
                {CURRENCY}
                {shop.cartPrice}
              </PriceBox>
            </>
          </CheckoutButton>
        )}
      </CheckoutButtonWrapper>
    </CartPopupBody>
  );
};

export default Cart;
