import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import LogoImg from "assets/images/footer-logo-05.png";
import Grid from "@mui/material/Grid";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import IconButton from "@mui/material/IconButton";
function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      sx={{ borderTop: "3px solid #fffa", pt: "1rem", color: "#fffa" }}
    >
      <Link color="inherit" href="/" sx={{ textDecoration: "none" }}>
        {" כל הזכויות שמורות לחברת הולד און  © "}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Footer = () => {
  return (
    <Box
      id="footer"
      sx={{
        py: 1,
        px: 2,
        mt: "auto",
        backgroundColor: "secondary.main",
        padding: "5px 10%",
        color: "#fff",
        textAlign: "start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <img src={LogoImg} alt="HoldOn" />
        </Box>
        <Box sx={{ alignSelf: "flex-start" }}>
          <Typography variant="h6" color="inherit">
            שירות לקוחות
          </Typography>
          <Typography variant="body1" component="div" color="inherit">
            <div>טל:04-8370738</div>
            <div>מייל:support@holdon.co.il</div>
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Typography variant="h6" color="inherit">
            מפת האתר
          </Typography>
          <Typography variant="body1" component="div" color="inherit">
            <div>
              <Link
                component={RouterLink}
                to="/"
                underline="hover"
                color="inherit"
              >
                דף הבית
              </Link>
            </div>
            <div>
              <Link
                component={RouterLink}
                to="/shop"
                underline="hover"
                color="inherit"
              >
                המוצרים
              </Link>
            </div>
            <div>
              <Link
                component={RouterLink}
                to="/about-us"
                underline="hover"
                color="inherit"
              >
                הסיפור שלנו
              </Link>
            </div>
            <div>
              <Link
                component={RouterLink}
                to="/myAccount"
                underline="hover"
                color="inherit"
              >
                חשבון שלי
              </Link>
            </div>
            <div>
              <Link
                component={RouterLink}
                to="/premium"
                underline="hover"
                color="inherit"
              >
                חבר מועדף - הסבר
              </Link>
            </div>
            <div>
              <Link
                component={RouterLink}
                to="/contact"
                underline="hover"
                color="inherit"
              >
                צור קשר
              </Link>
            </div>
          </Typography>
        </Box>
        <Box sx={{ mt: { xs: 2, sm: "unset" } }}>
          <IconButton
            href="https://www.facebook.com/holdonisrael/"
            aria-label="facebook"
          >
            <FacebookRoundedIcon />
          </IconButton>
          <IconButton
            href="https://www.instagram.com/holdon_israel"
            aria-label="instagram"
          >
            <InstagramIcon />
          </IconButton>
          <div>
            <Link
              component={RouterLink}
              to="/terms"
              underline="hover"
              color="inherit"
              sx={{ marginInlineStart: "20px", verticalAlign: "top" }}
            >
              תקנון האתר
            </Link><br/>
            <Link
              component={RouterLink}
              to="/accessibility"
              underline="hover"
              color="inherit"
              sx={{ marginInlineStart: "20px", verticalAlign: "top" }}
            >
              הצהרת נגישות
            </Link>
          </div>
        </Box>
      </Box>
      <Box>
        <Copyright />
      </Box>
    </Box>
  );
};

export default Footer;

// <AppBar position="static" color="secondary">
//   <Container maxWidth="md">
//     <Toolbar>
//       <Typography variant="body1" color="inherit">
//         © 2019 Gistia
//       </Typography>
//     </Toolbar>
//     <Copyright />
//   </Container>
// </AppBar>
