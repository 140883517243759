import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import RTL from "styles/RTL";
import { ThemeProvider } from "@mui/material/styles";
import theme from "styles/theme";
import CssBaseline from "@mui/material/CssBaseline";
import "styles/index.css";
import "overlayscrollbars/css/OverlayScrollbars.css";
import { MstProvider, rootStore } from "stores/RootStore";
import { setupAxiosInterceptors } from "api/api.service";
import Layout from "components/Layout/Layout";
import { BrowserRouter } from "react-router-dom";
import MyRoutes from "Routes";

console.log('starting shop app');
setupAxiosInterceptors();

ReactDOM.render(
  <React.StrictMode>
    <RTL>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MstProvider value={rootStore}>
        <div className="App">
          <BrowserRouter>
            <Layout>
              <MyRoutes />
            </Layout>
          </BrowserRouter>
        </div>
        </MstProvider>
      </ThemeProvider>
    </RTL>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
