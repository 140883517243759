import Box from '@mui/material/Box'
import useMediaQuery from "@mui/material/useMediaQuery";


type Props = {
  isMarketer:boolean
}

const PromoBanner = (props: Props) => {
  const isMarketer = props.isMarketer;
    const mobile = useMediaQuery("(max-width:580px)");

    function getPromoImage(): string | undefined {
        //return  isMarketer? '/images/shop/promo/promoD-2-marketer.png' : mobile ? '/images/shop/promo/promoM-3.png' : '/images/shop/promo/promoD-3.png';
        return  mobile ? '/images/shop/promo/till20241220-M.png' : '/images/shop/promo/till20241220-D.png';
      }
      if(isMarketer){
        return  null;
      }
  return (
    <Box sx={{
        width: mobile ? '100%' : '80%',
        margin:'auto'
    }}>
    <img
    src={getPromoImage()}
    className={mobile ? 'promoM' : 'promoD'}
    alt='promo banner'
    style={{ maxWidth: "100%", maxHeight: "100%" }}
  />
  </Box>
  )
}

export default PromoBanner