import * as React from "react";
import { Plus, Minus } from "assets/icons/PlusMinus";
import { CounterBox, CounterButton, CounterValue } from "./counter.style";
interface Props {
  onDecrement: (e: React.MouseEvent<HTMLElement>) => void;
  onIncrement: (e: React.MouseEvent<HTMLElement>) => void;
  value: number;
  variant?: string;
  className?: string;
}

export const Counter: React.FC<Props> = ({
  onDecrement,
  onIncrement,
  value,
  variant,
  className,
}) => {
  return (
    <CounterBox className={className}>
      <CounterButton
        onClick={onDecrement}
        variant={variant}
        className={`control-button ${variant}`}
      >
        <Minus />
      </CounterButton>
      <CounterValue>{value}</CounterValue>
      <CounterButton
        onClick={onIncrement}
        variant={variant}
        className={`control-button ${variant}`}
      >
        <Plus />
      </CounterButton>
    </CounterBox>
  );
};
