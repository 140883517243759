import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ProductPage from "./ProductPage";
import axios from "axios";

import Box from "@mui/material/Box";
import PriceRow from "components/Products/PriceRow";
import AddToCart from "components/Products/AddToCart";
import getProductImage from "utils/product-image";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useMst } from "stores/RootStore";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import getProductMock from "components/Products/mocks/getProductMock";

type Props = {
  id: number;
  //onClose:(event: object, reason?: string) => void
  onClose: () => void;
};

const ProductDetailsPopUp = (props: Props) => {
  const { shop } = useMst();
  const { id, onClose } = props;
  //const [open, setOpen] = React.useState(false);
  //const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  //!----------------------------------------------------------------

  const [loading, setLoading] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [product, setProduct] = useState<IProductDetails | undefined>(undefined);

  //!----------------------------------------------------------------
  useEffect(() => {
    if (id === 0) {
      onClose();
      return;
    }
    const product = shop.products.find((p) => p.id === id);
    if (product) {
    getProductDetails(id, product.vp,product.sellPrice, product.metaValue ? product.metaValue :"");
    }
    // eslint-disable-next-line
  }, [id]);

  const getProductDetails = async (id: number,vp:number,sellPrice:number,metaValue:string) => {
        const mock_product = getProductMock(id)
        if(mock_product){
        setProduct({...mock_product, vp:vp, sellPrice:sellPrice, metaPrice:metaValue});   
        }else{
          setProduct(getProductMock(id));
        }

    
  };

  return (
    <>
      {!!product && (
        <div className="product-card">
          <Dialog
            fullScreen={fullScreen}
            open={id > 0}
            onClose={onClose}
            scroll="paper"
            aria-labelledby="product-dialog-title"
            sx={{
              "& .MuiDialog-paper": {
                maxWidth: "960px",
              },
            }}
          >
            <DialogTitle id="product-dialog-title">
              <Typography
                variant="body1"
                sx={{
                  color: "mypink.main",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  lineHeight: "1.3",
                  paddingInlineEnd: "55px",
                }}
              >
                {product?.name}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  m: 0,
                  p: 2,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ width: !fullScreen ? "960px" : "unset" }} dividers>
              <Box>{product && <ProductPage product={{ ...product, id: id }} />}</Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-around" }}>
              {shop.isInCart(id) && (
                <Link to={"/checkout"} style={{ textDecoration: "none" }}>
                  <Button onClick={onClose} variant="outlined">
                    לקופה
                  </Button>
                </Link>
              )}
              <AddToCart id={id} />
              {shop.isInCart(id) && (
                <Button onClick={onClose} variant="outlined">
                  המשך קניות...
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      )}
      return{" "}
    </>
  );
};

export default observer(ProductDetailsPopUp);
