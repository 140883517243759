import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import { useDrawerContext } from "contexts/drawer-context";
import { Link as RouterLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useMst } from "stores/RootStore";
import { AUTH_TYPE } from "components/Auth/auth.type";
import { paths } from "route-paths";
import FavoriteIcon from "@mui/icons-material/Favorite";

interface Props {
  navLinks: { title: string; path: string; type?: string | undefined }[];
}

const SideDrawer: React.FC<Props> = ({ navLinks }) => {
  const { user } = useMst();

  const { open, handleMenuToggle } = useDrawerContext();

  const firstMenu = user.isAuthenticated
    ? {
        title: `שלום - ${user.firstName}, התנתק`,
        path: "/logout",
        type: "user",
      }
    : { title: "היכנס לאתר", path: "login", type: "anonymous" };

  const mobileNavLinks = [firstMenu, ...navLinks]; //, lastMenu

  const renderLink = (title: string, path: string, i: number) => {
    if (path === paths.registration) {
      return (
        <Link
          key={`${title}${i}`}
          sx={{
            color: "common.white",
            textDecoration: "none",
            "&:hover": { cursor: "pointer" },
            fontSize: "1rem",
          }}
          onClick={() => {
            user.setAuthType(AUTH_TYPE.REGISTER);
          }}
        >
          {title}
        </Link>
      );
      // } else if (path === paths.share) {
      //   return (
      //     <Link
      //       key={`${title}${i}`}
      //       sx={{
      //         color: "common.white",
      //         textDecoration: "none",
      //         fontSize: "1rem",
      //         cursor: "pointer",
      //       }}
      //       onClick={user.toggleLogin}
      //     >
      //       <span>{title} </span>
      //       <FavoriteIcon sx={{ fontSize: "1rem", color: "#f2a6c4" }} />
      //     </Link>
      //   );
    } else if (path === "login") {
      return (
        <Link
          key={`${title}${i}`}
          sx={{
            color: "common.white",
            textDecoration: "none",
            fontSize: "1rem",
            cursor: "pointer",
          }}
          onClick={user.toggleLogin}
        >
          {title}
        </Link>
      );
    } else {
      return (
        <Link
          key={`${title}${i}`}
          sx={{
            color: "common.white",
            textDecoration: "none",
            fontSize: "1rem",
          }}
          component={RouterLink}
          to={path}
        >
          {title}
          {path === paths.share && (
            <FavoriteIcon sx={{ fontSize: "1rem", color: "#f2a6c4" }} />
          )}
        </Link>
      );
    }
  };

  return (
    <>
      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleMenuToggle}
        sx={{
          ".MuiDrawer-paper": {
            bgcolor: "secondary.main",
            boxSizing: "border-box",
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Box
          sx={{
            width: 250,
            height: "100%",
            marginTop: "auto",
            marginBottom: "auto",
            paddingTop: "40px",
            justifyContent: "stretch",
            display: "flex",
            flexDirection: "column",
          }}
          role="presentation"
          onClick={handleMenuToggle}
          onKeyDown={handleMenuToggle}
        >
          {mobileNavLinks.map((link, i) => {
            const { title, path, type } = link;
            if (type === "anonymous" && user.isAuthenticated) return null;
            if (type === "user" && !user.isAuthenticated) return null;

            return (
              <Box
                key={i}
                sx={{
                  fontSize: "1rem",
                  borderBottom: "1px solid #eeee",
                  lineHeight: "3rem",
                }}
              >
                <Typography
                  variant="button"
                  key={`${title}${i}`}
                  sx={{
                    ml: 5,
                    my: 2,
                    textTransform: "uppercase",
                  }}
                >
                  {renderLink(title, path, i)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Drawer>
    </>
  );
};

export default observer(SideDrawer);
