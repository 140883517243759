import { useState } from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useMst } from "stores/RootStore";
import { useNavigate } from "react-router-dom";
import apiService from "api/api.service";
import LoadingButton from "@mui/lab/LoadingButton";
import { AUTH_TYPE } from "./auth.type";

export default function RegSuccess() {
  const { user } = useMst();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleOnClick = async () => {
    if (!user.regPhone || !user.regPassword) {
      user.setAuthType(AUTH_TYPE.LOGIN);
      return;
    }

    setLoading(true);

    try {
      const response = await apiService.post<any>("auth/login", {
        phone: user.regPhone,
        password: user.regPassword,
      });

      user.resetRegCredentials();
      user.login(response);
      user.afterLogin();
      if (user.isOpenLoginDialog) {
        user.closeLoginDialog();
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error("Login failed:", error.message);
      }
      user.setAuthType(AUTH_TYPE.LOGIN);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        margin: "auto",
        padding: 2,
        flexDirection: "column",
        alignItems: "stretch",
        textAlign: "start",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Typography component="h6" variant="body1" color="primary.main">
          איזה כיף <br />
          אפשר כבר לרכוש ב 15% הנחה וליהנות ממוצרים שיהיו החברים הכי טובים שלך לניהול אורח חיים בריא בקלות ובהנאה.
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" color="secondary">
          כלקוח רשם יש לך גם הטבות נוספת שיהפכו את הרכישות הבאות לעוד יותר משתלמות. (אפשר לקרא על כך באתר)
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" color="inherit" paragraph>
          <strong>טיפ נוסף,</strong>
          <br />
          רכישת מארזים הרבה יותר משתלמת
        </Typography>
      </Box>
      <Box>
        <LoadingButton
          fullWidth
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleOnClick}
          loading={loading}
          sx={{ mt: 3, mb: 2, fontSize: "large" }}
        >
          הבנתי, תודה
        </LoadingButton>
      </Box>
    </Box>
  );
}
