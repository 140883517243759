import React from "react";
import { CURRENCY } from "utils/constant";
import CartPopupButton from "./CartPopupButton";
import { observer } from "mobx-react-lite";
import { useMst } from "stores/RootStore";

import Drawer from "@mui/material/Drawer";
import Cart from "./Cart";
import { CartSlidePopup } from "./cart.style";
import useMediaQuery from "@mui/material/useMediaQuery";
// const CartPopupStyle = createGlobalStyle`
//   .cartPopup{
//     top: auto !important;
//     left: auto !important;
//     bottom: 50px !important;
//     right: 50px !important;
//     box-shadow: ${themeGet("shadows.big", "0 21px 36px rgba(0, 0, 0, 0.16)")};
//     transform-origin: bottom right;

//     @media (max-width: 580px) {
//       max-width: none!important;
//       width: 100% !important;
//       bottom: 0 !important;
//       left: 0!important;
//       background: ${themeGet("colors.white", "#ffffff")};
//       overflow: initial !important;
//       transform-origin: bottom center;
//     }
//   }
// `;

interface Props {}

const CartPopUp = (props: Props) => {
  const { shop } = useMst();

  const mobile = useMediaQuery("(max-width:580px)");

  return (
    <>
      {mobile ? (
        <>
          {/* <CartPopupStyle /> */}
          <Drawer
            anchor="bottom"
            open={shop.cartIsOpen}
            onClose={shop.closeCart}
            variant="temporary"
            className="cartPopup"
            sx={{
              top: "auto !important",
              left: "auto !important",
              bottom: "50px !important",
              right: "50px !important",
              boxShadow: "0 21px 36px rgba(0, 0, 0, 0.16)",
              transformOrigin: "bottom right",
              "@media (max-width: 580px)": {
                maxWidth: "none!important",
                width: "100% !important",
                bottom: "0 !important",
                left: "0!important",
                background: "#ffffff",
                overflow: "initial !important",
                transformOrigin: "bottom center",
              },
            }}
          >
            <Cart scrollbarHeight="100vh" />
          </Drawer>
        </>
      ) : (
        <>
          <CartSlidePopup
            className={shop.cartIsOpen === true ? "cartPopupFixed" : ""}
          >
            {shop.cartIsOpen && <Cart scrollbarHeight="100vh" />}
          </CartSlidePopup>
        </>
      )}

      <CartPopupButton
        className="product-cart"
        itemCount={shop.cartItemsCount}
        itemPostfix={
          shop.cartItemsCount !== 1 ? <span>פריטים</span> : <span>פריט</span>
        }
        price={shop.cartPrice}
        pricePrefix={CURRENCY}
        onClick={shop.openCart}
      />
    </>
  );
};

export default observer(CartPopUp);
