import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  responsiveFontSizes,
} from "@mui/material/styles";
import { red } from "@mui/material/colors";

// declare module '@mui/material/styles' {
//   interface Theme {
//     pallete: {
//       pink: PaletteColorOptions;
//     };
//   }
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//     pallete: {
//       pink?: PaletteColorOptions;
//     };
//   }
// }

declare module "@mui/material/styles" {
  interface Palette {
    mypink: PaletteColor;
  }
  interface PaletteOptions {
    mypink?: PaletteColor;
  }
}

const { palette } = createTheme();
// A custom theme for this app
let theme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      main: "#283E9A",
    },
    secondary: {
      main: "#2eaa79",
      contrastText: "#fff",
    },
    error: {
      main: red.A400,
    },
    mypink: palette.augmentColor({ color: { main: "#f2a6c4" } }),
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: "0 !important",
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);
export default theme;

//2eaa79 green
//112783 blue #283E9A
//ee90b6 pink
//FDBDD7 pink
