import { Card, CardContent, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { CURRENCY, VP } from "utils/constant";
import getProductImage from "utils/product-image";
import { useMst } from "stores/RootStore";

interface Props {
  product: Product;
  qty: number;
}

export const Item: React.FC<Props> = ({ product, qty }) => {
  const { user } = useMst();
  return (
    <div>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "stretch",
              justifyContent: "space-between",
              bgcolor: "background.paper",
              textAlign: "start",
            }}
          >
            <Box
              sx={{
                maxWidth: { xs: "50px", sm: "100px" },
                maxHeight: { xs: "50px", sm: "70px" },
                marginInlineEnd: 1,
              }}
            >
              <img
                src={getProductImage(product.id, "sm")}
                className={"product-image"}
                alt={product.name}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </Box>
            <Box
              sx={{
                marginInlineEnd: 1,
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Typography variant="subtitle2">{product.name}</Typography>
              <Typography variant="body2">
                <span style={{ fontSize: "smaller" }}>
                  {product.sellPrice.toFixed(2)} {CURRENCY}
                </span>{" "}
                {user.isMarketer && (
                  <span style={{ fontSize: "smaller" }}>
                    {product.vp.toFixed(2)} {VP}
                  </span>
                )}
              </Typography>
            </Box>
            <Box sx={{ marginInlineEnd: 1, alignSelf: "center" }}>
              <Typography variant="body2">({qty})</Typography>
            </Box>
            <Box sx={{ alignSelf: "flex-end", flexShrink: 0 }}>
              <Typography variant="subtitle2">
                {(product.sellPrice * qty).toFixed(2)} {CURRENCY}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};
