import * as Yup from "yup";

const isEmailOrPhoneRegEx =
  /^((\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+)|((0)( |-)?([1-468-9]( |-)?\d{7}|(5|7)[0-9]( |-)?\d{7})))+$/;
const isEmailRegEx = /^(\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+)+$/;
const isPhoneRegEx =
  /^((0)( |-)?([1-468-9]( |-)?\d{7}|(5|7)[0-9]( |-)?\d{7}))+$/;

const isCellPhoneRegEx = /^((0)( |-)?((5)[0-9]( |-)?\d{7}))+$/;

const emailOrPhoneValidator = Yup.string().matches(
  isEmailOrPhoneRegEx,
  "מס טלפון או אימייל לא תקין"
);

const emailValidator = Yup.string()
  .trim()
  .matches(isEmailRegEx, "נא לוודא אימייל תקין");

const phoneValidator = Yup.string().matches(
  isCellPhoneRegEx,
  " נא לוודא טלפון נייד תקין"
);

const phoneCellValidator = Yup.string().matches(
  isCellPhoneRegEx,
  " נא לוודא טלפון נייד תקין"
);

const passwordValidator = Yup.string().required("נא להכניס סיסמה!");
const requiredValidator = Yup.string().required("שדה נדרש!");

export default {
  emailOrPhoneValidator,
  emailValidator,
  phoneValidator,
  phoneCellValidator,
  passwordValidator,
  requiredValidator,
};
