import Typography from "@mui/material/Typography";
import React from "react";

const PageTitle: React.FC = ({ children }) => {
  return (
    <Typography
      variant="h4"
      bgcolor="primary.main"
      color="#FFFE"
      sx={{
        lineHeight: 2,
      }}
    >
      {children}
    </Typography>
  );
};

export default PageTitle;
