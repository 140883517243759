import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import pic_h from "assets/images/site/man.png";
import pic from "assets/images/site/twoshakes.png";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "400px" },
  overflow: "scroll",
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type Props = {
  show: boolean;
  onClose: () => void;
  isH: boolean;
};
export default function InfoModal(props: Props) {
  const { show, onClose, isH } = props;

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={show}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "secondary",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "primary.main",
            }}
          >
            <Typography gutterBottom>
              מעכשיו ניהול אורח חיים בריא יהיה יותר קל, יותר מהנה ויותר משתלם
            </Typography>
            <Typography>
              <strong>הינה רק חלק מההטבות:</strong>
            </Typography>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText
                  primary="הנחת חברים"
                  secondary="באופן מידי יש לך 15% הנחה על כל הרכישות"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText
                  primary="קרדיטים על רכישות"
                  secondary="כלקוח פרמיום מקבלים קרדיט על כל הרכישות שזה כסף חזרה שעומד לרשותך באתר לרכישות הבאות"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText
                  primary="קרדיטים על רכישות של חברים"
                  secondary="כל החברים שיגיע בהמלצתך לאתר, ייהנו מכל הטוב הזה, ולך יהיה קרדיט על הרכישות שלהם לתמיד , מה שיעניק לך הנחה משמעותית עד כדי מוצרים חינם."
                />
              </ListItem>
            </List>
            אנחנו רוצים לעזור לך לנהל חיים בריאים ומהנים בקלות ובנוחות!
            <Box sx={{ flexGrow: 1 }}>
              {!isH && (
                <img
                  src={pic}
                  alt="הצטרף להולד-און"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    height: "100%",
                    width: "100%",
                  }}
                />
              )}
              {isH && (
                <img
                  src={pic_h}
                  alt="הצטרף להולד-און"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    height: "100%",
                    width: "100%",
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
