import { useState } from "react";
import { Box } from "@mui/material";
import CreatePassword from "./CreatePassword";
import CreateCode from "./CreateCode";

type ResetStep = "createCode" | "resetPassword";

function ResetPassword() {
  const [phone, setPhone] = useState<string>("");
  const [step, setStep] = useState<ResetStep>("createCode");

  const onCodeCreated = (phone: string) => {
    setStep("resetPassword");
    setPhone(phone);
  };

  return (
    <>
      <Box sx={{ minHeight: "350px" }}>
        {step === "createCode" && <CreateCode onCodeCreated={onCodeCreated} />}
        {step === "resetPassword" && (
          <CreatePassword phone={phone} registration={false} />
        )}
      </Box>
    </>
  );
}

export default ResetPassword;
