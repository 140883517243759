import { Box } from "@mui/material";
import React from "react";
import "./Terms.css";

interface Props {}

export const Terms = (props: Props) => {
  return (
    <Box sx={{ textAlign: "start", maxWidth: "800px", margin: "auto" }}>
      <h3 style={{ textAlign: "center" }}>
        <strong>
          <u>תקנון האתר</u>
        </strong>
      </h3>
      <p>
        <strong>
          <u>מבוא</u>
        </strong>
      </p>
      <p>
        אתרHoldon.co.il ("האתר") מופעל על ידי חברת "hold on" ("החברה") ומשמש גם בתור אתר מכירות לרכישת מוצרים וכן תוכנית ליווי
        ("המוצר").
      </p>
      <p>האמור בתקנון זה בלשון זכר הוא לשם הנוחות בלבד והתקנון מתייחס לבני שני המינים באופן שווה.</p>
      <p>הרכישה באמצעות האתר כפופה לתנאים המפורטים בתקנון זה.</p>
      <p>
        <strong>חשוב לציין - פרטי המשתמש לא מועברים לשום צד ג' ולא יעשה בהם שום שימוש מעבר לצרכי הפעלת האתר</strong>
      </p>
      <p>
        עצם רכישת המוצר באתר תהווה הצהרה מצד הלקוח כי קרא את הוראות תקנון זה, הבין אותם והסכים להם. התקנון מהווה חוזה מחייב בין
        הלקוח לבין החברה.
      </p>
      <p>
        ברכישת מוצרים, יצטרף שם הרוכש לרשימת לקוחות האתר אליה יישלח מפעם לפעם מידע שוטף בנוגע למוצרי החברה ומבצעים. באם אינך
        מעוניין בקבלת מידע באפשרותך להסיר את שמך מרשימת הלקוחות.
      </p>
      <p>
        <strong>
          <u>הרכישה</u>
        </strong>
      </p>
      <p>האתר מאפשר ללקוחות קנייה נוחה, קלה ובטוחה של המוצר באמצעות האינטרנט.</p>
      <p>ניתן לרכוש את המוצר באתר בכל עת עד לגמר המלאי.</p>
      <p>הרכישה באתר היא באמצעות כרטיס אשראי או בהעברה בנקאית והעסקה תתבצע לאחר אישורה על ידי חברת האשראי.</p>
      <p>מחיר המוצר באתר כולל את המע"מ.</p>
      <p>החברה שומרת לעצמה את הזכות להפסיק את שיווק ומכירת המוצר בכל עת וכן לשלול זכות רכישה באתר על פי שיקול דעתה.</p>
      <p>
        המידע באתר זה הינו כללי ואינו מהווה המלצה רפואית ו\או המלצה לטיפול ו\או המלצה לרכישה. המידע באתר אינו מיועד להנחות את
        הציבור לטיפול עצמאי ו\או הנחיה ללקיחה ו\או עצה לשימוש בתוסף תזונה כלשהוא ו\או שינוי ו\או הורדה של תרופה כלשהיא. המידע באתר
        זה אינו תחליף לייעוץ רפואי ו\או להמלצות הרופא המטפל.
      </p>
      <p>
        <strong>
          <u>מדיניות החזרות וביטולים</u>
        </strong>
      </p>
      <ol>
        <li>
          <strong>החזר מוצרי יהיה רק אם המוצר פגום.</strong>
        </li>
        <li>
          <strong>ביטול תוכניות ליווי -</strong>
          <p>
            תוכנית האימון והליווי הינה תוכנית אישית שכוללת הקניית ידע, כלים והכוונה לבניית שגרת תזונה מאוזנת ונוחה. הצלחת התוכנית
            מחייבת שיתוף פעולה, של המתאמן בתהליך, עם המאמן האישי. <u>בהעדר שיתוף</u> כזה, לא יהיה למאמן היכולת ליצור את השינוי
            והאחריות חלה על המתאמן בתוכנית.
          </p>
          <p className="strong">במידה והמתאמן בתוכנית ירצה לבטל את הרשמתו לתוכנית, הביטול יתאפשר ע״פ המתווה הבא:</p>
          <ol>
            <li>
              הודעה על ביטול תוכנית שתתבצע עד 24 שעות מרכישתה <u>יתבצע החזר מלא</u>.
            </li>
            <li>
              הודעה על ביטול תוכנית שתתבצע לאחר 24 שעות מרכישתה, החברה תחייב דמי ביטול בשווי 5% מסכום העיסקה + דמי תקופת הליווי +
              עלות המוצרים שהיו בשימוש וזאת באופן הבא:
              <ol>
                <li>
                  <strong>חיוב על תקופת הליווי:</strong>
                  <p>- בהודעה על סיום התקשרות בטווח של עד 30 יום, ייגבו 920 ש"ח</p>
                  <p className="strong">- לאחר 30 יום - לא ניתן לבטל ולכן לא יהיה החזר כספי</p>
                </li>
                <li>
                  <strong>חיוב על מוצרים:</strong>
                  <p>
                    - לא יהיה חיוב עבור מוצרים <strong>שיוחזרו בטווח של 30 יום מההרשמה לתוכנית כשהם סגורים</strong>. להבהרה, רק
                    מוצרים שיוחזרו סגורים באריזתם, לא יחויבו.
                  </p>
                  <p>
                    - עבור מוצרים שהיו בשימוש או שלא הוחזרו עד 30 מיום תחילת התוכנית, החיוב יהיה ע״פ מחיר המוצרים המופיע באתר הולד
                    און.{" "}
                  </p>
                  <p>- להבהרה - לא יהיה החזר עבור מוצרים שהוזמנו באופן עצמאי ע״י הלקוח (למעט אם היו פגומים).</p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>

      {/* <p>הודעה על ביטול תוכנית שתתבצע עד 24 שעות מרכישתה יתבצע החזר מלא</p>
      <p>הודעה על ביטל תוכנית שתתבצע לאחר 24 שעות מרכישתה יתבצע:</p>
      <ul>
        <li>החברה תחייב דמי ביטול בשווי 5% מסכום העסקה, בנוסף ייגבו 500 ש"ח עבור תקופת הליווי.</li>
        <li>מוצרים שלא יוחזרו לחברה יחוייבו בתשלום נוסף. להבהרה, רק מוצרים שיוחזרו סגורים באריזתם, לא יחויבו.</li>
        <li>הודעה על סיום התקשרות לאחר 14 יום- לא ניתן יהיה לבטל את התוכנית.</li>
      </ul>
      <p>*לא יינתן זיכוי נוסף בגין אי שימוש במוצרי ההשלמה התזונתית</p>
      <p>*הזיכוי יבוצע עד 30 ימי עסקים מיום קבלת מוצרי ההשלמה או מבקשת הביטול במידה ולא יוחזרו מוצרים.</p>
      <p>**החזר מוצרים יהיה רק אם המוצר פגום - מול חברת HOLD ON 04-8370738.</p> */}

      <p>
        <strong>
          <u>אספקה והובלת המוצר</u>
        </strong>
      </p>
      <p>החברה תדאג לאספקת המוצר ללקוח לכתובת שהוקלדה על ידו בעת ביצוע הרכישה באתר המכירות, תוך 7 ימי עסקים.</p>
      <p>החברה לא תהיה אחראית לכל איחור ו\או עיכוב באספקה, שנגרמה כתוצאה מכוח עליון ו\או מאירועים שאינם בשליטתה.</p>
      <p>מחיר המוצר אינו כולל את עלות המשלוח.</p>

      <p>
        <strong>
          <u>קרדיטים</u>
        </strong>
      </p>
      <p>
        לקוחות פרמיום, (לקוחות שצברו מעל ל-800₪ בקניה מצטברת) יקבלו קרדיטים המהווים כסף לרכישת מוצרים באתר. קרדיטים יתקבלו עבור כל
        רכישה אישית או רכישה של הלקוחות שהפנו לאתר דרך הקישור האישי שלך. סכום הקרדיטים שהקניה מזכה יהיה בהתאם לכתוב באתר בפרטי
        המוצר. הקרדיטים הינם אישיים, לא ניתנים להעברה לאחר, <strong>ותוקפם הוא ל 6 חודשים מיום הרכישה</strong>.
      </p>

      <p>
        <strong>
          <u>שירות לקוחות</u>
        </strong>
      </p>
      <p>בכל שאלה או בירור לגבי המוצר, ניתן לפנות בטלפון 04-8370738 אל נציגי השירות של החברה.</p>
      <p>
        <strong>
          <u>אחריות ושירות</u>
        </strong>
      </p>
      <p>
        חברת "hold on" רשאית להשתמש במידע שהמופיע בטופס הרכישה על מנת להביא ללקוח את המידע והשירותים המבוקשים, לתועלת קהל לקוחות
        החברה. מידע אישי זה לא ייחשף ולא ייעשה בו שימוש נוסף למטרות שיווקיות ללא רשות.
      </p>
      <p>חברת "hold on" לא תעביר פרטיי לקוחות לצד ג'.</p>
      <p>חברת "hold on" עשויה להשתמש במידע האישי לשם ביקורת פנימית למטרות תאימות ובטחון מידע.</p>
      <p>
        חברת "hold on" נוקטת באמצעי זהירות מקובלים על מנת לשמור, ככל האפשר, על סודיות המידע. במקרים שאינם בשליטת החברה ו\או
        הנובעים מכוח עליון, לא תהא החברה אחראית לכ נזק מכל סוג שהוא, ישיר או עקיף, שייגרם ללקוח, אם מידע זה אבד ו\או ייעשה בו
        שימוש לא מורשה.
      </p>
      <p>
        חברת "hold on" מתחייבת שלא לעשות שימוש במידע המסופק לה על ידי הלקוחות על מנת לאפשר את הרכישה בחנות הוירטואלית ובהתאם לכל
        דין.
        <br />
        <strong>
          <u>קניין רוחני</u>
        </strong>
      </p>
      <p>כל זכויות הקניין הרוחני באתר מכירות זה הינן רכושה הבלעדי של החברה "hold on".</p>
      <p>
        אין להעתיק, לשכפל, להפיץ, לפרסם או להשתמש בכל דרך אחרת במידע כלשהו מן האתר ו\או מאתר מכירות זה, אלא אם ניתנה הסכמה לכך
        מראש ובכתב מאת החברה.
      </p>
      <p>
        <strong>
          <u>דין וסמכות שיפוטית</u>
        </strong>
      </p>
      <p>
        פרשנותו ואכיפתו של תקנון זה ו\או כל פעולה או סכסוך הנובע ממנו ייעשו בהתאם לדין הישראלי בלבד ולבית המשפט המוסמך באחד
        מהמחוזות תהא מסורה סמכות השיפוט הבלעדית.
      </p>
      <p>
        <strong>
          <u>שימוש בקופון</u>
        </strong>
      </p>
      <p>השימוש בקופון ייעשה על ידי הלקוח באתר עד הסכום המצוין בקופון ובהתאם לתוקף הקופון.</p>
      <p>השימוש בקופון יהיה לשימוש המשתמש על שמו הופק הקופון בלבד ו לא ניתן להעביר את הקופון לצד ג' או מי מטעם המשתמש.</p>
      <p>סכום השימוש יופחת מסך ערך הקופון ליתרה.</p>
      <p>אין אפשרות להמיר את הקופון בכסף ו\או לקבל החזר כספי על הקופון.</p>
      <p>
        הקופון מאפשר קניית מוצרים באתר. ישנם מוצרים בהם מצוינת תקרה לקנייה בקופון, אזי לא ניתן יהיה להשתמש בקופון מעבר לתקרה זו.
      </p>
      <p>
        <strong>
          <u>שימוש בקרדיטים</u>
        </strong>
      </p>
      <p>השימוש בקרדיטים ייעשה על ידי הלקוח באתר עד לסכום המצוין בקרדיט ובהתאם לתוקף הקרדיט.</p>
      <p>השימוש בקרדיטים הינו לשימוש המשתמש על שמו התקבל הקרדיט.</p>
      <p>אין אפשרות להמיר את הקרדיט בכסף או לקבל החזר כספי על הקרדיט.</p>
      <p>הקרדיט מאפשר לקנות מוצרים באתר. לא ניתן להעביר קרדיט לצד ג' או מי מטעם המשתמש.</p>
    </Box>
  );
};
