import { useState } from "react";
import { Box, Typography, TextField, Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import validators from "utils/validators";
import * as Yup from "yup";
import apiService from "api/api.service";

function RecoverPassword() {
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [result, setResult] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setPhone(event.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    setResult("");

    try {
      validators.phoneValidator.validateSync(phone);

      const response = await apiService.post<string>(`auth/recover?phone=${phone}`);
      setResult(response);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setError(err.errors[0]);
      } else if (err instanceof Error) {
        if (err.message.includes("204")) {
          setError("טלפון לא קיים במערכת");
        } else {
          setError(err.message || "שגיאת התחברות");
        }
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box>
        <Typography variant="h6" color="initial">
          לקבלת או איפוס סיסמה הכנס טלפון איתו נרשמת למערכת
        </Typography>
        <div>
          <TextField
            id="phone"
            label="טלפון"
            value={phone}
            onChange={handleChange}
            error={!!error}
            helperText={error}
            size="small"
          />
        </div>
        <p>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            type="submit"
            onClick={handleSubmit}
          >
            שלחו לי סיסמה
          </LoadingButton>
        </p>
      </Box>
      {result && (
        <Box>
          <Alert severity="success">{result}</Alert>
        </Box>
      )}
    </>
  );
}

export default RecoverPassword;
