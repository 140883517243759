import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMst } from "stores/RootStore";
import { CURRENCY, VP } from "utils/constant";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import IconButton from "@mui/material/IconButton";
import { observer } from "mobx-react-lite";

interface Props {}

const BalanceRow = (props: Props) => {
  const { shop, user } = useMst();
  //const [balanceUsed, setBalanceUsed] = useState(0);
  const [message, setMessage] = useState("");
  const [flagBalance, setFlagBalance] = useState(false);
  const [openChangeCoupon, setOpenChangeCoupon] = useState(false);
  const [balanceStep, setBalanceStep] = useState("init");
  const [couponUsed, setCouponUsed] = useState(0);

  const setBalanceUsed = () => {
    //shop.setBalanceUsed();
    setBalanceStep("max");
    setFlagBalance(true);
  };
  const resetBalance = () => {
    shop.resetBalanceUsed();
    setBalanceStep("init");
    setFlagBalance(false);
  };

  const openChangeCouponForm = () => {
    setOpenChangeCoupon(true);
  };
  const closeChangeCouponForm = () => {
    setCouponUsed(shop.order.couponUsed);
    setOpenChangeCoupon(false);
  };

  const updateCouponUsed = () => {
    if (couponUsed > 0) {
      shop.setCouponUsed(couponUsed);
      setBalanceStep("some");
    }
    setOpenChangeCoupon(false);
  };
  const handleChangeCouponUsed = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCouponUsed(Math.min(Number(event.target.value), shop.order.couponUsed));
  };

  useEffect(() => {
    if (flagBalance) {
      shop.setBalanceUsed();
      setFlagBalance(false);
    }
  }, [flagBalance]);

  return (
    <>
      {user.creditBalance > 0 && (
        <Box
          sx={{
            backgroundColor: "#efffef",
            minHeight: "2rem",
            mt: 2,
            p: "10px",
            border: " 1px solid #2eaa79",
            textAlign: "start",
            position: "relative",
          }}
        >
          <Typography variant="subtitle2" color="initial" gutterBottom>
            יש לך יתרת זכות לקנייה בחנות של{" "}
            {user.isMarketer ? (
              <span>
                {user.coupon.toFixed(2)} {VP}
              </span>
            ) : (
              <span>
                {user.creditBalance.toFixed(2)} {CURRENCY}
              </span>
            )}
          </Typography>
          {!shop.balanceUsed ? (
            <Typography variant="subtitle2" sx={{ color: "secondary.dark" }} gutterBottom>
              לתשלום ביתרת זכות{" "}
              <Button onClick={setBalanceUsed} size="small" color="secondary" variant="contained">
                לחץ כאן
              </Button>
            </Typography>
          ) : (
            <>
              <Typography variant="subtitle2" sx={{ color: "secondary.dark" }} gutterBottom>
                ניצלת{" "}
                <span>
                  {shop.balanceUsed.toFixed(2)} {CURRENCY}
                </span>{" "}
                {user.isMarketer && (
                  <span>
                    {" "}
                    {shop.order.couponUsed.toFixed(2)} {VP}{" "}
                  </span>
                )}
                <span>לקניה זו</span> <br />
              </Typography>
              {!openChangeCoupon && (
                <Box>
                  <Box>
                    {user.isMarketer && (
                      <>
                        {" "}
                        <Button onClick={openChangeCouponForm} size="small" color="success" variant="contained">
                          נצל פחות...
                        </Button>{" "}
                      </>
                    )}
                    <Button onClick={resetBalance} size="small" color="warning" variant="contained">
                      בטל
                    </Button>
                  </Box>
                  <Typography variant="caption" color="#999" gutterBottom>
                    {balanceStep === "max" && <span> * המקסימום האפשרי לקניה זו</span>}
                    {balanceStep === "some" && <span> * לקניה זו השתמשת בקופונים בשווי:</span>}
                  </Typography>
                </Box>
              )}
              {openChangeCoupon && (
                <>
                  <Box>
                    <TextField
                      id=""
                      label=""
                      type="number"
                      variant="standard"
                      value={couponUsed}
                      style={{ maxWidth: "100px" }}
                      onChange={handleChangeCouponUsed}
                      InputProps={{
                        inputProps: {
                          max: shop.order.couponUsed,
                          min: 0,
                        },
                      }}
                    />{" "}
                    <Button onClick={updateCouponUsed} size="small" color="success" variant="contained">
                      שנה
                    </Button>{" "}
                    <Button onClick={closeChangeCouponForm} size="small" color="warning" variant="contained">
                      בטל
                    </Button>{" "}
                  </Box>
                </>
              )}
            </>
          )}
          {!openChangeCoupon && (
            <Typography gutterBottom variant="subtitle2" sx={{ position: "absolute", bottom: "5px", right: "20px" }}>
              {!!shop.balanceUsed && (
                <span>
                  {shop.balanceUsed.toFixed(2)} {CURRENCY} -
                </span>
              )}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default observer(BalanceRow);
