import React from "react";
import Box from "@mui/material/Box";

type Props = {
  icons: string[];
};

const ProductIcons = (props: Props) => {
  const { icons } = props;
  return (
    <>
      {icons.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-around",
          }}
        >
          {icons.map((icon) => (
            <Box sx={{ flex: 1, maxWidth: "90px" }} key={icon}>
              <img
                src={`/images/icons/${icon}.png`}
                alt={icon}
                style={{ width: "100%" }}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default ProductIcons;
