export const paths = {
  home: "/",
  about: "/about",
  contact: "/contact",
  share: "/share",
  shop: "/shop",
  tips: "/tips",
  shopCheckout: "/checkout",
  shopProducts: "/shop/products",
  shopCart: "/shop/cart",
  shopDone: "/shop/done",
  shopError: "/shop/error",
  coach: "/coach",
  coachOrderProgram: "/coach/orderProgram",
  logout: "/logout",
  login: "/auth/login",
  auth: "/auth",
  registration: "/register",
  resetPassword: "/auth/reset-password",
  myBiz: "/mybiz",
  myPayments: "/mybiz/myPayments",
  premium: "/premium",
  join: "/join/:refId",
  myAccount: "/MyAccount",
  notImplemented: "/not-implemented",
  terms: "/terms",
};
