import { FC, useEffect } from "react";
import { useMst } from "stores/RootStore";

import { Button, Typography, Hidden } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { observer } from "mobx-react-lite";

export const LoginPage: FC = observer(() => {
  const { user } = useMst();

  //const type = new URLSearchParams(window.location.search).get("type");

  useEffect(() => {
    user.toggleLogin();
  }, []);

  return (
    <>
      <Button variant="outlined" onClick={user.toggleLogin} sx={{ mt: 5 }}>
        <PersonIcon />
        <Typography variant="caption">
          {user.isAuthenticated ? (
            <>שלום {user.firstName} התנתק</>
          ) : (
            <>כניסה למערכת</>
          )}
        </Typography>
      </Button>
    </>
  );
});
