import IFramePage from 'components/IFramePage/IFramePage';
import React from 'react'

type Props = {}

const AboutPage = (props: Props) => {
    const src = "https://landing.fitk.co.il/our_story";
  return (
    <div>
      <IFramePage title="אודות החברה" src={src} />        
    </div>
  )
}

export default AboutPage