import { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { useMst } from "stores/RootStore";
import { Alert, Button, Box, Checkbox,Link,Dialog,DialogTitle , DialogContent, DialogActions ,FormControlLabel, Radio, RadioGroup  } from "@mui/material";
import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Terms } from "pages/Terms";

interface Props {}

const FundingAddressRow = (props: Props) => {
  const { shop } = useMst();
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleAgreementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreement(event.target.checked);
};

const handleOpenDialog  = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  event.preventDefault();  // Prevent the default action of the link
  setOpenDialog(true);
};

const handleCloseDialog  = () => {
  setOpenDialog(false);
};



  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    shop.setFundingId(parseInt(event.target.value, 10));
  };

  const onSubmit = () => {
    if(!agreement){
      shop.setError("יש להסכים לתנאים");
      return;
    }
    if (shop.orderToPay !== 0 && shop.order.fundingId !== 1 && shop.order.fundingId !== 2) {
      shop.setError("נא לבחור צורת תשלום");
      return;
    }
    if (shop.orderToPay === 0) {
      shop.setFundingId(3);
    }
    setLoading(true);
    shop.createOrder().finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
     <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="terms-dialog-title"
      >
        <DialogTitle id="terms-dialog-title">תקנון האתר</DialogTitle>
        <DialogContent dividers>
            <Terms />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>סגור</Button>
        </DialogActions>
      </Dialog>



    <Grid container spacing={2} sx={{ textAlign: "start" }}>
      {/* //` Shipping Address */}
      <Grid item xs={12} sm={6} sx={{ paddingInlineStart: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ mt: 2, fontWeight: 600 }}>
          פרטי משלוח
        </Typography>
        <Typography gutterBottom>{shop.fullName}</Typography>
        <Typography gutterBottom>{`${shop.order.address.street} ${shop.order.address.home}${
          shop.order.address.apartment ? " דירה " + shop.order.address.apartment : ""
        }`}</Typography>
        <Typography gutterBottom>{shop.order.address.city} </Typography>
        <Typography gutterBottom>{shop.order.address.phone} </Typography>
      </Grid>
      {/* //` Funding Type */}
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" gutterBottom sx={{ mt: 2, fontWeight: 600 }}>
          <span> בחר אמצעי תשלום</span>
        </Typography>

        {shop.orderToPay === 0 ? (
          <Typography variant="body1" color="initial">
            <strong>הזמנה ללא חיוב</strong> אין צורך בתשלום - בצע הזמנה ותקבל מוצרים
          </Typography>
        ) : (
          <>
            <RadioGroup
              aria-label="אמצעי תשלום"
              defaultValue={1}
              value={shop.order.fundingId}
              onChange={handleChange}
              name="radio-buttons-group"
            >
              <FormControlLabel value={2} control={<Radio />} label="הפקדה בנקאית" />
              <Typography variant="body1" color="initial">
                <strong>הפקדה בנקאית</strong> תקבל מספר הזמנה שאותה יש לציין בהפקדה לבנק ולהביא עם האישור
              </Typography>
              <FormControlLabel value={1} control={<Radio />} label="כרטיס אשראי" />
              <Typography variant="body1" color="initial">
                <strong> כרטיס אשראי</strong> תועבר לאתר מאובטח לתשלום והשלמת התהליך
              </Typography>
            </RadioGroup>
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
      <FormControlLabel
                control={
                    <Checkbox
                        checked={agreement}
                        onChange={handleAgreementChange}
                        name="termsCheckbox"
                        color="primary"
                    />
                }
                label={
                    <span>
                        אני מסכים ל
                        {' '}
                        <Link href="#" onClick={handleOpenDialog} underline="always">תקנון האתר</Link>
                    </span>
                }
            />

      </Grid>

      <Grid item xs={12} sx={{ mt: 3 }}>
        <Box margin={1}>{shop.error && <Alert severity="error">{shop.error}</Alert>}</Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => shop.setCheckoutStep(-1)}
            sx={{
              marginTop: 3,
              marginLeft: 1,
            }}
          >
            חזרה
          </Button>

          <LoadingButton
            variant="contained"
            color="primary"
            onClick={onSubmit}
            loading={loading}
            sx={{
              marginTop: 3,
              marginLeft: 1,
            }}
          >
            בצע הזמנה
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
    </>
  );
};

export default observer(FundingAddressRow);
