import { Box, Button, Typography } from "@mui/material";
import pic6 from "assets/images/site/shake6.gif";
import pic6h from "assets/images/site/shake6.gif";

type Props = {
  nextStep: () => void;
  lastStep: () => void;
  isH: boolean;
};

const Step6 = (props: Props) => {
  const { nextStep, lastStep, isH } = props;

  return (
    <Box>
      <Box>
        <Typography variant="h4" color="primary.main">
          <span>פשוט להכין וכיף לשתות</span>
        </Typography>
      </Box>
      <Box sx={{ maxWidth: "200px", margin: "auto" }}>
        {isH && <img src={pic6h} alt="shake-hold-on" width="100%" />}
        {!isH && <img src={pic6} alt="shake-hold-on" width="100%" />}
      </Box>
      <Box>
        <Typography variant="body1" color="initial">
          <span>
            לדוגמא, שייק הארוחה שלנו הוא מוצר ייחודי שב 2 דק׳ הכנה נותן לך ארוחה
            מושלמת מבחינה תזונתית, ערך קלורי נמוך <u>וטעים בטירוף</u>
          </span>
        </Typography>
      </Box>
      <Box sx={{ mb: 4, mt: 3, textAlign: "start", color: "secondary.main" }}>
        <ul>
          <li>22 ויטמינים ומינרלים חיוניים לתפקוד מיטבי</li>
          <li>כ 25 גר׳ חלבון איכותי לשמירת מסת שריר בריאה</li>
          <li>עשיר במגנזיום שחשוב לבריאות ולמערכת החיסונית</li>
          <li>כל זה בכ 200 קלוריות בלבד ומאוד מאוד טעים</li>
        </ul>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ fontSize: "1.5rem" }}
          onClick={nextStep}
        >
          הבנתי, מצויין
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography
          variant="body1"
          color="primary.main"
          onClick={lastStep}
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "1.2rem",
          }}
        >
          כבר מכיר, מעוניין להירשם
        </Typography>
      </Box>
    </Box>
  );
};

export default Step6;
