const name = "My-שיא";

const summary =
  '<div dir="rtl">' +
  "<p>" +
  "<strong>משקה צמחים וסיבים תזונתיים, עשיר בנוגדי חמצון עוצמתיים לשיפור תפקודים חיוניים רבים.</strong></p>" +
  "<p>משקה טעים ומרענן, המכיל רכיבים תזונתיים עוצמתיים, במינון אפקטיבי, המשתלבים יחד במשקה אחד שקל וכיף לשלב במהלך היום וליהנות מיתרונות הרכיבים.</p>" +
  "</div>";
const contentA =
  '<div dir="rtl">' +
  '<h2><span style="color: #ff9900;">10 רכיבים המובילים במשקה: </span></h2>' +
  "<p>*אינולין &ndash; מזון פרה ביוטיקה, ויטמין C, ויטמין A,&nbsp;ויטמין E, אבץ, סלניום, חומצה פולית, ביוטין, פוליפנולים ממיצוי תה ירוק, מיצוי גווארנה.</p>" +
  "<h3>רכיבים עוצמתיים במינון אפקטיבי שפועלים בסינרגיה ל:</h3>" +
  "<ul>" +
  "<li>הפחתת סימני ההזדקנות</li>" +
  "<li>בריאות מטבולית</li>" +
  "<li>חיזוק המערכת החיסונית</li>" +
  "<li>מראה צעיר וקורן&nbsp;</li>" +
  "</ul>" +
  "</div>";
const contentB =
  '<div dir="rtl"><table style="border-collapse: collapse; width: 100%;" border="0">' +
  '<tbody><tr><td style="width: 70px"><img src="/images/logos/simbol100.png" alt="/logo-h50.png" width="60"  /></td>' +
  "<td ><p>מותג: HoldOn</p></td></tr>" +
  '<tr><td style="width: 70px"><img src="/images/icons/gmp.png" alt="gmp.png" width="60"  /></td>' +
  "<td><p>אישור משרד הבריאות תקן G.M.P</p></td></tr>" +
  '<tr><td style="width: 70px"><img src="/images/icons/parve.png" alt="parve.png" width="60"  /></td>' +
  "<td><p>כשרות: הרבנות האזורית כפר פינס מנשה פרווה,</p></td></tr>" +
  "</tbody></table></div>";

const contentC =
  '<div dir="rtl">' +
  '<h4 style="text-align: center;"><span style="color: #333399;">הבוקר טוב שלך מתחיל עכשיו</span></h4>' +
  // '<div data-oembed-url="//player.vimeo.com/video/660205761?title=0&amp;amp;byline=0">' +
  // '<div style="height: 0; left: 0; padding-bottom: 52.1875%; position: relative; width: 100%;"><iframe style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" tabindex="-1" src="https://player.vimeo.com/video/660205761?h=0fe5ae3138&amp;app_id=122963&amp;byline=0&amp;badge=0&amp;portrait=0&amp;title=0" scrolling="no" allowfullscreen="allowfullscreen"></iframe></div>' +
  // "</div>" +
  "</div>";
const faqs = [
  {
    title: "מה יתרונות של מרכיבי My-שיא?",
    body:
      '<div dir="rtl">' +
      "<ul>" +
      "<li>נלחמים ברדיקאליים החופשיים (נוגדי חמצון חזקים) </li>" +
      "<li>מזינים את חיידקי המעיין הטובים וגורמים להם להתרבות</li>" +
      "<li>מחזקים את המערכת החיסונית</li>" +
      "<li>מפחיתים סימני הזדקנות</li>" +
      "<li>מזינים ומשפרים את מראה העור</li>" +
      "<li>מחזקים את שורש השיער</li>" +
      "<li>מגבירים את הפעילות המטאבולית ומאיצים את שריפת הקלוריות, </li>" +
      "<li>תומכים בתהליך ירידה במשקל</li>" +
      "<li>מפחיתים עייפות ומשפרים את יכולת הלמידה והריכוז </li>" +
      "</ul>" +
      "</div>",
  },
  {
    title: "מה מייחד את My-שיא ולמה לבחור בו?",
    body: `
    <div>
    <p><strong>
        פתרון פרקטי, טעים ומרענן לתמיכה תזונתית לשיפור תפקודים רבים.
    </strong></p>
    <p><strong>
        ריכזנו במשקה אחד, מעל 10 רכיבים חשובים, במינון אפקטיבי. קל לשלב, חיסכון עצום בעלות הכוללת והכי חשוב – נרגיש הבדל משמעותי במספר תיפקודים.
    </strong></p>
    <h3 style="color:#283E9A">הרכיבים שבמוצר, המינון והיתרונות שלהם:</h3>
    <ul>
        <li>
            <strong>אנולין</strong><span> -
                מזון פרה ביוטיקה הטוב ביותר לשגשוג חיידקי המעיים הטובים <br/> 5.3 גר׳ במנה כ 25% מהקצובה היומית
            </span>
        </li>
        <li>
            <strong>ויטמין C</strong><span> -
                נוגד חמצון עוצמתי ומחזק את המערכת החיסונית –<br/> 800 מ״ג במנה
            </span>
        </li>
        <li>
            <strong>ויטמין E</strong><span> -
                נוגד חמצון חיוני ביותר ששומר על בריאות העור-<br/> 10 מ״ג במנה כ 100% מהקצובה היומית
            </span>
        </li>
        <li>
            <strong>ויטמין A</strong><span> -
                <span>נוגד חמצון חיוני ביותר ששומר על בריאות העור-</span><br/>
                <span>700 מק״ג במנה כ 70% מהקצובה היומית </span>
            </span>
        </li>
        <li>
            <strong>אבץ</strong><span> -
                <span
                >נלחם בווירוסים וחיידקים ומגן מפני מחלות
                -</span><br/>
                <span>
                    15 מ״ג במנה כ 100% מהקצובה היומית
                </span>
            </span>
        </li>
        <li>
            <strong>ביוטין</strong><span> -
                <span>
                    מפחית נשירת שיער, מעודד צמיחה, משפר את בריאות העור ומחזק את הציפורניים
                -</span><br/>
                <span>
                    300 מק״ג במנה כ 300% מהקצובה היומית
                </span>
            </span>
        </li>
        <li>
            <strong>סילניום</strong><span> -
                <span>
                    נוגד חמצון חזק ביותר, עובד בסינרגיה עם ויטמינים C  ו E למלחמה ברדיקלים החופשיים
                -</span><br/>
                <span>
                    25 מק״ג במנה כ 40% מהקצובה היומית
                </span>
            </span>
        </li>
        <li>
            <strong>חומצה פולית </strong><span> -
                <span>
                    חשובה לתפקוד מערכת העצבים נקשרת למניעת מחלות לב, כלי דם וחולשה שיכלית (דימנציה)
                -</span><br/>
                <span>
                    250 מק״ג במנה כ 130% מהקצובה היומית
                </span>
            </span>
        </li>
        <li>
            <strong>פוליפנולים ממצוי תה ירוק </strong><span> -
                <span>
                    נוגדי חמצון חזקים, מסייע בעיכול, מסייע בהאצת שריפת השומן
                </span>
            </span>
        </li>
        <li>
            <strong>מיצוי גווארנה</strong><span> -
                <span>
                    צמח מיערות האמזונה שנותן  ערנות טבעית, מפחית עיפות ומשפר את הריכוז
                </span>
            </span>
        </li>
    </ul>
</div>
    `,
  },
  {
    title: "איך מכינים?",
    body: `<div dir="rtl">
      <strong>להוסיף כפית מדידה (8 גר׳) ל 500 מ״ל מים קרים או חמים (עד 80 מעלות) ולשתות לרוויה
</strong>
      </div>`,
  },
  {
    title: "פרטים על המוצר:",
    body: `<div dir="rtl" style="text-align: center;">
      <h3 style="text-align: center;">מידע על המוצר My-שיא</h3>
      <img src="/images/product/tavit105-1.png" alt="" style="width:100%;max-width:500px;" />
      <img src="/images/product/tavit105-2.png" alt="" style="width:100%;max-width:500px;" />
      <img src="/images/product/tavit105-3.png" alt="" style="width:100%;max-width:500px;" />
      </div>`,
  },
];

const images = [
  {
    original: "/images/shop/sm/105.png",
    thumbnail: "/images/shop/sm/105.png",
    //thumbnailHeight: 30,
    //originalHeight: 300,
  },
  {
    original: "/images/site/myc1.png",
    thumbnail: "/images/site/myc1.png",
    thumbnailHeight: 50,
  },
  {
    original: "/images/site/myc2.png",
    thumbnail: "/images/site/myc2.png",
    thumbnailHeight: 50,
  },
  {
    original: "/images/site/myc3.png",
    thumbnail: "/images/site/myc3.png",
    thumbnailHeight: 50,
  },
  {
    original: "/images/site/myc4.png",
    thumbnail: "/images/site/myc4.png",
    thumbnailHeight: 50,
  },
];

const icons = ["nosweets", "nogluten", "nopreservs", "kasher", "tevoni"];

export const product105 = {
  id: 105,
  name: name,
  summary: summary,
  contentA: contentA,
  contentB: contentB,
  contentC: contentC,
  vp: 183.0,
  priceNoVp: 0,
  sellPrice: 205,
  faqs: faqs,
  icons: icons,
  images: images,
  metaPrice: "",
};
