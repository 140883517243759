import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/system/Box";
import React from "react";
import { observer } from "mobx-react-lite";
import { useMst } from "stores/RootStore";
import { Item } from "./Item";
import { CURRENCY } from "utils/constant";
import Paper from "@mui/material/Paper";

interface Props {}

const ItemsRow = (props: Props) => {
  const { user, shop } = useMst();
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ mt: 2, fontWeight: 600 }}>
        פרטי ההזמנה
      </Typography>
      {shop.cart.map((item) => (
        <Item key={item.product.id} product={item.product} qty={item.qty} />
      ))}

      <Typography
        variant="subtitle2"
        color="initial"
        sx={{ textAlign: "end", p: 1 }}
      >
        <span>סה"כ ביניים: </span>{" "}
        <span style={{ fontWeight: 600, fontSize: "larger" }}>
          {shop.cartPrice.toFixed(2)} {CURRENCY}
        </span>
      </Typography>

      {user.isMarketer && (
        <Grid
          container
          spacing={1}
          sx={{ color: "secondary.dark", fontWeight: 600 }}
        >
          <Grid item xs={8} sx={{ textAlign: "start" }}>
            הנחת משווק <span>{user.discount}%</span>
          </Grid>{" "}
          <Grid item xs={4} sx={{ textAlign: "end" }}>
            {shop.discountUsed.toFixed(2)} {CURRENCY} -
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default observer(ItemsRow);
