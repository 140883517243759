export const hasRole = (
  allowedRoles?: string[],
  userRoles?: string[]
): boolean => {
  if (!allowedRoles || allowedRoles.length === 0) return true;
  if (!userRoles) return false;
  return userRoles.some((role) => allowedRoles.includes(role));
};

export const uuidCreate = () => {
  return (
    Math.random().toString(36).substring(2) + new Date().getTime().toString(36)
  );
};
