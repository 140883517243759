import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AUTH_TYPE } from "components/Auth/auth.type";
import { Link as RouterLink } from "react-router-dom";
import { useMst } from "stores/RootStore";
import { observer } from "mobx-react-lite";
import { paths } from "route-paths";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import FavoriteIcon from "@mui/icons-material/Favorite";
interface Props {
  navLinks: { title: string; path: string; type?: string | undefined }[];
}
const Navbar: React.FC<Props> = ({ navLinks }) => {
  const { user } = useMst();

  return (
    <Toolbar
      component="nav"
      variant="dense"
      sx={{
        display: { xs: "none", sm: "flex" },
        backgroundColor: "secondary.main",
        justifyContent: "space-between",
        overflowX: "auto",
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        sx={{ margin: "auto" }}
      >
        {navLinks.map(({ title, path, type }, i) => {
          if (type === "anonymous" && user.isAuthenticated) return null;
          if (type === "user" && !user.isAuthenticated) return null;
          if (path === paths.registration) {
            return (
              <Typography
                key={`${title}${i}`}
                variant="button"
                sx={{
                  color: "#fff",
                  opacity: "0.9",
                  textDecoration: "none",
                  "&:hover": { opacity: 1, cursor: "pointer" },
                }}
                onClick={() => {
                  user.setAuthType(AUTH_TYPE.REGISTER);
                }}
              >
                {title}
              </Typography>
            );
          }

          // if (path === paths.share) {
          //   return (
          //     <Typography
          //       key={`${title}${i}`}
          //       variant="button"
          //       sx={{
          //         color: "#fff",
          //         opacity: "0.7",
          //         display: "inherit",
          //         textDecoration: "none",
          //         "&:hover": { opacity: 1, cursor: "pointer" },
          //       }}
          //       onClick={() => {
          //         user.setAuthType(AUTH_TYPE.REGISTER);
          //       }}
          //     >
          //       <span>{title} </span>

          //     </Typography>
          //   );
          // }

          return (
            <Link
              component={RouterLink}
              key={`${title}${i}`}
              to={path}
              variant="button"
              sx={{
                color: "#fff",
                opacity: "0.7",
                textDecoration: "none",
                "&:hover": { opacity: 1 },
              }}
            >
              {title}
              {path === paths.share && (
                <FavoriteIcon sx={{ fontSize: "1rem", color: "#f2a6c4" }} />
              )}
            </Link>
          );
        })}
      </Stack>
    </Toolbar>
  );
};

export default observer(Navbar);
