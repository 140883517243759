import { Box } from "@mui/material";
import { DrawerContextProvider } from "contexts/drawer-context";
import React, { useEffect } from "react";
import { FOOTER_HEIGHT } from "utils/constant";
import Footer from "./Footer";
import Header from "./Header";
import LoginDialog from "pages/LoginDialog/LoginDialog";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useMst } from "stores/RootStore";
import { observer } from "mobx-react-lite";
import HeaderMt from "./HeaderMt";
//import AlertDialogSlide from "components/AlertDialog/AlertDialogSlide";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const Layout: React.FC = ({ children }) => {
  const { user } = useMst();
  const location = useLocation();
  const showMt = !user.isAuthenticated && location.pathname.indexOf("/join") > -1;
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (user.showAlert) {
      MySwal.fire({
        title: "לתשומת לבך!",
        text: user.alertMessage,
        icon: "warning",
        confirmButtonText: "סגור",
      }).then(() => {
        user.closeAlertDialog();
      });
    }
  }, [user.showAlert]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <DrawerContextProvider>
          {showMt && <HeaderMt />}
          {!showMt && <Header />}
        </DrawerContextProvider>
        <Box
          sx={{
            minHeight: `calc(100vh - ${FOOTER_HEIGHT}px)`,
          }}
        >
          {children}
        </Box>
        {!showMt && <Footer />}
      </Box>
      <LoginDialog />
      {/* <AlertDialogSlide /> */}
    </>
  );
};

export default observer(Layout);
