/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment } from "react";
import Textfield from "components/FormsUI/Textfield";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import validators from "utils/validators";
import { Grid, Container, Typography, Box, Button, FormControlLabel, Checkbox } from "@mui/material";
import { useMst } from "stores/RootStore";
import { Link } from "react-router-dom";

const FORM_VALIDATION = Yup.object().shape({
  firstName: Yup.string().required("נדרש"),
  lastName: Yup.string().required("נדרש"),
  phone: validators.phoneValidator.required("נא להכניס טלפון!"),
  street: Yup.string().required("נדרש"),
  home: Yup.string().required("נדרש"),
  apartment: Yup.string(),
  city: Yup.string().required("נדרש"),
  // zipcode: Yup.string()
  //   .required("נדרש")
  //   .matches(/^[0-9]+$/, "נא להכניס רק ספרות"),
});

//! ----------------------------------------------------------------
export default function AddressForm(): React.ReactElement {
  const { shop, user } = useMst();

  const onSubmit = (values: IShippingAddress, actions: any) => {
    actions.setSubmitting(false);
    shop.setAddress(values);
    shop.setCheckoutStep(1);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    shop.setAddressDefault(event.target.checked);
  };

  const initialAddress = shop.order.address.firstName
    ? shop.order.address
    : {
        //address: user.address,
        street: user.shippingAddress.street,
        home: user.shippingAddress.home,
        apartment: user.shippingAddress.apartment,
        city: user.shippingAddress.city,
        firstName: user.shippingAddress.firstName,
        lastName: user.shippingAddress.lastName,
        phone: user.shippingAddress.phone,
        zipcode: user.shippingAddress.zipcode ?? "",
        shipmentComment: user.shippingAddress.shipmentComment ?? "",
      };
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <Box
              sx={{
                marginTop: 5,
                marginBottom: 8,
              }}
            >
              <Formik
                initialValues={{
                  ...initialAddress,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={onSubmit}
              >
                <Form id="my-form" name="my-form">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        פרטי משלוח
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Textfield fullWidth name="firstName" label="שם פרטי" variant="outlined" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Textfield fullWidth name="lastName" label="שם משפחה" variant="outlined" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Textfield fullWidth name="phone" label="טלפון למשלוח" variant="outlined" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Textfield fullWidth name="city" label="עיר" variant="outlined" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Textfield fullWidth name="street" label="רחוב" variant="outlined" />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{ display: "flex", gap: "10px" }}>
                      <Textfield name="home" label="מס" variant="outlined" width="50%" />
                      <Textfield name="apartment" label="דירה" variant="outlined" width="50%" />
                    </Grid>
                    <Grid item md={12}>
                      <Textfield
                        fullWidth
                        name="shipmentComment"
                        label="הערות לכתובת המשלוח"
                        variant="outlined"
                        multiline
                      />
                    </Grid>
                    {/* <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        name="zipcode"
                        label="מיקוד"
                        variant="outlined"
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <FormControlLabel
                        label="שמור כתובת זו בתור ברירת מחדל למשלוחים הבאים"
                        control={
                          <Checkbox
                            //checked={shop.order.isAddressDefault}
                            value={shop.order.isAddressDefault}
                            onChange={handleCheckboxChange}
                            color="primary"
                            inputProps={{ "aria-label": "שמור כתובת זו" }}
                          />
                        }
                      />
                      {/* <CheckboxWrapper
                        name="updateAddress"
                        // sxlabel="הגדר ככתובת ראשית למשלוחים"
                        label="שמור כתובת זו למשלוחים הבאים"
                      ></CheckboxWrapper> */}
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 3,
                          ml: 1,
                        }}
                      >
                        <Button variant="outlined" component={Link} to="/">
                          חזרה לקניות...
                        </Button>
                        <Button variant="contained" color="primary" type="submit">
                          המשך לאישור הזמנה
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </Formik>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Fragment>
  );
}

// {true && ( //addressChanged
//                       <Grid item xs={12}>
//                         <CheckboxWrapper
//                           name="updateAddress"
//                           // sxlabel="הגדר ככתובת ראשית למשלוחים"
//                           label="שמור כתובת זו למשלוחים הבאים"
//                         ></CheckboxWrapper>
//                       </Grid>
//                     )}
