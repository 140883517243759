import IFramePage from "components/IFramePage/IFramePage";
import IframeDynamicHeight from "components/IframeFullHeight/IframeDynamicHeight";
import WordPressPage from "components/WordPressPage/WordPressPage";
import React from "react";

interface Props {}

const MealShake = (props: Props) => {
  const url = 'https://landing.fitk.co.il/product-page-3/';
  return (
    <IframeDynamicHeight title="שייק ארוחה" src={url} />
    // <IframeDynamicHeight title="דף הבית" src="https://info.holdon.co.il/meal-shake" />
    // <WordPressPage page_id="56" />
  );
};

export default MealShake;
