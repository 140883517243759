import { Box } from "@mui/material";
import React from "react";

type Props = {};

function Accessibility({}: Props) {
  return (
    <Box
      sx={{
        maxWidth: "800px",
        margin: "auto",
        textAlign: "start",
        fontSize: "large",
        padding: "10px",
        paddingInline: "15px",
      }}
    >
      <h1>הצהרת נגישות</h1>
      <p>תאריך עדכון הצהרת נגישות 20.11.2023</p>
      <p>
        הולד און בע&quot;מ שמה לעצמה למטרה לאפשר לכלל האוכלוסייה, כולל לאנשים בעלי מוגבלות זו או אחרת, נגישות מלאה לאתר האינטרנט
        ותכניו. החברה מאמינה בזכויות שוות והזדמנויות שוות להפקת אותה תועלת מתכני האתר ולכן פועל לקידום נגישות האתרים הישראלים
        והפיכתם למתאימים לכלל האוכלוסייה בישראל, על פי המצוין בחוק ובתקנות הנגישות. בנוסף להנגשת האתר, גם צוות החברה עובר בימים
        אלו הדרכה במתן שירות לקוחות נגיש.
      </p>

      <h3>הנגשת האתר</h3>
      <p>הנגשת האתר מתבצעת בהתאם לתקן הישראלי לנגישות 5568 והנחיות גוף התקינה הבין-לאומי W3C לרמה AA.</p>

      <p>
        היכן מפעילים את ההנגשה באתר?
        <br />
        בפינה ימנית התחתונה באתר holdon.co.il, נמצא כפתור &quot;הנגשה&quot;. בעת הקלקה על כפתור זה, יפתח תפריט המציע לגולש את
        מגוון סוגי ההנגשות האפשריים באתר. לאחר בחירת סוג ההנגשה הרצוי, והקלקה עליו, ייטען האתר במצב המונגש. להפסקת ההנגשה, יש
        ללחוץ על &quot;בטל הנגשה&quot; המופיע גם הוא בתפריט.
      </p>
      <h3>התאמות עיקריות</h3>
      <h4>צבעים:</h4>
      <p>שינוי ניגודיות צבעים על בסיס רקע כהה ו/או בהיר. התאמת האתר לעיוורי צבעים.</p>

      <h4>מקלדת:</h4>
      <p>מתן אפשרות ניווט על ידי המקלדת</p>
      <h4>תוכן האתר:</h4>
      <ul>
        <li>הנגשת התמונות באתר</li>
        <li>הנגשת תפריטים באתר</li>
        <li>הגדלת הפונט למספר גדלים שונים</li>
        <li>התאמת האתר עבור טכנולוגיות מסייעות</li>
        <li>הצהרת נגישות</li>
      </ul>
      <h4>משוב גולשים</h4>
      <p>
        צוות הולד און בע&quot;מ שואף לתת לכל לקוחותיו את השירות הטוב ביותר. במידה ובמהלך הגלישה נתקלתם בכל זאת במגבלה שהפריעה
        לגלישה או מנעה אותה לחלוטין, נשמח לשמוע על כך!
      </p>
      <p>אנא ידעו אותנו באמצעות טופס "צור קשר".</p>
      <p>האתר נבדק בקורא מסך מסוג NVDA והינו מותאם לגלישה בדפדנים הנפוצים ( כרום,מוזילה , אדג&#39; )</p>
      <h4>הסדרי נגישות במשרדי החברה:</h4>
      <p>כתובת המשרד לקבלת דואר: הכוכבים 58, קסריה 3070303</p>
      <h4>נגישות המקום:</h4>
      <ul>
        <li>אין משרדים לקבלת קהל</li>
        <li> המקום המחסן לא נגיש אין קבלת קהל</li>
        <li>שירות נעשה טלפונית וגם אונליין</li>
        <li>יש שירותי משלוח</li>
      </ul>

      <h4>המנגיש:</h4>
      <p>תהליך ההנגשה בוצע על ידי חברה להנגשת אתרים &quot;הנגשת אתרים לישראל&quot; בשיתוף עם חברת ואגס VGS בניית אתרים.</p>

      <h2>דרכי פנייה לבקשות והצעות שיפור בנושא נגישות</h2>
      <p>
         אם נתקלת בקושי כלשהו בקבלת שירות מאתנו עקב מוגבלותך, נשמח לקבל הערות
        והצעות ייעול:
      </p>
      <p>
        בדואר: לכבוד מחלקת פניות הציבור.
        <br />
        כתובת: הכוכבים 58, קסריה 3070303
        <br />
        בטלפון: 04-8370738 <br />
        בדואר אלקטרוני: support@holdon.co.il
      </p>
    </Box>
  );
}

export default Accessibility;
