import LoadingPage from "components/Other/LoadingPage";
import { LoginPage } from "pages/Auth";

import { FC, ReactElement, Suspense, useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import HomePage from "pages/HomePage";
import JoinPage from "pages/Registration/JoinPage";
import ShopPage from "pages/ShopPage";
import { TipsPage } from "pages/Tips";
import PremiumPage from "pages/PremiumPage";
import MyAccountPage from "pages/MyAccountPage";
import CheckoutPage from "pages/Checkout/CheckoutPage";
import { useMst } from "stores/RootStore";

import { paths } from "./route-paths";
import Logout from "components/Auth/Logout";
import { DonePage } from "pages/Checkout/Done";
import ErrorPage from "pages/Checkout/ErrorPage";
import { Terms } from "pages/Terms";
import Tip from "pages/Tips/Tip";
import ContactUs from "pages/ContactUs";
import SharePage from "pages/SharePage";
import AboutPage from "pages/AboutPage";
import { AUTH_TYPE } from "components/Auth/auth.type";
import MealShake from "pages/MealShake";
import Accessibility from "pages/Docs/Accessibility";
//~---------------------------------------------------------

function RequireAuth({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useMst().user;

  let location = useLocation();
  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth" state={{ from: location }} />;
  }

  return children;
}

const DefaultComponent: FC = (): ReactElement => (
  <div>
    <h2>בבניה - עדיין לא מומש.</h2>
  </div>
);

const MyRoutes: FC = () => {
  const user = useMst().user;
  const [searchParams] = useSearchParams();
  const refId = searchParams.get("refid");
  const ad = searchParams.get("ad");
  useEffect(() => {
    if (refId) {
      user.setJoinParams(refId, ad || "");
      user.setAuthType(AUTH_TYPE.REGISTER);
    }
  }, [refId, ad, user]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path={paths.home} element={<ShopPage />} />
        {/* <Route path={paths.tips} element={<TipsPage />} /> */}

        <Route path={paths.tips} element={<TipsPage />}>
          <Route path=":route" element={<Tip />} />
        </Route>

        <Route path={paths.auth} element={<LoginPage />} />
        <Route path={paths.logout} element={<Logout />} />
        <Route path={paths.shop} element={<ShopPage />} />
        <Route path={paths.premium} element={<PremiumPage />} />
        <Route path={paths.about} element={<AboutPage />} />
        <Route path={paths.join} element={<JoinPage />} />
        <Route path={paths.terms} element={<Terms />} />
        <Route path="myhome" element={<HomePage />} />
        <Route path="accessibility" element={<Accessibility />} />
        <Route path="meal-shake" element={<MealShake />} />
        <Route
          path={paths.shopDone}
          element={
            // <RequireAuth>
            <DonePage />
            // </RequireAuth>
          }
        />
        <Route path={paths.shopError} element={<ErrorPage />} />
        <Route
          path={paths.myAccount}
          element={
            <RequireAuth>
              <MyAccountPage />
            </RequireAuth>
          }
        />
        <Route
          path={paths.share}
          element={
            <RequireAuth>
              <SharePage />
            </RequireAuth>
          }
        />
        <Route
          path={paths.shopCheckout}
          element={
            <RequireAuth>
              <CheckoutPage />
            </RequireAuth>
          }
        />
        <Route path="contact" element={<ContactUs />} />
        <Route path="*" element={<DefaultComponent />} />
      </Routes>
    </Suspense>
  );
};

export default MyRoutes;
//~---------------------------------------------------------
