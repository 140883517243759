import { Fragment, useState } from "react";
import { useMst } from "stores/RootStore";
import * as Yup from "yup";

import { Grid, Alert, Button, Box } from "@mui/material";
import { observer } from "mobx-react-lite";

import ShovarRow from "./components/ShovarRow";
import BalanceRow from "./components/BalanceRow";
import TotalToPay from "./components/TotalToPay";
import ItemsRow from "./components/ItemsRow";
import FundingAddressRow from "./components/FundingAddressRow";

//`------------------------------------------------------------------

const FORM_VALIDATION = Yup.object().shape({
  funding: Yup.string().required("בחר צורת תשלום"),
});

function ReviewOrder(): React.ReactElement {
  const { user, shop } = useMst();

  return (
    <Fragment>
      <ItemsRow />
      <ShovarRow />
      <BalanceRow />
      <TotalToPay />
      <FundingAddressRow />
    </Fragment>
  );
}

export default observer(ReviewOrder);
