import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { StylisPlugin } from "@emotion/cache";
import createCache from "@emotion/cache";
import React from "react";

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin] as Array<StylisPlugin> | undefined,
});

const RTL: React.FC = ({ children }) => (
  <CacheProvider value={cacheRtl}>{children}</CacheProvider>
);

export default RTL;
