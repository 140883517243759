import styled from "@mui/system/styled";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { CURRENCY } from "utils/constant";

type CartButtonProps = {
  style?: React.CSSProperties;
  itemCount?: number;
  itemPostfix?: any;
  price?: number;
  pricePrefix?: string;
  className?: string;
  onClick?: (e: any) => void;
};

const CartPopupButtonWrapper = styled("button")`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #283e9a;
  padding: 0;
  border-radius: 6px 0 0 6px;
  box-shadow: 0 21px 36px rgba(0, 0, 0, 0.16);
  border: 0;
  outline: 0;
  cursor: pointer;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -46px;
  z-index: 99;

  @media (max-width: 580px) {
    height: 60px;
    flex-direction: row;
    padding-left: 30px;
    //border-radius: 60px;
    bottom: 50px;
    left: 50px;
    right: unset;
    top: unset;
    z-index: 999;
    width: calc(100% - 60px);
    height: 45px;
    padding: 2px 2px 2px 10px;
    bottom: 15px;
    left: 30px;
  }
`;

const TotalItems = styled("span")`
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  padding-left: 5px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  svg {
    margin-right: 10px;
  }
`;

const PriceBox = styled("span")`
  width: auto;
  height: 35px;
  min-width: 80px;
  overflow: hidden;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  color: #283e9a;
  margin: 0 10px 10px;
  @media (max-width: 580px) {
    width: 90px;
    height: 100%;
    height: 41px;
    margin-left: auto;
    margin-right: 0;
    //border-radius: 28px;
    margin-block: auto;
  }
`;

const CartPopupButton: React.FC<CartButtonProps> = ({
  itemCount,
  itemPostfix = "פריטים",
  price,
  pricePrefix = { CURRENCY },
  style,
  onClick,
  className,
}) => (
  <CartPopupButtonWrapper style={style} onClick={onClick} className={className}>
    <TotalItems>
      <ShoppingCartIcon />
      {itemCount}&nbsp;{itemPostfix}
    </TotalItems>
    <PriceBox>
      {pricePrefix}
      {parseFloat(`${price}`).toFixed(2)}
    </PriceBox>
  </CartPopupButtonWrapper>
);

export default CartPopupButton;
