//import css from "@styled-system/css";

//import styled,{ css } from "styled-components";

//import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
//import { css } from "@mui/system";
//import styled from "@mui/system/styled";

export const CounterBox = styled("div")(
  css({
    display: "flex",
    backgroundColor: "#283E9A",
    color: "white",
    fontSize: "base",
    fontWeight: "bold",
  }),
  {
    width: 104,
    height: 36,
    //borderRadius: 200,
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    flexShrink: 0,
    "&:focus": {
      outline: "none",
    },
    "&.horizontal": {
      width: 104,
      height: 36,
    },
    "&.vertical": {
      width: 30,
      height: 90,
      flexDirection: "column-reverse",
    },
    "&.lightVertical": {
      width: 30,
      height: 90,
      flexDirection: "column-reverse",
      backgroundColor: "#F7F7F7",
      color: "#0D1136",
    },
  }

  // variant({
  //   variants: {
  //     horizontal: {
  //       width: 104,
  //       height: 36,
  //     },
  //     vertical: {
  //       width: 30,
  //       height: 90,
  //       flexDirection: "column-reverse",
  //     },
  //     lightHorizontal: {
  //       width: 104,
  //       height: 36,
  //       backgroundColor: "#F7F7F7",
  //       color: "#0D1136",
  //     },
  //     lightVertical: {
  //       width: 30,
  //       height: 90,
  //       flexDirection: "column-reverse",
  //       backgroundColor: "#F7F7F7",
  //       color: "#0D1136",
  //     },
  //     altHorizontal: {
  //       width: 104,
  //       height: 36,
  //       borderRadius: "6px",
  //     },
  //     altVertical: {
  //       width: 30,
  //       height: 90,
  //       borderRadius: "6px",
  //     },
  //     full: {
  //       width: "100%",
  //       height: 36,
  //       borderRadius: "6px",
  //     },
  //   },
  // })
);

export const CounterButton = styled("button")<any>(
  {
    border: "none",
    backgroundColor: "transparent",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    padding: 10,
    cursor: "pointer",
    "&:hover, &:focus": {
      outline: "none",
    },
    "&.lightVertical": {
      color: "#77798C",
    },
  }

  // ,
  // variant({
  //   variants: {
  //     lightHorizontal: {
  //       color: "#77798C",
  //     },
  //     lightVertical: {
  //       color: "#77798C",
  //     },
  //   },
  // })
);

export const CounterValue = styled("span")({
  pointerEvents: "none",
});
CounterValue.displayName = "CounterValue";
CounterButton.displayName = "CounterButton";
CounterBox.displayName = "CounterBox";
