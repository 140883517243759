import { product100 } from "./ProductMock100";
import { product101 } from "./ProductMock101";
import { product102 } from "./ProductMock102";
import { product103 } from "./ProductMock103";
import { product104 } from "./ProductMock104";
import { product105 } from "./ProductMock105";
import { product303 } from "./ProductMock303";
import { product300 } from "./ProductMock300";
import { product306 } from "./ProductMock306";
import { product309 } from "./ProductMock309";
import { product310 } from "./ProductMock310";
import { product311 } from "./ProductMock311";
import { product312 } from "./ProductMock312";
import { product313 } from "./ProductMock313";
import { product314 } from "./ProductMock314";
import { product319 } from "./ProductMock319";
import { product320 } from "./ProductMock320";
import { product321 } from "./ProductMock321";
import { product308 } from "./ProductMock308";
import { product315 } from "./ProductMock315";
import { product316 } from "./ProductMock316";
import { product317 } from "./ProductMock317";
import { product318 } from "./ProductMock318";
import { product302 } from "./ProductMock302";
import { product307 } from "./ProductMock307";
import { product346 } from "./ProductMock346";
import { product386 } from "./ProductMock386";

const getProductMock = (id: number) => {
  switch (id) {
    case 100:
      return product100;
    case 101:
      return product101;
    case 102:
      return product102;
    case 103:
      return product103;
    case 104:
      return product104;
    case 105:
      return product105;
    case 303:
      return product303;
    case 300:
      return product300;
    case 302:
      return product302;
    case 306:
      return product306;
    case 307:
      return product307;
    case 308:
      return product308;
    case 309:
      return product309;
    case 310:
      return product310;
    case 311:
      return product311;
    case 312:
      return product312;
    case 313:
      return product313;
    case 314:
      return product314;
    case 315:
      return product315;
    case 316:
      return product316;
    case 317:
      return product317;
    case 318:
      return product318;
    case 319:
      return product319;
    case 320:
      return product320;
    case 321:
      return product321;
    case 346:
      return product346;
    case 386:
      return product386;

    default:
      return undefined;
  }
};

export default getProductMock;
