import { Outlet } from "react-router-dom";
import Tip from "./Tip";

const TipsMainPage = () => {
  return (
    <div>
      <h1>מאמרים וטיפים</h1>
    </div>
  );
};
export const tips = {
  twoMinShake: {
    route: "2minShake",
    title: "איך להכין שייק טעים ב 2 דקות",
    url: "https://lp.landing-page.mobi/index.php?page=landing&id=467581&token=f902ee80b4b7e6153931854e1e143b56",
  },
};

export const TipsPage = () => {
  return (
    <>
      {/* <div>
        <h1>מאמרים וטיפים</h1>
      </div> */}

      <Outlet />
      {/* <Routes>
        <Route
          path="/tips/2minShake"
          element={
            <Tip
              route={tips.twoMinShake.route}
              title={tips.twoMinShake.title}
              url={tips.twoMinShake.url}
            />
          }
        />

        <Route path="/tips" element={<TipsMainPage />} />
      </Routes> */}
    </>
  );
};
