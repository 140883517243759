import { types, Instance, flow, applySnapshot, getParent, cast } from "mobx-state-tree";
import { AUTH_TYPE } from "components/Auth/auth.type";
import { RootInstance } from "./RootStore";
import { AddressModel } from "./models";
import { CURRENCY } from "utils/constant";
import apiService from "api/api.service";


export const UserStoreInitialState = {
  userId: 0,
  discount: 0, //%
  coupon: 0, // VP
  credit: 0, //nis
  creditExpired: 0, //nis
  stars: 0, //nis
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  shopId: 0,
  //address: AddressModel,
  shippingAddress: {},
  street: "",
  home: "",
  apartment: "",
  city: "",
  zipcode: "",
  refId: "",
  roles: [],
  joinId: "",
  reference: "",
  regAnswers: "", //registration answers from flow
  isH: false,
  isMarketerExpiredSoon: false,
};

export const AuthTypeModel = types.model({
  step: types.string,
  title: types.string,
});

const showRenew = (expiredAt: string | null | undefined) => {
  let show = false;
  if (expiredAt) {
    const expired = new Date(expiredAt);
    if (expired && expired instanceof Date) {
      show = (expired.valueOf() - new Date().valueOf()) / (1000 * 60 * 60 * 24) < 60; // days till expired
    }
  }
  return show;
};

export const UserStore = types
  .model("UserStore", {
    userId: 0,
    discount: 0, //%
    coupon: 0, // VP
    credit: 0, //nis
    creditBalance: 0, //nis credit+coupon.toAmount
    creditExpired: 0, //nis
    stars: 0, //nis
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    shopId: 0,
    shippingAddress: types.optional(AddressModel, {}),
    address: "",
    street: "",
    home: "",
    apartment: "",
    city: "",
    zipcode: "",
    refId: "",
    roles: types.array(types.string), //types.optional(types.array(types.string), []),
    isOpenLoginDialog: false,
    authType: types.optional(AuthTypeModel, AUTH_TYPE.LOGIN),
    joinId: "",
    reference: "",
    regAnswers: "", //registration answers from flow
    regPhone: "", //registration phone
    regPassword: "", //registration password
    isH: false, //is haredy
    isMarketerExpiredSoon: false,
    showAlert: false,
    alertMessage: "",
    token: "",
  })
  .views((store) => ({
    get couponValue(): number {
      //nis
      return (
        store.coupon * (getParent(store) as RootInstance).settings.multiVpRateHandleFee //1.12
      );
    },

    get isAuthenticated() {
      return store.userId > 0;
    },
    get isMarketer() {
      return store.roles.includes("marketer");
    },
    get isPremium() {
      return store.roles.includes("premium");
    },
    get isCoach() {
      return store.roles.includes("coach");
    },
    get isAdmin() {
      return store.roles.includes("admin");
    },
    get isNoRole() {
      return store.roles.length === 0;
    },
    get discountMulti(): number {
      return (store.discount * (getParent(store) as RootInstance).settings.multiVpRateHandleFee) / 100;
    },
    get afterDiscountMulti() {
      return (100 - store.discount) / 100;
    },
  }))
  .views((store) => ({
    get balance() {
      return store.creditBalance; //store.couponValue + store.credit;
    },
  }))
  .actions((store) => ({
    reset() {
      applySnapshot(store, {
        ...UserStoreInitialState,
        joinId: store.joinId,
        reference: store.reference,
        isOpenLoginDialog: store.isOpenLoginDialog,
        authType: store.authType,
        isH: store.isH,
        //isMarketerExpiredSoon: store.isMarketerExpiredSoon,
        roles: [],
      });
    },
    logout() {
      (getParent(store) as RootInstance).reset();
      localStorage.removeItem("jwtToken");
    },
    login(value: IUser) {
      store.userId = value.userId;
      store.firstName = value.firstName || "";
      store.lastName = value.lastName || "";
      store.phone = value.phone || "";
      store.email = value.email || "";
      store.shopId = value.shopId;
      store.discount = value.discount;
      store.coupon = value.coupon;
      store.credit = value.credit;
      store.creditBalance = value.creditBalance;
      store.creditExpired = value.creditExpired;
      store.stars = value.stars ? value.stars : 0;
      store.roles.clear();
      store.roles = cast([...value.roles]);
      store.shippingAddress = value.shippingAddress ?? {};
      store.address = value.address || "";
      store.street = value.street || "";
      store.home = value.home || "";
      store.apartment = value.apartment || "";
      store.city = value.city || "";
      store.zipcode = value.zipcode || "";
      store.refId = value.refId;
      store.isMarketerExpiredSoon = store.isMarketer && value.expiredAt && showRenew(value.expiredAt);
    },
    openLoginDialog() {
      store.isOpenLoginDialog = true;
      store.authType = AUTH_TYPE.LOGIN;
    },
    afterLogin() {
      if (store.isMarketerExpiredSoon) {
        store.showAlert = true;
        store.alertMessage = `שים לב המנוי שלך כמשווק עומד לפוג בקרוב, \n ניתן לחדשו באתר המשרד של המשווקים`;
      } else if (store.creditExpired > 0) {
        store.showAlert = true;
        store.alertMessage = `שים לב ${CURRENCY}${store.creditExpired.toFixed(2)}  מיתרת הזכות עומדים לפוג בסוף החודש `;
      }
    },
    closeAlertDialog() {
      store.showAlert = false;
      store.alertMessage = "";
    },
    closeLoginDialog() {
      store.isOpenLoginDialog = false;
      store.authType = AUTH_TYPE.LOGIN;
    },

    setAuthType(type: { step: string; title: string }) {
      store.isOpenLoginDialog = true;
      store.authType = type;
    },
    setJoinParams(joinId: string, joinAd: string) {
      store.joinId = joinId;
      store.reference = joinAd;
    },
    setRegAnswers(answer: string) {
      store.regAnswers += ` | ${answer}`;
    },
    resetRegAnswers() {
      store.regAnswers = "";
    },
    setRegCredentials(phone: string, password: string) {
      store.regPhone = phone;
      store.regPassword = password;
    },
    resetRegCredentials() {
      store.regPhone = "";
      store.regPassword = "";
    },
    setIsH(isH: boolean) {
      if (store.isAuthenticated) return;
      store.isH = isH;
    },
  }))
  .actions((store) => ({
    authenticate: flow(function* (token: string) {
      if (token) {
        localStorage.setItem("jwtToken", token);
        try {
          const user: IUser = yield apiService.get<IUser>({ url: "auth/current" });
          store.login(user);
          return true; // Indicate successful authentication
        } catch (error) {
          console.log(error);
          store.logout();
          return false; // Indicate failed authentication
        }
      }
      return false; // No token provided
    }),
  }))
  .actions((store) => ({
    toggleLogin() {
      store.isOpenLoginDialog = true;
      store.authType = AUTH_TYPE.LOGIN;
      if (store.isAuthenticated) {
        store.logout();
      }
    },
  }))
  .actions((store) => ({
    updateUser: flow(function* updateUser() {
      try {
        const user: IUser = yield apiService.get({ url: "auth/current" });
        store.login(user);
      } catch (error) {
        console.log(error);
        store.logout();
      }
    }),
  }));

export interface UserType extends Instance<typeof UserStore> {}
