import React from 'react'

type Props = {
    src:string;
    title:string;   
}

const IFramePage = ({src,title}: Props) => {
  return (
    <div>
         <iframe
        src={src}
        title={title}
        height="2200"
        style={{ width: "100%", minHeight: "90vh" }}
      />
    </div>
  )
}

export default IFramePage