import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMst } from "stores/RootStore";

const Logout = (): React.ReactElement => {
  const navigate = useNavigate();
  const { user } = useMst();

  useEffect(() => {
    const performLogout = () => {
      user.logout();
      navigate("/");
    };

    performLogout();
  }, [user, navigate]);

  return <>Logging out...</>;
};

export default Logout;
