import React from "react";
import { Button, Card, CardActions, CardContent, CardMedia,  Grid, Typography } from "@mui/material";
import getProductImage from "utils/product-image";
import PriceRow from "./PriceRow";
import Box from "@mui/material/Box";
import parse from 'html-react-parser';
import { observer } from "mobx-react-lite";
//import { useMst } from "stores/RootStore";
import AddToCart from "./AddToCart";
import "./ProductCard.css";
interface Props {
  product: Product;
  showProductDetails?: (e: React.MouseEvent<HTMLElement>, id: number) => void;
}

// const promo227ids = [308,309,311,314,316,318,319,324,325,328];
//const promo20ids = [102,300,307];
const promo425ids = [315,317];
const promo195ids = [308,309,314];
const promoMyC = [346,347,348,349,350,351,352,386,389,390,391,392];
const currentPromo = [349,350,351,352,317,318,315,316,323,324,392,391,390,389];

const ProductCard: React.FC<Props> = ({ product, showProductDetails }) => {
  //const { user, shop } = useMst();

  const showDetails = (e: React.MouseEvent<HTMLElement>) => {
    if (showProductDetails) showProductDetails(e, product.id);
  };

  //const isPromo20 = //promo20ids.includes(product.id);
  const isPromo425 = promo425ids.includes(product.id);
  const isPromo195 = promo195ids.includes(product.id);
  const isPromo =  currentPromo.includes(product.id); //true; // isPromo195 || isPromo425;


  // const tempMessage1 = "עקב העליה בביקוש, אזל המלאי ";
  // const tempMessage2 = "וההספקה תיהיה רק בתחילת נובמבר";
  // const tempMessage21 = "המזמינים עכשיו יקבלו";
  // const tempMessage3 = "  שייק ארוחה נוסף ";
  return (
    <Grid item key={product.id} xs={12} sm={6} md={4}>
      <Card
        //onClick={onClick}
        className="product-card"
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          overflow: "visible",
        }}
      >
        <CardMedia
          //TODO show details popup
          sx={{
            pt: "20px",
            height: "250px",
            cursor: "pointer",
            position: "relative",
          }}
          onClick={showDetails}
        >
          {/* {!!product.promoId && (
            <Box
              sx={{
                position: "absolute",
                top: "5px",
                right: 0,
                rotate: "341deg",
                padding: "5px 10px",
                color: "secondary.main",
              }}
            >
              <span className="sale">מבצע</span>
            </Box>
          )} */}
          {isPromo && (
            <Box
              sx={{
                position: "absolute",
                top: "-17px",
                right: 0,
                rotate: "351deg",
                padding: "5px 10px",
                color: "secondary.main",
                textAlign:"right"
              }}
            >
               <img
                  src= {'/images/shop/promo/till20241220-P.png'}
                  className={"promo-image"}
                  alt={product.name}
                  style={{ maxWidth: "78%", boxShadow: "3px 3px 3px 1px #b6bab9" }}
                />
            </Box>
          )}

          <img
            src={getProductImage(product.id, "sm")}
            className={"product-image"}
            alt={product.name}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
          {/* {!!product.promoId && (
            <Box
              sx={{
                position: "absolute",
                bottom: "0",
                right: "0",
                width: "100%",
                backgroundImage: 'url("/images/shop/bg-sale-pink.png")',
                backgroundSize: "100% 100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: "50%",
                color: "#000",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    alignSelf: "center",
                    //fontSize: "14px",
                    width: "50%",
                  }}
                >
                  {product.promoTitle}
                </Box>
              </Box>
            </Box>
          )} */}
          {/* {(product.id === 319 || product.id === 320) && (
            <Box
              sx={{
                background: "#2eaa79",
                position: "absolute",
                bottom: 0,
                left: "5%",
                right: "5%",
                fontSize: "14px",
                padding: "5px",
                fontWeight: "bold",
                color: "#f7f7cb",
                boxShadow: "1px 1px 1px 2px #eeee",
              }}
            >
              {tempMessage1}
              <br />
              {tempMessage2}
              <br />
              {tempMessage21}
              <br />
              <strong>
                {tempMessage3}{" "}
                <span>
                  <u>במתנה</u>
                </span>
              </strong>
            </Box>
          )} */}
        </CardMedia>
        <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            sx={{ color: "primary.main", fontWeight: "600", minHeight: "2.5em" }}
          >
            {parse(product.nameHtml ? product.nameHtml : "")}
          </Typography>
          <PriceRow vp={product.vp} sellPrice={product.sellPrice} />
          <Typography component={'div'} sx={{ textAlign: "center", mt: 2, fontSize: "1",flex:1 }}>
            {parse(product.summaryHtml ? product.summaryHtml : "")}
          </Typography>
          {product.metaName === "1" ? (
            <div style={{ marginTop: "20px" }}>{parse(product.metaValue ? product.metaValue : "")}</div>
          ) : (
            <div style={{ marginTop: "20px" }}></div>
          )}
        </CardContent>
        <CardActions
          sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", gap: "20px", marginBottom: "20px" }}
        >
          <Button size="small" onClick={showDetails}>
            <strong>פרטים על המוצר</strong>
          </Button>
          <AddToCart id={product.id} />
        </CardActions>
      </Card>
    </Grid>
  );
};

export default observer(ProductCard);
