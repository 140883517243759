import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { observer } from "mobx-react-lite";
import { useTheme } from "@mui/material/styles";
//-----------------------------------------------------
import Login from "components/Auth/Login";
import ResetPassword from "components/Auth/ResetPassword";
import RecoverPassword from "components/Auth/RecoverPassword";
import Register from "components/Auth/Register";
import { AUTH_TYPE } from "components/Auth/auth.type";
import RegPromo from "components/Auth/RegPromo";
import RegSuccess from "components/Auth/RegSuccess";
import { useMst } from "stores/RootStore";
import { useState } from "react";
import Container from "@mui/material/Container";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    margin: theme.spacing(1),
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minHeight: "300px",
    //minWidth: "350px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: "secondary.main",
        color: "#fff",
        textAlign: "center",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fefefe",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

function LoginDialog() {
  const { user } = useMst();

  const handleClose = () => {
    user.closeLoginDialog();
  };

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={user.isOpenLoginDialog}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {user.authType.title}
      </BootstrapDialogTitle>

      <DialogContent dividers>
        <Container component="main" maxWidth="xs">
          {user.authType.step === AUTH_TYPE.LOGIN.step && <Login />}
          {user.authType.step === AUTH_TYPE.RESET_PASSWORD.step && (
            <ResetPassword />
          )}
          {user.authType.step === AUTH_TYPE.RECOVER_PASSWORD.step && (
            <RecoverPassword />
          )}
          {user.authType.step === AUTH_TYPE.REGISTER.step && <Register />}
          {user.authType.step === AUTH_TYPE.REG_PROMO.step && <RegPromo />}
          {user.authType.step === AUTH_TYPE.REG_SUCCESS.step && <RegSuccess />}
        </Container>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default observer(LoginDialog);
