import { Copyright } from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import Deposits from "components/Orders/Deposits";
import MyRefs from "components/Orders/MyRefs";
import Orders from "components/Orders/Orders";
import { useMst } from "stores/RootStore";

import { CURRENCY } from "utils/constant";
import Link from "@mui/material/Link";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { config } from "config";
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from "react-share";
function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

const MyAccountPage = () => {
  const { user } = useMst();
  const initialTooltip = "לחץ להעתיק";
  const [tooltip, setTooltip] = useState(initialTooltip);

  const handleTooltipClose = () => {
    setTooltip(initialTooltip);
  };

  const joinUrl = `https://holdon.co.il/join/${user.refId}`;
  const joinaUrl = `https://holdon.co.il/join/${user.refId}?a`;
  const siteUrl = `https://holdon.co.il/?refid=${user.refId}`;
  const tipShakeUrl = `https://holdon.co.il/tips/2minShake?refid=${user.refId}`;
  const wpMealShakeUrl = `https://landing.fitk.co.il/holdonmeal/?refid=${user.refId}`;
  return (
    <Container
      maxWidth="lg"
      sx={{ margin: "auto", mt: 4, mb: 4, textAlign: "center" }}
    >
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={7} lg={7}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              //height: 240,
              textAlign: "start",
            }}
          >
            <Typography
              component="h2"
              variant="h5"
              color="primary.main"
              gutterBottom
              fontWeight="bold"
            >
              {user.isMarketer
                ? "קישור אישי לצירוף לקוחות לחנות שלך"
                : "קישור אישי לצירוף חברים"}
            </Typography>
            {user.isMarketer ? (
              <>
                <Typography gutterBottom variant="body1" color="initial">
                  הקישור האישי הוא קישור (לינק) שמאפשר לך לצרף לקוחות כחברים
                  באתר.
                </Typography>
                <Typography gutterBottom variant="body1" color="initial">
                  כל הנרשמים דרך הקישור הזה יקבלו את כל ההטבות כחברים רשומים,
                  ויהיו רשומים בחנות שלך.
                </Typography>
              </>
            ) : (
              <>
                <Typography gutterBottom variant="body1" color="initial">
                  הקישור האישי הוא קישור (לינק) שמאפשר לך לצרף מכרים כחברים
                  לאתר.
                </Typography>

                <Typography gutterBottom variant="body1" color="initial">
                  כל הנרשמים דרך הקישור הזה יקבלו את כל ההטבות כחברים רשומים,{" "}
                  <strong>
                    ובתור הממליץ, תקבל קרדיט על כל הרכישות שלהם מעכשיו ולתמיד
                  </strong>{" "}
                  (כסף שעומד לרשותך באתר לרכישות) - מה שיעניק לך הנחה משמעותית
                  ברכישות הבאות עד כדי מוצרים חינם!
                </Typography>

                <Typography
                  sx={{ mb: 2 }}
                  gutterBottom
                  variant="body1"
                  color="initial"
                >
                  ככה עוזרים למי שיקר לנו והאורח החיים הבריא נהיה יותר משתלם.
                </Typography>
              </>
            )}

            <Typography
              variant="h6"
              gutterBottom
              fontWeight="bold"
              mt={3}
              color="primary"
            >
              רק ללחוץ ולשלוח (הקישור יועתק בלחיצה)
            </Typography>
            {user.isMarketer && (
              <Typography variant="body1" color="initial" fontWeight="bold">
                קישור אישי עם חלון לדף הרשמה מידי:
              </Typography>
            )}
            <Tooltip
              // disableFocusListener
              title={tooltip}
              onClose={handleTooltipClose}
            >
              <Typography
                component="p"
                onClick={() => {
                  navigator.clipboard.writeText(
                    user.isMarketer ? joinaUrl : joinUrl
                  );
                  setTooltip("הועתק");
                }}
                variant="h6"
                sx={{ backgroundColor: "#eeb" }}
              >
                {user.isMarketer ? joinaUrl : joinUrl}
              </Typography>
            </Tooltip>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight="bold"
              mt={3}>
                הקישור האישי הוא קישור (לינק) שמאפשר לך לצרף מכרים כחברים
                לאתר. קישור לכתבה על שייק ארוחה וחשיבותו לתזונה שלנו עם מזהה הפניה שלך.
              </Typography>
            <Tooltip
              // disableFocusListener
              title={tooltip}
              onClose={handleTooltipClose}
            >
              <Typography
                component="p"
                onClick={() => {
                  navigator.clipboard.writeText(
                    wpMealShakeUrl
                  );
                  setTooltip("הועתק");
                }}
                variant="h6"
                sx={{ backgroundColor: "#eeb" }}
              >
                {wpMealShakeUrl}
              </Typography>
            </Tooltip>
            <Box>
              <Typography variant="body2" color="initial">
                שתף את הקישור עם החברים שלך
              </Typography>
              <FacebookShareButton
                url={joinUrl}
                quote={config.shareQuote}
                hashtag="#holdon"
              >
                <FacebookIcon size={40} round={true} />
              </FacebookShareButton>
              <WhatsappShareButton
                url={joinUrl}
                title={config.shareQuote}
                separator=" | "
              >
                <WhatsappIcon size={40} round={true} />
              </WhatsappShareButton>
            </Box>
            {user.isMarketer && (
              <>
                <Box>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="initial"
                    mt={3}
                  >
                    קישור אישי ללא חלון ההרשמה:
                  </Typography>
                  <Tooltip
                    // disableFocusListener
                    title={tooltip}
                    onClose={handleTooltipClose}
                  >
                    <Typography
                      component="p"
                      onClick={() => {
                        navigator.clipboard.writeText(siteUrl);
                        setTooltip("הועתק");
                      }}
                      variant="h6"
                      sx={{ backgroundColor: "#eeb" }}
                    >
                      {siteUrl}
                    </Typography>
                  </Tooltip>
                  <Box>
                    <Typography variant="body2" color="initial">
                      שתף את הקישור עם החברים שלך
                    </Typography>
                    <FacebookShareButton
                      url={siteUrl}
                      quote={config.shareQuote}
                      hashtag="#holdon"
                    >
                      <FacebookIcon size={40} round={true} />
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url={siteUrl}
                      title={config.shareQuote}
                      separator=" | "
                    >
                      <WhatsappIcon size={40} round={true} />
                    </WhatsappShareButton>
                  </Box>
                </Box>

                <Box>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="initial"
                    mt={3}
                  >
                    קישור אישי לכתבה על שייק:
                  </Typography>
                  <Tooltip
                    // disableFocusListener
                    title={tooltip}
                    onClose={handleTooltipClose}
                  >
                    <Typography
                      component="p"
                      onClick={() => {
                        navigator.clipboard.writeText(tipShakeUrl);
                        setTooltip("הועתק");
                      }}
                      variant="h6"
                      sx={{ backgroundColor: "#eeb" }}
                    >
                      {tipShakeUrl}
                    </Typography>
                  </Tooltip>
                  <Box>
                    <Typography variant="body2" color="initial">
                      שתף את הקישור עם החברים שלך
                    </Typography>
                    <FacebookShareButton
                      url={tipShakeUrl}
                      quote={config.shareQuote}
                      hashtag="#holdon"
                    >
                      <FacebookIcon size={40} round={true} />
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url={tipShakeUrl}
                      title={config.shareQuote}
                      separator=" | "
                    >
                      <WhatsappIcon size={40} round={true} />
                    </WhatsappShareButton>
                  </Box>
                </Box>
              </>
            )}

            {/* <Typography
              color="secondary.main"
              sx={{ flex: 1, fontWeight: "bold" }}
            >
              בבניה: כאן יופיע כמות הקרדיטים שתצבור על הזמנות של החברים שלך
              <br />
              <span style={{ color: "#999" }}>
                קרדיטים שצברת על הזמנות של החברים שצירפת: {CURRENCY} XXXXX
              </span>
            </Typography>
            <div>
              <Link color="secondary.main" href="#" onClick={preventDefault}>
                בבניה: כאן יופיע כל החברים שיצטרפו דרך הלינק שלך <br />
                <span style={{ color: "#999" }}>
                  {" "}
                  כל החברים שהצטרפו דרכי לאתר
                </span>
              </Link>
            </div> */}
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        {/* <Grid item xs={12} md={5} lg={5}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
            }}
          >
            <Deposits />
          </Paper>
        </Grid> */}
        {/* Recent Orders */}
        {/* <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Orders />
          </Paper>
        </Grid>
      <Copyright sx={{ pt: 4 }} /> */}
      </Grid>
    </Container>
  );
};

export default observer(MyAccountPage);
