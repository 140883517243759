import { Typography, Box, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import { useMst } from "stores/RootStore";
import { AUTH_TYPE } from "./auth.type";

interface Props {}

const RegPromo = (props: Props) => {
  const { user } = useMst();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography gutterBottom mb={1}>
        מעכשיו ניהול אורח חיים בריא יהיה יותר קל, יותר מהנה ויותר משתלם
      </Typography>
      <Typography gutterBottom mb={1}>
        נרשמים כחברים (ללא עלות) לאתר HoldOn, נהנים ממוצרים הכי מתקדמים והכי
        טעימים שיש, מוצרים שיהיו החברים שיסייעו לך לנהל שגרת תזונה מאוזנת ובריאה
        בקלות ובהנאה.
      </Typography>
      <Typography gutterBottom>
        בהרשמה כחברים מקבלים 15% הנחה כבר מהקנייה הראשונה וזו רק ההתחלה!
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <img
          src="/images/two-with-shake-600.png"
          alt="הצטרף להולד-און"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            height: "100%",
            width: "100%",
          }}
        />
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Divider variant="middle" />
        <Button
          autoFocus
          sx={{ mt: 1 }}
          color="primary"
          variant="contained"
          onClick={() => {
            user.setAuthType(AUTH_TYPE.REGISTER);
          }}
        >
          להרשמה וקבלת כל ההטבות של החברים
        </Button>
      </Box>
    </Box>
  );
};

export default RegPromo;
