import Box from '@mui/material/Box';
import React, { useEffect, useRef } from 'react'

type Props = {
  title: string;
  src: string;
};// window.removeEventListener

const IframeDynamicHeight = (props: Props) => {
  // const iframeRef = useRef<HTMLIFrameElement>(null); 
  const [height, setHeight] = React.useState(4000);

  useEffect(() => {
    const handleMessage: EventListener =  (e: Event) =>  {
      const message = (e as MessageEvent<any>).data;
      console.log(message);
      if (message.height)
        setHeight(message.height);
        else
        console.log('no height');
    };    

    window.addEventListener('message',handleMessage);   
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Box id="iframe-wrapper" sx={{
      border: 0,
      //backgroundColor: '#282c34',
      pointerEvents: 'none',
      //zIndex: 2147483639,
     position: 'relative',
    // bottom: 0,
     width: '100%', 
     height: height,
     overflow: 'visible',
     //opacity: 1,maxWidth: '100%',right: 0,maxHeight: '100%'
    }}>
      <iframe
        src={props.src}
        title={props.title}
        // ref={iframeRef}
        style={{
          pointerEvents: 'all',backgroundColor: 'none',
          border: 0,width: '100%',height: '100%',position: 'absolute',
                    float:'none',inset:0,margin:0,padding:0,minHeight: '0',
          }}       
      />

</Box>
  ) 
  
}

export default IframeDynamicHeight