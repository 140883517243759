import Box from "@mui/material/Box";
import ImageGallery from "react-image-gallery";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PriceRow from "components/Products/PriceRow";
import getProductImage from "utils/product-image";
import ProductIcons from "components/Products/ProductIcons";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ProductPage.css";
import ProductFaqs from "components/Products/ProductFaqs";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
type Props = {
  product: IProductDetails;
};

const ProductPage = (props: Props) => {
  const { product } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Grid container spacing={1}>
        {/*//!------------------------------ */}
        <Grid item xs={12} md={6} container>
          <Grid item xs={12}>
            {/*//` 1 summary */}
            <Box>
              <div dangerouslySetInnerHTML={{ __html: product.summary }} />
            </Box>
          </Grid>
          {!fullScreen && (
            <Grid item xs={12}>
              {/*//` 3 icons */}
              <Box>
                <ProductIcons icons={product.icons} />
              </Box>
            </Grid>
          )}
          {!fullScreen && (
            <Grid item xs={12}>
              {/*//` 6 content A */}
              <Box>
                <div dangerouslySetInnerHTML={{ __html: product.contentA }} />
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid item sm={12} md={6} container>
          {/*//!------------------------------ */}
          <Grid item xs={12}>
            {/*//` 2 galerry */}

            <Box sx={{ mb: 2 }}>
              <ImageGallery
                items={product.images}
                isRTL
                showPlayButton={false}
                showFullscreenButton={false}
                thumbnailPosition={fullScreen ? "bottom" : "right"}
                additionalClass="product-image-gallery"
              />
            </Box>
          </Grid>
          {fullScreen && (
            <Grid item xs={12}>
              {/*//` 3 icons */}
              <Box>
                <ProductIcons icons={product.icons} />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            {/*//` 4 price */}
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <PriceRow vp={product.vp} sellPrice={product.sellPrice} />

              {product.metaPrice && (
                <Typography variant="body2" color="initial">
                  <div dangerouslySetInnerHTML={{ __html: product.metaPrice }} />                  
                </Typography>
              )}
              <Box sx={{ color: "secondary" }}>
                <Chip
                  icon={<LocalShippingIcon />}
                  label="משלוח מהיר עד הבית"
                  sx={{ marginInlineEnd: "20px", border: "none !important" }}
                  variant="outlined"
                  color="secondary"
                />
                <Chip
                  icon={<CreditScoreIcon />}
                  label="קניה מאובטחת"
                  variant="outlined"
                  //size="small"
                  color="secondary"
                  sx={{ border: "none !important" }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {/*//` 5 content B */}
            <Box sx={{ mt: 2 }}>
              <div dangerouslySetInnerHTML={{ __html: product.contentB }} />
            </Box>
          </Grid>
        </Grid>
        {fullScreen && (
          <Grid item xs={12}>
            {/*//` 6 content A */}
            <Box>
              <div dangerouslySetInnerHTML={{ __html: product.contentA }} />
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          {/*//` 7 FAQ */}
          <Box>
            <ProductFaqs faqs={product.faqs} />
          </Box>
        </Grid>
        {/* <Grid item sm={12} md={6}> */}
        {/*//` 8 REVIEWS */}
        {/* REVIEWS
        </Grid> */}
        <Grid item xs={12} md={12}>
          {/*//` 9 CONTENT C */}
          <Box>
            <div dangerouslySetInnerHTML={{ __html: product.contentC }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductPage;
