import { Box, Button, Typography } from "@mui/material";
import pic8 from "assets/images/site/vlogo300.png";

type Props = {
  nextStep: () => void;
};

const Step7 = (props: Props) => {
  const { nextStep } = props;

  return (
    <Box>
      <Box>
        <img src={pic8} alt="shake-hold-on" width="200px" />
        <Typography variant="h4" color="primary.main" gutterBottom>
          החבר שלך לאורח חיים בריא
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" color="initial">
          <span>איזה כיף, עשית צעד חשוב </span>
          <br />
          <span>בדרך לאורח חיים בריא </span>
          <br />
          <span>בקלות ובהנאה!</span>
        </Typography>
      </Box>
      <Box sx={{ mb: 4, mt: 4 }}>
        <Typography variant="h6" color="initial">
          בשלב הבא נצרף אותך לאתר כחבר
          <br />
          וכבר אפשר ליהנות
          <br />
          מכל הטוב הזה
        </Typography>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ fontSize: "1.5rem" }}
          onClick={nextStep}
        >
          להרשמה מהירה
        </Button>
      </Box>
    </Box>
  );
};

export default Step7;
