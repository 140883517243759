import {
  Instance,
  onSnapshot,
  types,
  applySnapshot,
  cast,
  onPatch,
} from "mobx-state-tree";
import { createContext, useContext } from "react";
import { SettingsStore, settingsStoreInitialState } from "./SettingsStore";
import { ShopStore, ShopStoreInitialState } from "./ShopStore";
import { UserStore, UserStoreInitialState } from "./UserStore";

export const RootModel = types
  .model("RootStore", {
    user: UserStore,
    shop: ShopStore,
    settings: SettingsStore,
  })
  .actions((self) => ({
    reset() {
      self.user.reset();
      self.shop.reset();
    },
  }));

let initialState = {
  user: UserStoreInitialState,
  shop: ShopStoreInitialState,
  settings: settingsStoreInitialState,
};

const data = localStorage.getItem("rootState");
if (data) {
  const json = JSON.parse(data);
  if (RootModel.is(json)) {
    console.log(json);
    // @ts-ignore
    initialState = json;
    //applySnapshot(rootStore, json);
  }
}

export const rootStore = RootModel.create(initialState);

onSnapshot(rootStore, (snapshot) => {
  //console.log("Snapshot: ", snapshot);
  localStorage.setItem("rootState", JSON.stringify(snapshot));
});
onPatch(rootStore, (patch) => {
  //console.log("Patch: ", patch);
});
export type RootInstance = Instance<typeof RootModel>;

const RootStoreContext = createContext<null | RootInstance>(null);

export const MstProvider = RootStoreContext.Provider;
export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}
