import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";

type Props = {
  faqs: IFAQ[];
};

const ProductFaqs = (props: Props) => {
  const { faqs } = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (id: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      const panel = `panel${id}`;
      setExpanded(isExpanded ? panel : false);

      //   setExpanded(false);
      // setTimeout(() => {
      //   setExpanded(isExpanded ? panel : false);
      // }, 300);

      // const el = document?.getElementById(panel);
      // console.log(el);
      // console.log(el.offsetTop);

      // if (ref && ref.current)
      //   window.scrollTo({ behavior: "smooth", top: ref.current.offsetTop });
      //el.focus();
      //window.scrollTo(0, ref.current.offsetTop); //moves the newly opened panel up offeset by 100px
    };

  return (
    <>
      {faqs.length > 0 && (
        <Box>
          <Typography variant="h4" color="secondary.main" gutterBottom>
            שאלות שיעניינו אותך:
          </Typography>
          {faqs.map((faq, i) => {
            return (
              <Accordion
                key={i}
                expanded={expanded === `panel${i}`}
                onChange={handleChange(i)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${i}`}
                  id={`panel${i}`}
                  sx={{ color: "primary.main" }}
                >
                  <Typography>{faq.title}</Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <div dangerouslySetInnerHTML={{ __html: faq.body }} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      )}
    </>
  );
};

// <div key={faq.title}>
//   <div>{faq.title}</div>
//   <div>{faq.body}</div>
// </div>
export default ProductFaqs;
