const name = "מארז 4 שייק ארוחה טבעוני";

const summary =
  '<div dir="rtl"><p><strong>החבר שיעזור לך לנהל אורח חיים בריא </strong><br />' +
  "פתרון פרקטי, מזין וסופר טעים לארוחה קלה ומאוזנת.&nbsp; <br />ארוחה עם ערך תזונתי גבוה וערך קלורי נמוך<br />מושלם כארוחת בוקר מזינה או כארוחה קלה אחרת כשרוצים להרגיש שבעים וקלילים.</p></div>";

const contentA =
  '<div dir="rtl">' +
  " <p>ארוחה קלה, שהיא סופר טעימה, עשירה בערכים תזונתיים שכוללים:</p>" +
  "<ul>" +
  "<li><strong>חלבון סויה מבודד איכותי </strong>שמסייע בשימור מסת שריר בריאה,</li>" +
  "<li><strong>22 וויטמינים ומינרלים חיוניים </strong>שעוזרים לתפקוד מיטבי,</li>" +
  "<li><strong>סיבים תזונתיים </strong>שחשובים למערכת עיכול בריאה</li>" +
  "<li><strong>פחמימות</strong> במידה הנכונה כדי לספק את האנרגיה הנחוצה ליום מושלם.</li>" +
  "</ul>" +
  "<p><strong>מושלם כארוחת בוקר מזינה, מעולה לאחריי פעילות ספורטיבית ו״ סוגר פינה״ לכל פעם שרוצים ארוחה קלה ומזינה משביעה תשאיר אותנו קלילים. </strong><strong></strong><strong>טעים במיוחד!!!</strong></p>" +
  "</div>";
const contentB =
  '<div dir="rtl"><table style="border-collapse: collapse; width: 100%;" border="0">' +
  '<tbody><tr><td style="width: 70px"><img src="/images/logos/simbol100.png" alt="/logo-h50.png" width="60"  /></td>' +
  "<td ><p>מותג: HoldOn</p></td></tr>" +
  '<tr><td style="width: 70px"><img src="/images/icons/gmp.png" alt="gmp.png" width="60"  /></td>' +
  "<td><p>אישור משרד הבריאות תקן G.M.P</p></td></tr>" +
  '<tr><td style="width: 70px"><img src="/images/icons/parve.png" alt="parve.png" width="60"  /></td>' +
  "<td><p>כשרות: הרבנות האזורית כפר פינס מנשה פרווה,</p></td></tr>" +
  "</tbody></table></div>";

const contentC =
  '<div dir="rtl">' +
  '<h4 style="text-align: center;"><span style="color: #333399;">פשוט להכין וכיף לשתות</span></h4>' +
  '<div data-oembed-url="//player.vimeo.com/video/660205761?title=0&amp;amp;byline=0">' +
  '<div style="height: 0; left: 0; padding-bottom: 52.1875%; position: relative; width: 100%;"><iframe style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" tabindex="-1" src="https://player.vimeo.com/video/660205761?h=0fe5ae3138&amp;app_id=122963&amp;byline=0&amp;badge=0&amp;portrait=0&amp;title=0" scrolling="no" allowfullscreen="allowfullscreen"></iframe></div>' +
  "</div>" +
  "</div>";
const faqs = [
  {
    title: "מה מייחד את שייק הארוחה ולמה לבחור בו?",
    body:
      '<div dir="rtl">' +
      "<p><strong>שייק הארוחה הוא פתרון פרקטי, מזין וסופר טעים לארוחה קלה ומאוזנת.&nbsp; </strong><strong><br /></strong><strong>ארוחה עם ערך תזונתי גבוה וערך קלורי נמוך.</strong></p>" +
      "<p><strong>מכיל:</strong></p>" +
      "<ul>" +
      "<li><strong>חלבון סויה מבודד איכותי </strong>שמסייע בשימור מסת שריר בריאה,</li>" +
      "<li><strong>22 וויטמינים ומינרלים חיוניים </strong>שעוזרים לתפקוד מיטבי,</li>" +
      "<li><strong>סיבים תזונתיים </strong>שחשובים למערכת עיכול בריאה</li>" +
      "<li><strong>פחמימות</strong> במידה הנכונה כדי לספק את האנרגיה הנחוצה ליום מושלם.</li>" +
      "</ul>" +
      "<p><u><strong>יתרונות עיקריים: </strong></u></p>" +
      '<ul style="list-style-type: circle;">' +
      "<li><strong>משלים קשת רחבה של מרכיבי תזונה חיוניים לתזונה מאוזנת ללא חסרים - </strong>לא צריך לדאוג: איזה וויטמינים, איזה מינרלים, כמה חלבון &ndash; <u>הכול כבר בפנים ובאופן&nbsp; מאוזן</u>.<br /><br /></li>" +
      "<li><strong>מחליף את אחת הארוחות במהלך היום &ndash; רוב הלקוחות מעדיפים כארוחת בוקר ואחרים מעדיפם כארוחה אחרת במהלך היום&nbsp; </strong>נותן מענה מושלם&nbsp; לארוחה מלאה תזונתית, בערך קלורי נמוך <u>וסופר טעימה<br /><br /></u></li>" +
      "<li><strong>אינו מכיל ממתיקים מלאכותיים, צבעי מאכל מלאכותיים וללא גלוטן<br /><br /></strong></li>" +
      "<li><strong>מיוצר בישראל ונושא את תו התקן </strong><strong>GMP</strong> - תקן מחמיר של משרד הבריאות לתנאי יצור ובקרת האיכות.<br /><br /></li>" +
      "<li><strong>כשר פרווה<br /><br /></strong></li>" +
      "<li><strong>מאוד קל להכין (כ 2 דק׳ הכנה)<br /><br /></strong></li>" +
      "<li><strong>משביע </strong><strong>לכ</strong><strong> 3-4 שעות ומשאיר אותנו קלילים עם אנרגיה</strong>.<br /><br /></li>" +
      "<li><u><strong>טעים במיוחד </strong></u><strong>עם אפשרויות גיוון נרחבות</strong></li>" +
      "</ul>" +
      "<p><em>שייק הארוחה </em>הוא בבסיסו בטעם קרמל עדין, כך שהוא שמשתלב מצוין עם כל דבר שנוסיף לו וישנה את טעמו בהתאם לתוספת. (מה שמאפשר לנו גיוון נרחב) <br /><strong>בזכות טעמו הנפלא והמגוון, קל וכיף לשלב את </strong><strong>שייק הארוחה&nbsp; </strong><strong>בסדר היום התזונתי שלנו.</strong></p>" +
      "</div>",
  },
  {
    title: "איך שייק הארוחה יכול לסייע  לי בניהול אורח חיים בריא?",
    body:
      '<div dir="rtl">' +
      "<p>בעולם שבו אילוצי היום יום מקשים עלינו לשמור על תזונה מאוזנת, אנו רואים גדילה בתופעת ההשמנה ובבעיות בריאות שפוגעות באיכות החיים &ndash; <strong>רובן הן תוצאה של תזונה לא מאוזנת</strong>.</p>" +
      "<p>יום תזונתי מאוזן חייב לכולל את כל מרכבי התזונה שהגוף זקוק להם לצורך בניה, התחדשות התאים ולאנרגיה.</p>" +
      "<p><strong>תזונה מאוזנת מאפשרת לגוף לתפקד במיטבו</strong> <strong>ולכן נהנה מיותר אנרגיה, נרגיש יותר חיוניים, העור יראה יותר קורן ויהיו לנו פחות חשקים לאכילה לא מבוקרת</strong><strong>.</strong></p>" +
      "<p><strong>אז מה האתגר בניהול אורח חיים בריא:</strong></p>" +
      "<p><strong>מה שזמין לנו הוא בדרך כלל לא בריא ומה שבריא הרבה פעמים הוא לא טעים </strong></p>" +
      "<p><strong>אז אפשר לומר שמה שזמין וטעים בדרך כלל לא בריא.</strong></p>" +
      "<p><strong><br /></strong><strong>אז איך בכל זאת נספק לגוף ערך תזונתי גבוה תוך שמירה על ערך קלורי שמותאם לאורח החיים&nbsp; שבו אנחנו פחות פעילים</strong><strong>?</strong></p>" +
      "<p><strong><br /></strong>שייק ארוחה מנגיש לנו&nbsp; ב 2 דקות הכנה, ארוחה מלאה ומאוזנת, <u>סופר טעימה</u>, משביעה, ויחד עם זאת עם ערך קלורי נמוך.</p>" +
      "<p><strong>מתי כדאי לשלב את שייק הארוחה?</strong></p>" +
      "<p><strong>פתרון מעולה לארוחה בוקר קלה מזינה, וטעימה מאוד.</strong></p>" +
      "<p>מחקרים רבים מראים שארוחת בוקר מאוזנת, שומרת על בריאות טובה יותר וחיונית לניהול משקל תקין.</p>" +
      "<p><strong>פתרון מצוין כארוחה קלה בערב כדי לאזן יום שלא היה מאוזן תזונתית.</strong><strong><br /></strong>הרבה פעמיים קורה שאנחנו רוצים לאכול ארוחה קלה אבל מה שיש לנו זמין הוא לא מזין וגם עשיר בקלוריות ואין לנו חשק להכין ארוחה טובה.</p>" +
      "<p>שייק הארוחה ״סוגר לנו את הפינה״ ב 2 דקות ויש לנו ארוחה קלה מזינה ומאוזנת סופר טעימה שגם תשאיר אותנו שביעים וגם נשמור על ערך קלורי נמוך.</p>" +
      "<p><strong>פתרון מושלם לאחריי פעילות ספורטיבית </strong><strong><br /></strong>אחריי הפעילות הגוף זקוק להשלמת מאגרים שנוצלו כדי לחדש ולבנות תאי שריר בריאים.</p>" +
      "<p>שייק הארוחה משמש כמשקה התאוששות מעולה מזין ונספג בקלות, וע״י כך ממקסם את אפקט האימון בערך קלורי מדוד.&nbsp;</p>" +
      "<p><strong>שילוב שייק ארוחה בסדר היום מספק לגוף תזונה עשירה ומאוזנת תוך שמירה על ערך קלורי מבוקר.</strong></p>" +
      "</div>",
  },
  {
    title: "איך שייק הארוחה יכול לסייע לי בתהליך ירידה במשקל?",
    body:
      '<div dir="rtl">' +
      "<p>כדי להפחית משקל באופן מאוזן ולהישאר עם המשקל החדש לתמיד נדרש מאתנו שני דברים:</p>" +
      "<ol>" +
      "<li><strong>לסגל שגרת תזונה מאוזנת ונוחה (הרגלים)<br /><br /></strong></li>" +
      "<li><strong> להפחית את צריכת הקלוריות באופן מאוזן (שלא ייווצרו חסרים תזונתיים שיגרמו לחשקים בלתי נשלטים) </strong></li>" +
      "</ol>" +
      "<p>אחד האתגרים העיקריים בתהליך הירידה במשקל הוא איך <strong>להפחית את צריכת הקלוריות תוך שמירה על תזונה מאוזנת</strong>.</p>" +
      "<p>כאשר אוכלים פחות, יש נטייה להפחית גם ערכים חשובים, הדבר מתבטא בחשקים שמתגברים ככול שהתהליך מתמשך.</p>" +
      "<p>לרוב, מי שעושה תהליך של ירידה במשקל, סובל מחסרים שמתבטאים בחשקים ולכן רובם נשברים בשלב כזה או אחר והרבה פעמים מפצים על תחושת החסר <strong>והתוצאה ש 95% חוזרים למשקל הקודם או אף יותר תוך פחות משנה.</strong></p>" +
      "<p>שייק הארוחה הוא חבר לכל מי שנמצא בתהליך של ירידה במשקל, הוא מסייע לשמור על תזונה עשירה בערכים תזונתיים ובערך קלורי נמוך. <strong>התוצאה - הירידה במשקל קלה יותר , אין כמעט חשקים, מסת השריר נשמרת ולכן המראה אחרי הפחתת המשקל נשאר מוצק, העור קורן ומראה השיער יפה</strong>.</p>" +
      "<p><strong>שילוב שייק הארוחה פעם או פעמיים ביום כארוחה קלה (לדוגמא כארוחת בוקר ולפעמים גם כארוחת ערב) מפחית את צריכת הקלוריות היומית תוך שהוא משלים ערכי תזונה חיוניים ובכך מסייע לתהליך הירידה במשקל שיהיה מאוזן, ללא חשקים והירידה במשקל תהיה איכותית (הפחתה משומן ולא משריר</strong>)</p>" +
      "<p><strong>בתוכנית אימון להרגלי תזונה לחיים של </strong><strong>fit k</strong> <strong><br /></strong><strong>משלבים את שייק הארוחה כחלק מהכלים לתזונה מאוזנת בתהליך.</strong></p>" +
      "</div>",
  },
  {
    title: "איך אני מכין שייק ארוחה מזין וטעים ב 2 דקות?",
    body:
      '<div dir="rtl">' +
      "<p>שייק הארוחה הוא בבסיסו בטעם קרמל עדין, כך שהוא שמשתלב מצוין עם כל דבר שנוסיף לו וישנה את טעמו בהתאם לתוספת. (מה שמאפשר לנו גיוון נרחב)</p>" +
      "<p><strong>אופן ההכנה&nbsp; לבסיס: </strong></p>" +
      "<ul>" +
      "<li>2 כפות (מטבח) מעט גדושות של שייק הארוחה (כ 26 גר׳)</li>" +
      "<li>2 כפות מדידה מהאקסטרה לשייק*, מסדרת מוצרי HoldOn</li>" +
      "<li>1 כוס מים (כ 200 מ״ל)</li>" +
      "<li>4-5 קוביות קרח</li>" +
      "</ul>" +
      "<p><em>* ניתן לשלב עם שייק הארוחה חלב סויה כהשלמת החלבון (במקום </em><em>האקסטרה</em> <em>לשייק</em><em>) </em></p>" +
      "<p>לערבל בבלנדר כדקה &ndash; <br />* לקבלת תוצאה מיטבית, יש להגיע למרקם מושלם עבורך ע״י ״משחק בכמות המים, כמות הקרח וזמן הערבול (למרקם דליל יותר יש לשים יותר מים, פחות קרח וזמן ערבול קצר יותר ולהפך למרקם קטיפתי )</p>" +
      "<p><strong>נקבל </strong><strong>שייק</strong><strong> ארוחה בסיסי עם 22 ויטמינים ומינרלים, סיבים תזונתיים </strong><strong>וכ</strong><strong> 25 גר׳ חלבון וערך הקלורי של כ 180 קלוריות.</strong></p>" +
      "<p>&nbsp;</p>" +
      "<p><strong>ניתן (וגם כדאי) להוסיף תוספות לגיוון הטעם.</strong></p>" +
      "<p><u><strong>הצעות לתוספת מגוונת:</strong></u></p>" +
      "<p><strong>פרי לבחירה </strong>(בננה/תות/מנגו/פרירות יער וכו׳) &ndash; לקבלת שייק פירות מענג</p>" +
      "<p><strong>כפית נס קפה </strong>- לקבלת אייס קפה מזין</p>" +
      "<p><strong>כפית חמאת בוטנים </strong>&ndash; לקבלת שייק במבה</p>" +
      "<p><strong>כפית חמאת פיסטוק </strong>(או אגוזי לוז) &ndash; לקבלת שייק גלידה</p>" +
      "<p><strong>כפית טחינה גולמית </strong>&ndash; לקבלת שייק פרפה חלבה</p>" +
      "<p><strong>עלים ירוקים </strong>&ndash; לקבלת שייק ירוק מזין</p>" +
      "<p>* אפשרויות הגיוון הן רבות, ניתן להוסיף ככול העולה בדמיונך לפי הטעם והעדפה אישית.</p>" +
      "<p>** יש לשים לב גם לקלוריות הנוספות שבתוספת שבחרת.</p>" +
      "</div>",
  },
  {
    title: "פרטים על המוצר:",
    body:
      '<div dir="rtl">' +
      '<h3 style="text-align: center;">תוית שייק ארוחה טבעוני</h3>' +
      '<div style="display:flex;justify-content: space-around;flex-wrap: wrap;">' +
      '<img src="/images/product/tavit101-1.png" alt="" style="width: 100%;max-width: 400px;"  />' +
      '<img src="/images/product/tavit101-2.png" alt="" style="width: 100%;max-width: 400px;" />' +
      "</div>" +
      "</div>",
  },
];

const images = [
  {
    original: "/images/shop/sm/313.png",
    thumbnail: "/images/shop/th/313.png",
    //thumbnailHeight: 30,
    //originalHeight: 300,
  },
  {
    original: "/images/site/fistuk.jpg",
    thumbnail: "/images/site/th/fistuk.jpg",
    thumbnailHeight: 50,
  },
  {
    original: "/images/site/banana.jpg",
    thumbnail: "/images/site/th/banana.jpg",
    thumbnailHeight: 50,
  },
  {
    original: "/images/site/coffee.jpg",
    thumbnail: "/images/site/th/coffee.jpg",
    thumbnailHeight: 50,
  },
  {
    original: "/images/site/family.jpg",
    thumbnail: "/images/site/th/family.jpg",
    thumbnailHeight: 50,
  },
];

const icons = ["tevoni", "nosweets", "nogluten", "nopreservs", "kasher"];

export const product313 = {
  id: 313,
  name: name,
  summary: summary,
  contentA: contentA,
  contentB: contentB,
  contentC: contentC,
  vp: 575.9,
  priceNoVp: 0,
  sellPrice: 645,
  faqs: faqs,
  icons: icons,
  images: images,
  metaPrice: 'שווי המוצרים בתפזורת 712ש"ח לחברים, חסכון של 67ש"ח',
};
