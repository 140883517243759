/**
 * Asynchronously loads the component for HomePage
 */
//import { CircularProgress, styled } from "@mui/material/core";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/system/Box";

// const Container = styled("div")({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   height: "50vh",
// });

const LoadingPage = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "50vh",
    }}
  >
    <CircularProgress />
  </Box>
);

export default LoadingPage;
