const name = "מארז 4 אקסטרא לשייק";

const summary =
  '<div dir="rtl">' +
  "<p>מהווה השלמה איכותית <strong><em>לשייק</em></strong><strong><em> ארוחה </em></strong>לקבלת ארוחה מלאה, משביעה עם כל מרכיבי התזונה הנחוצים לארוחה מושלמת <strong>טעימה במיוחד ובערך קלורי נמוך</strong>.</p>" +
  "<p><strong>האקסטרה</strong> <strong>לשייק</strong> מחליף את הצורך בהוספת חלב או יוגורט להכנת&nbsp; <strong><em>שייק ארוחה.</em></strong></p>" +
  "<p><strong>משלים חלבון איכותי, מגנזיום ומרכיבי תזונה חשובים נוספים</strong><strong>.</strong></p>" +
  "</div>";
const contentA =
  '<div dir="rtl">' +
  "<p><em>פתרון פשוט ונוח כתוספת המשלימה את </em><strong><em>שייק ארוחה</em></strong><em>,&nbsp; </em><em><br /></em><em>משפר את הטעם והמרקם, משלים חלבון, מגנזיום וערכי תזונה חשובים.</em></p>" +
  "<p><strong>שילוב </strong><strong>האקסטרה</strong> <strong>לשייק</strong><strong> עם שייק ארוחה </strong>מהווים ארוחה מלאה (תזונתית) טעימה במיוחד, קלה להכנה ועשירה בוויטמינים מינרלים וסיבים תזונתיים.</p>" +
  "<p><strong>רק להוסיף מים (וקרח) ויש ארוחה מושלמת.</strong></p>" +
  "<p><strong>2</strong> <strong>כפות מדידה (מנה)&nbsp; מהוות תוספת של כ 13 גר׳ חלבון איכותי </strong><strong>לשיק ארוחה. </strong>(כל כף נוספת תוסיף עוד כ 6.5 גר׳ חלבון)</p>" +
  "</div>";

const contentB =
  '<div dir="rtl"><table style="border-collapse: collapse; width: 100%;" border="0">' +
  '<tbody><tr><td style="width: 70px"><img src="/images/logos/simbol100.png" alt="/logo-h50.png" width="60"  /></td>' +
  "<td ><p>מותג: HoldOn</p></td></tr>" +
  '<tr><td style="width: 70px"><img src="/images/icons/gmp.png" alt="gmp.png" width="60"  /></td>' +
  "<td><p>אישור משרד הבריאות תקן G.M.P</p></td></tr>" +
  '<tr><td style="width: 70px"><img src="/images/icons/halavi.png" alt="parve.png" width="60"  /></td>' +
  "<td><p>כשרות: הרבנות האזורית כפר פינס מנשה חלבי </p></td></tr>" +
  "</tbody></table></div>";

const contentC =
  '<div dir="rtl">' +
  '<h4 style="text-align: center;"><span style="color: #333399;">פשוט להכין וכיף לשתות</span></h4>' +
  '<div data-oembed-url="//player.vimeo.com/video/660205761?title=0&amp;amp;byline=0">' +
  '<div style="height: 0; left: 0; padding-bottom: 52.1875%; position: relative; width: 100%;"><iframe style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" tabindex="-1" src="https://player.vimeo.com/video/660205761?h=0fe5ae3138&amp;app_id=122963&amp;byline=0&amp;badge=0&amp;portrait=0&amp;title=0" scrolling="no" allowfullscreen="allowfullscreen"></iframe></div>' +
  "</div>" +
  "</div>";
const faqs = [
  {
    title: "מה מייחד את האקסטרה לשייק ולמה לבחור בו ?",
    body:
      '<div dir="rtl">' +
      "<p><strong>משלים את </strong><strong><em>שייק ארוחה </em></strong><strong>לקבלת ארוחה מלאה ומזינה. </strong></p>" +
      "<p><strong>שילוב מושלם גם בהרכב התזונתי וגם לקבלת </strong><strong><em>שייק ארוחה מלאה. </em></strong><strong>טעים במיוחד עם מרקם קטיפתי מפנק. </strong></p>" +
      "<p><strong>מכיל:</strong></p>" +
      "<ul>" +
      "<li><strong>תשלובת חלבונים - חלבון מי גבינה איכותי וסויה מבודד - </strong>חשוב לשמירת מסת שריר בריאה,</li>" +
      "<li><strong>מגנזיום ומינרלים הנחוצים לתפקוד מיטבי.</strong></li>" +
      "<li><strong>פחמימות</strong> במידה הנכונה כדי לספק לנו את האנרגיה הנחוצה. חיוני לשיפור ספיגה של החלבון לשרירים</li>" +
      "</ul>" +
      "<p><strong>יתרונות עיקריים: </strong></p>" +
      '<ul class="check">' +
      "<li><strong>משלים באופן איכותי את שייק ארוחה, - מחליף את הצורך בחלב או ביוגורט &ndash; רק להוסיף מים (וקרח)<br /><br /></strong></li>" +
      "<li><strong>משתלב יחד עם שייק הארוחה לארוחה מלאה (בהרכב התזונתי) משביעה עם טעם ומרקם מושלמים<br /><br /></strong></li>" +
      "<li><strong>כל כף מדידה מכילה כ 6.5 גר חלבון &ndash; </strong><strong><br /></strong>מנה = 2 -3 כפות שהם תוספת של כ 13 &ndash; 21 גר׳ חלבון (בהתאמה)<br /><br /></li>" +
      "<li><strong>משלים את כמות המגנזיום היומית </strong>בשילוב עם שייק ארוחה (עקב השימוש הרב בישראל במים מותפלים הן לשתייה והן להשקיה, לרובנו יש מחסור במגנזיום שמוביל לתחלואה מוגברת של מחלות לב וסכרת)<br /><br /></li>" +
      "<li><strong>אינו מכיל ממתיקים מלאכותיים, צבעי מאכל מלאכותיים וללא גלוטן<br /><br /></strong></li>" +
      "<li><strong><strong>המוצר מיוצר בישראל תחת בקרת משרד הבריאות ונושא את תו התקן </strong><strong>GMP</strong> - תקן מחמיר לתנאי יצור ובקרת האיכות.<br /><br /></strong></li>" +
      "<li><strong>טעים במיוחד<br /><br /></strong></li>" +
      "<li><strong>כשר</strong></li>" +
      "</ul>" +
      "</div>",
  },
  {
    title: "למה כדאי לשלב את האקסטרה לשייק?",
    body:
      '<div dir="rtl">' +
      "<p><strong>האקסטרה</strong> <strong>לשייק</strong> מהווה תוסף חלבון איכותי שמכיל את כל חומצות האמינו הדרושות לשם בנייה והתחדשות התאים.</p>" +
      "<p>הוא עשיר במגנזיום ומשלב ערכי תזונה נוספים החשובים לספיגה יעילה.</p>" +
      "<ul>" +
      "<li>פרקטי לשימוש ומשתלב באופן מושלם עם שייק ארוחה, מעשיר את טעמו וחוסך את הצורך בהוספת חלב או יוגורט להכנתו. (רק להוסיף מים וקרח)<br /><br /></li>" +
      "<li><em>משלים את כמות החלבון הרצויה להתחדשות תאים תקינה.<br /><br /></em></li>" +
      "<li><em>משלים את צריכת המגנזיום החשובה </em><br /><strong><em>(</em></strong><em>שבעקבות התפלת מי השתייה וההשקיה לרובנו יש מחסור)</em></li>" +
      "</ul>" +
      "<p>&nbsp;</p>" +
      "<p><strong><em>למה חשוב להשלים את צריכת החלבון היומית ?</em></strong></p>" +
      "<p>חלבונים הם אבני הבנייה שמשמשים לחידוש ובניית תאי הגוף.</p>" +
      "<p>לשם כך הגוף זקוק <u>לחלבונים מלאים</u>, הכוללים את כל חומצות האמינו החיוניות.</p>" +
      "<p><strong>צריכת חלבון חשובה, כדי למנוע מצבי עייפות, נשירת שיער, איבוד אלסטיות העור, פגיעה במערכת החיסונית, איבוד מסת השריר הנגרמים כתוצאה מחוסרים בחלבון.</strong></p>" +
      "<p><strong>שילוב של שייק ארוחה ואקסטרה </strong><strong>לשייק,</strong><strong> מהווה ארוחה קלה ומלאה (תזונתית), עשירה בחלבון איכותי, בוויטמינים, מינרלים, סיבים תזונתיים ופחמימות במידה הנכונה כדי לספק לנו אנרגיה וחיוניות.</strong></p>" +
      "<p><strong>וכל זה בערך קלורי של&nbsp; כ 180 קלוריות בלבד.</strong></p>" +
      "</div>",
  },
  {
    title: "למי במיוחד חשוב להוסיף את האקסטרה לשייק?",
    body:
      '<div dir="rtl">' +
      "<p>מחקרים מראים שרוב האוכלוסייה בישראל צורכים כמות חלבון <u>קטנה</u> מהדרוש.</p>" +
      "<p>התוצאה היא איבוד מסת שריר חשובה, איבוד שיער והזדקנות מהירה של תאי העור.</p>" +
      "<h3><strong>למה חשוב לשמר מסת השריר ?</strong></h3>" +
      "<p>השרירים הם המנוע של הגוף (וצרכני הקלוריות העיקריים), לכן איבוד רקמת שריר גורמת לירידה ביכולת התפקודית וירידה בקצב שריפת הקלוריות (מה שמוביל להשמנה עם מראה רפוי).</p>" +
      "<p>הרבה אנשים טועים לחשוב שרק ספורט בונה שריר אך למעשה הספורט רק יוצר גירוי לבניית שריר (אין יש מעין)</p>" +
      "<p>בניית השריר נעשית ע״י התזונה והמרכיב החשוב בתזונה הוא החלבון.</p>" +
      "<p><em>(ספורט מייצר את הגירוי המוגבר לבניית שריר ולכן אכן חשוב ספורט אבל לתזונה יש חשיבות מכרעת)</em></p>" +
      "<p>&nbsp;</p>" +
      "<h3><strong>למי חשוב במיוחד להקפיד על צריכת חלבון תקינה ?</strong></h3>" +
      "<p><strong>אחרי גיל 35 </strong>מתחילה ירידה ביכולת הגוף לניצול החלבון לצורך חידוש ובנייה, לכן, ככול שאנחנו עולים בגיל יש חשיבות מוגברת לשמירה על כמות חלבון מספקת בתזונה.</p>" +
      "<p><strong>לעוסקים בפעילות ספורט &ndash; </strong>בפעילות ספורט אנו יוצרים ״קריעה״ מיקרוסקופית ברקמת השריר (זהו בעצם הגירוי הרצוי לבניית וחיזוק מסת השריר) לכן חשובה צריכת חלבון איכותי כדי לספק את ״חומר הגלם״&nbsp; הדרוש לגוף להתחדשות ובניית רקמת שריר וע״י כך למקסם את אפקט האימון.</p>" +
      "<p><strong>לסיכום, צריכת כמות חלבון מספקת תורמת לבריאות תקינה, איזון הגוף, שמירת מסת שריר בריאה ומראה צעיר יותר.</strong></p>" +
      "<p><strong>שילוב של שייק ארוחה ואקסטרה </strong><strong>לשייק</strong><strong> מהווה יחד ארוחה מלאה (תזונתית) עשירה בחלבון איכותי, בוויטמינים, מינרלים, סיבים תזונתיים ופחמימות במידה הנכונה כדי לספק לנו אנרגיה וחיוניות.</strong></p>" +
      "<p><strong>וכל זה בערך קלורי של כ 180 קלוריות בלבד.</strong></p>" +
      "</div>",
  },
  {
    title: "איך אני משלב את  האקסטרה לשייק כדי להפיק את המקסימום?",
    body:
      '<div dir="rtl">' +
      "<p><strong>האקסטרה</strong><strong> לשיק </strong>הוא בבסיסו בטעם וניל עדין, כך שהוא שמשתלב מצוין עם כל דבר שנוסיף לו וישנה את טעמו בהתאם לתוספת. (מה שמאפשר לנו גיוון רחב).</p>" +
      "<p><strong>אפן ההכנה: </strong></p>" +
      "<ul>" +
      "<li>2 כפות (מטבח) מעט גדושות <strong>משייק</strong><strong> ארוחה </strong>מסדרת הולד און (כ 26 גר׳)</li>" +
      "<li>2 כפות מדידה <strong>מאקסטרה</strong> <strong>לשייק</strong>* (25 גר׳)</li>" +
      "<li>1 כוס מים (כ 200 מ״ל)</li>" +
      "<li>4-5 קוביות קרח</li>" +
      "</ul>" +
      "<p>לערבל בבלנדר כדקה&nbsp;<br />* לקבלת תוצאה מיטבית, יש להגיע למרקם מושלם עבורך ע״י ״משחק בכמות המים, כמות הקרח וזמן הערבול. (למרקם דליל יותר יש לשים יותר מים, פחות קרח וזמן ערבול קצר יותר ולהפך למרקם קטיפתי).</p>" +
      "<p><strong>נקבל - שייק ארוחה בסיסי עם 22 ויטמינים ומינרלים, סיבים תזונתיים </strong><strong>וכ</strong><strong> 22 גר׳ חלבון וערך הקלורי של כ 180 קלוריות בלבד.</strong></p>" +
      "<p>* ניתן להוסיף כף מדידה נוספת מאקסטרה לשייק (סה״כ 3 כפות מדידה) ונקבל ארוחה עם כ 28 ר׳ חלבון וכ 230 קלורית <u><strong>והרבה יותר משביעה.</strong></u></p>" +
      "<p><strong>ניתן (וגם כדאי) להוסיף תוספות לגיוון הטעם.</strong></p>" +
      "<p><u><strong>הצעות לתוספת מגוונת:</strong></u></p>" +
      "<p><strong>פרי לבחירה </strong>(בננה/תות/מנגו/פירות יער וכו׳) &ndash; לקבלת שייק פרירות מענג.</p>" +
      "<p><strong>כפית נס קפה </strong>- לקבלת אייס קפה מזין.</p>" +
      "<p><strong>כפית חמאת בוטנים </strong>&ndash; לקבלת שייק במבה.</p>" +
      "<p><strong>כפית חמאת פיסטוק </strong>(או אגוזי לוז) &ndash; לקבלת שייק גלידה.</p>" +
      "<p><strong>כפית טחינה גולמית </strong>&ndash; לקבלת שייק פרפה חלבה.</p>" +
      "<p><strong>עלים ירוקים </strong>&ndash; לקבלת שייק ירוק מזין.</p>" +
      "<p>* אפשרויות הגיוון הן רבות, ניתן להוסיף ככול העולה בדמיונך לפי הטעם והעדפה אישית.</p>" +
      "<p>** יש לשים לב גם לקלוריות הנוספות שבתוספת שבחרת!</p>" +
      "</div>",
  },
  {
    title: "פרטים על המוצר:",
    body:
      '<div dir="rtl" style="text-align: center;">' +
      '<h3 style="text-align: center;">מידע על המוצר אקסטרה לשייק</h3>' +
      '<img src="/images/product/tavit102-1.png" alt="" style="width:100%;max-width:500px;" /></div>',
  },
];

const images = [
  {
    original: "/images/shop/sm/314.png",
    thumbnail: "/images/shop/th/314.png",
    //thumbnailHeight: 30,
    //originalHeight: 300,
  },
  {
    original: "/images/site/fistuk.jpg",
    thumbnail: "/images/site/th/fistuk.jpg",
    thumbnailHeight: 50,
  },
  {
    original: "/images/site/extra.png",
    thumbnail: "/images/site/th/extra.png",
    thumbnailHeight: 50,
  },
  {
    original: "/images/site/tamar.jpg",
    thumbnail: "/images/site/th/tamar.jpg",
    thumbnailHeight: 50,
  },
  {
    original: "/images/site/pinut.jpg",
    thumbnail: "/images/site/th/pinut.jpg",
    thumbnailHeight: 50,
  },
  {
    original: "/images/site/coupletut.jpg",
    thumbnail: "/images/site/th/coupletut.jpg",
    thumbnailHeight: 50,
  },
];

const icons = ["nosweets", "nogluten", "nopreservs", "kasher"];

export const product314 = {
  id: 314,
  name: name,
  summary: summary,
  contentA: contentA,
  contentB: contentB,
  contentC: contentC,
  vp: 669.64,
  priceNoVp: 0,
  sellPrice: 750,
  faqs: faqs,
  icons: icons,
  images: images,
  metaPrice: "שווי המוצרים במארז בקניה בתפזורת: ₪820.00 לחברים, חסכון 70₪",
};
