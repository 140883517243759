import React from "react";
import { useMst } from "stores/RootStore";
import { Button } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { cartAnimation } from "utils/cart-animation";
import { Counter } from "../counter/counter";
import { observer } from "mobx-react-lite";

type Props = { id: number };

const AddToCart = (props: Props) => {
  const { id } = props;
  const { user, shop } = useMst();

  const handleAddClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!user.isAuthenticated) {
      user.openLoginDialog();
      return;
    }
    if (!shop.isInCart(id)) {
      cartAnimation(e);
    }
    shop.addToCart(id, 1);
  };
  const handleRemoveClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    shop.removeFromCart(id, 1);
  };

  return (
    <div>
      {!shop.isInCart(id) ? (
        <Button
          startIcon={<ShoppingCartIcon />}
          onClick={handleAddClick}
          size="small"
          sx={{
            border: "1px solid",
            borderColor: "primary.main",
            //borderRadius: "18px",
            height: "36px",
            paddingLeft: "17px",
            paddingRight: "17px",
            fontSize: "13px",
            fontWeight: "bold",
            color: "primary.main",
            "&:hover": {
              color: "white",
              backgroundColor: "primary.main",
              borderColor: "primary.main",
            },
          }}
        >
          הוסף חבר לעגלה
        </Button>
      ) : (
        <Counter value={shop.getQty(id)} onDecrement={handleRemoveClick} onIncrement={handleAddClick} className="card-counter" />
      )}
    </div>
  );
};

export default observer(AddToCart);
