import { Fragment, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import { Alert } from "@mui/material";
import { Box } from "@mui/material";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ErrorPage = (props: any) => {
  //! ==============================
  const { error } = props;
  const [orderId, setOrderId] = useState(0);
  const query = useQuery();

  useEffect(() => {
    if (orderId === 0) {
      const order = query.get("order");
      if (order) setOrderId(parseInt(order, 10));
    }
  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          width: "auto",
          marginLeft: 2,
          marginRight: 2,
          maxWidth: "600px",
          margin: { sm: "auto" },
        }}
      >
        <Paper
          sx={{
            marginTop: 3,
            marginBottom: 3,
            padding: 2,
          }}
        >
          <Typography component="h2" variant="h4" align="center">
            <Box margin={1}>
              {error && <Alert severity="error">{error}</Alert>}
            </Box>
          </Typography>
          <Fragment>
            <Typography
              variant="h5"
              gutterBottom
              align="center"
              className="errored"
            >
              <span>בקשתך להזמנת מוצרים מ </span>
              <span>Hold On </span>
              <span>נתקלה בבעיה </span>
              <p>
                {" "}
                אנחנו מתצלים על התקלה, תוכל לפנות למערכת התמיכה שלנו להמשך
                הטיפול
              </p>
              <p> או לנסות מחדש</p>
            </Typography>
            <Typography variant="subtitle1" align="right">
              <p> הבעיה נרשמה במערכת שלנו ומטופלת</p>
              <p>ניצור עמך קשר בהקדם</p>
            </Typography>
          </Fragment>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default ErrorPage;
