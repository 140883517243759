import { useParams } from "react-router-dom";
import { useEffect } from "react";
interface TipProps {
  route: string;
  title: string;
  url: string;
}

const tips: TipProps[] = [
  {
    route: "2minShake",
    title: "איך להכין שייק טעים ב 2 דקות",
    url: "https://landing.fitk.co.il/holdon-friends",
    // url: "https://lp.landing-page.mobi/index.php?page=landing&id=467581&token=f902ee80b4b7e6153931854e1e143b56",
  },
];

interface Props {
  //   route: string;
  //   title: string;
  //   url: string;
}

const Tip: React.FC<Props> = (props: Props) => {
  //const { route, title, url } = props;
  const { route } = useParams();
  const tip = tips.find((t) => t.route === route);

  //   useEffect(() => {
  //     const frame = document.getElementById("Iframe") as HTMLIFrameElement;
  //     if (frame)
  //       frame.onload = function () {
  //         const frameH =
  //           frame?.contentWindow?.document?.body?.scrollHeight || 800;
  //         let frameW = frame?.contentWindow?.document.body.scrollWidth || "";
  //         if (frameW === "") frameW = "100%";
  //         else frameW = frameW + "px";
  //         // add extra 50 pixels - in reality need just a bit more
  //         frame.style.height = 50 + frameH + "px";
  //         // not sure if this is really required.
  //         // set the width of the iframe as the width of the iframe content
  //         frame.style.width = frameW;
  //       };
  //   }, []);

  if (!tip) return null;

  return (
    <div style={{ width: "100%", height: "1100px" }}>
      <iframe src={tip.url} title={tip.title} style={{ width: "100%", height: "100%" }} frameBorder="0" id="Iframe">
        Oops, your browser doesn't get Iframes. Click{" "}
        <a href={tip.url} target="_blank" rel="noopener noreferrer">
          Here
        </a>{" "}
        to proceed.
      </iframe>
    </div>
  );
};

export default Tip;
