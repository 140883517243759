import { Fragment, useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup, Divider, Alert } from "@mui/material";

import { Box, Button, Typography } from "@mui/material";

type Props = {
  data: { title: string; question: string; options: string[] };
  nextStep: () => void;
  lastStep: () => void;
  addAnswer: (answer: string) => void;
};

const Questionnaire = (props: Props) => {
  const { nextStep, lastStep, addAnswer, data } = props;
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setAnswer("");
  }, [data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError("");
    setAnswer(event.target.value);
  };

  const goNextStep = () => {
    if (answer === "") {
      setError("נא לבחור את אחת התשובות!");
      return;
    }
    addAnswer(answer);
    nextStep();
  };

  return (
    <Box>
      <Box>
        <Typography variant="h6" color="initial">
          <span>{data.title}</span>
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" color="secondary">
          <span>{data.question}</span>
        </Typography>
      </Box>
      <Box sx={{ mb: 4, mt: 3 }}>
        <RadioGroup aria-label="אמצעי תשלום" defaultValue={1} value={answer} onChange={handleChange} name="radio-buttons-group">
          {data.options.map((item, i) => {
            return (
              <Fragment key={i}>
                <FormControlLabel key={i} value={i} control={<Radio />} label={item} sx={{ textAlign: "start" }} />
                <Divider sx={{ marginBlock: 1 }} />
              </Fragment>
            );
          })}
        </RadioGroup>
      </Box>
      <Box margin={1}>{error && <Alert severity="error">{error}</Alert>}</Box>
      <Box>
        <Button variant="contained" color="primary" size="large" sx={{ fontSize: "1.5rem" }} onClick={goNextStep}>
          אפשר להמשיך
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography
          variant="body1"
          color="primary.main"
          onClick={lastStep}
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "1.2rem",
          }}
        >
          כבר מכיר, מעוניין להירשם
        </Typography>
      </Box>
    </Box>
  );
};

export default Questionnaire;
