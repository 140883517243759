import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useMst } from "stores/RootStore";
import { useNavigate } from "react-router-dom";
import TextfieldWrapper from "components/FormsUI/Textfield";
import Alert from "@mui/material/Alert";
import { AUTH_TYPE } from "./auth.type";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import apiService from "api/api.service";
//!----------------------------------------------------------------

const INITIAL_FORM_STATE = {
  code: "",
  password: "",
};

const FORM_VALIDATION = Yup.object().shape({
  code: Yup.string().required("שדה נדרש"),
  password: Yup.string().required("שדה נדרש"),
});

type Props = {
  phone: string;
  registration: boolean;
};

const CreatePassword = (props: Props) => {
  const { user } = useMst();
  // const [code, setCode] = useState("");
  // const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loadingA, setLoadingA] = useState(false);
  const [loadingB, setLoadingB] = useState(false);
  const [error, setError] = useState("");
  const [resendMessage, setResendMessage] = useState("");
  const [method, setMethod] = useState("sms");

  const navigate = useNavigate();

  const { phone, registration } = props;

  //   const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setCode(event.target.value);
  //   };
  //   const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setPassword(event.target.value);
  //   };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethod((event.target as HTMLInputElement).value || "sms");
  };
  const handleResendSubmit = async () => {
    setLoadingA(true);
    setResendMessage("");
    setError("");

    try {
      await apiService.post(`auth/SendCode`, { phone, method });
      setResendMessage(`קוד נשלח לטלפון: ${phone} או אימייל`);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message || "שגיאת שליחה");
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoadingA(false);
    }
  };

  type LoginProps = {
    code: string;
    password: string;
  };
  const handleSubmit = async (values: LoginProps) => {
    const { code, password } = values;
    setLoadingB(true);
    setError("");

    try {
      const response = await apiService.post<any>(`auth/CreatePassword`, { code, password, phone });
      user.login(response);
      navigate("/", { replace: true });
      if (registration) {
        user.setAuthType(AUTH_TYPE.REG_SUCCESS);
      } else {
        user.closeLoginDialog();
      }
    } catch (err) {
      if (err instanceof Error) {
        if (err.message.includes("401")) {
          setError("קוד לא תואם או עדיין לא יצרת סיסמה ראשונית");
        } else if (err.message.includes("400")) {
          setError("קוד לא תואם וודא תקינות נתונים");
        } else {
          setError(err.message || "קוד לא תואם ,האם יצרת סיסמה? אם כן נסה שנית!");
        }
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoadingB(false);
    }
  };

  return (
    <Box>
      <Box margin={1}>{error && <Alert severity="error">{error}</Alert>}</Box>

      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={handleSubmit}
      >
        <Form noValidate>
          <Box>
            <Typography
              variant="body1"
              color="initial"
              fontWeight="bold"
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box sx={{ fontSize: "1rem", marginInlineEnd: 1 }}>1</Box>
              <Box sx={{ fontSize: "0.9rem" }}>
                <span>ברגעים אלו קיבלת קוד אימות ב SMS </span>
              </Box>
            </Typography>

            <TextfieldWrapper
              id="code"
              name="code"
              label="הזן כאן את הקוד שקיבלת"
              //value={code}
              //onChange={handleCodeChange}
              margin="normal"
              size="small"
              autoFocus
              fullWidth
              sx={{
                //mt: 2,
                background: "#f3f3ff",
                //width: "100%",
                //maxWidth: "300px",
              }}
            />
            <Box
              sx={
                {
                  //display: "flex",
                  //flexDirection: "row",
                  //mt: 1,
                  //justifyContent: "space-between",
                }
              }
            >
              <Box>
                <Typography variant="body2" color="initial">
                  לא קיבלתי קוד -
                  <FormControl>
                    <FormLabel id="ivr-controlled-radio-buttons-group">
                      בחר צורת שליחה
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="ivr-controlled-radio-buttons-group"
                      name="ivr-radio-buttons-group"
                      value={method}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="sms"
                        control={<Radio size="small" />}
                        label="SMS"
                      />
                      <FormControlLabel
                        value="ivr"
                        control={<Radio size="small" />}
                        label="הודע קולית"
                      />
                      <FormControlLabel
                        value="email"
                        control={<Radio size="small" />}
                        label="אימייל"
                      />
                    </RadioGroup>
                  </FormControl>
                </Typography>
                <Box sx={{ textAlign: "end" }}>
                  <LoadingButton
                    variant="text"
                    color="primary"
                    loading={loadingA}
                    //fullWidth
                    onClick={handleResendSubmit}
                    sx={{ textDecoration: "underline", p: 0 }}
                    //size="small"
                  >
                    שלח שוב
                  </LoadingButton>
                </Box>
                {resendMessage && (
                  <Box sx={{ fontSize: "0.7rem" }}>{resendMessage}</Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box>
              <Typography
                variant="body1"
                color="initial"
                fontWeight="bold"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box sx={{ fontSize: "1rem", marginInlineEnd: 1 }}>2</Box>
                <Box sx={{ fontSize: "0.9rem", lineHeight: 1.2 }}>
                  <span> בחר סיסמה אישית שתשמש אותך לכניסה לאתר החברים</span>
                  <span> (לפחות 8 תוים)</span>
                </Box>
              </Typography>
            </Box>
            <Box>
              <TextfieldWrapper
                id="password"
                name="password"
                label="הזן סיסמה אישית חדשה"
                type={showPassword ? "text" : "password"}
                //value={password}
                //onChange={handlePasswordChange}
                margin="normal"
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ marginInlineEnd: "10px" }}
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  //mt: 2,
                  background: "#f3f3ff",
                  //width: "100%",
                  //maxWidth: "300px",
                }}
              />
              <Box>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={loadingB}
                  type="submit"
                  fullWidth
                  //onClick={handleResendSubmit}
                  //sx={{ maxWidth: "300px" }}
                  //size="small"
                >
                  צור סיסמה חדשה
                </LoadingButton>
              </Box>
              <Box
                sx={{
                  textAlign: { xs: "center", sm: "start" },
                  fontSize: "0.8rem",
                  maxWidth: { xs: "80%", sm: "300px" },
                  margin: { xs: "auto", sm: "inherit" },
                  mt: 1,
                  lineHeight: "1.2",
                }}
              >
                <span>לאחר האיפוס, הסיסמה שבחרת תשלח גם ב SMS</span>
              </Box>
            </Box>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

export default CreatePassword;
