/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TextField } from "@mui/material";
import { useField } from "formik";

const TextfieldWrapper = (props: any) => {
  const { name, variant, ...otherProps } = props;
  const [field, meta] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: variant ? variant : "standard",
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  if (configTextfield.value === null) configTextfield.value = "";

  return <TextField {...configTextfield} />;
};

export default TextfieldWrapper;
