import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useMst } from "stores/RootStore";

import { AUTH_TYPE } from "components/Auth/auth.type";
import { Box, Button } from "@mui/material";
import Step1 from "./Steps/Step1";
import Questionnaire from "./Steps/Questionnaire";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";
import Step7 from "./Steps/Step7";
import Step8 from "./Steps/Step8";
import { observer } from "mobx-react-lite";

const JoinPage = () => {
  const { user } = useMst();
  const [step, setStep] = useState(1);
  const lastStep = 8;
  const [searchParams] = useSearchParams();
  const { refId } = useParams();
  const reference = searchParams.get("ad");
  const isH = searchParams.get("h") !== null;
  const regOnly = searchParams.get("a") !== null;

  const q: { title: string; question: string; options: string[] }[] = [
    {
      title: "שאלה 1 מתוך 3",
      question: "האם את/ה אוכל/ת ארוחות בוקר?",
      options: [
        "כן, אני דואג/ת להכין ולאכול ארוחות בוקר מאוזנות שמכילה את כל אבות המזון",
        "לפעמים, כשיש לי זמן אני מכין/ה ארוחות בוקר מאוזנת ובשאר הימים אני אוכל/ת משהו זריז",
        "אני אוכל/ת משהו זריז",
        "אני לא אוכל/ת ארוחת בוקר בכלל",
      ],
    },
    {
      title: "שאלה 2 מתוך 3",
      question: "באופן כללי עד כמה את/ה מקפיד/ה על תזונה עשירה ומאוזנת?",
      options: ["תמיד", "לפעמיים אבל יש מקום לשיפור", "ברוב הזמן לא"],
    },
    {
      title: "שאלה 3 מתוך 3",
      question: "מה המטרה העיקרית שלך?",
      options: [
        "אורח חיים בריא",
        "אורח חיים בריא והפחתת כמה ק״ג שהצטברו לי",
        "צריך שינוי בתזונה ובהרגלים שלי",
        "צריך לעשות שינוי בתזונה ובהרגלים ולהשיל ק״ג מיותרים שהצטברו לי",
      ],
    },
  ];

  useEffect(() => {
    user.setJoinParams(refId || "", reference || "");
    user.setIsH(isH);
    user.resetRegAnswers();
    if (regOnly) setStep(lastStep);
  }, [isH]);

  const nextStep = () => {
    setStep(step + 1);
  };
  const goToRegistration = () => {
    user.setAuthType(AUTH_TYPE.REGISTER);
  };
  const goToLastStep = () => {
    setStep(lastStep);
  };

  const addAnswers = (i: number) => (option: string) => {
    const answer = `${q[i].question} - ${q[i].options[+option]}`;
    user.setRegAnswers(answer);
  };

  // const addAnswers = (answer: string) => {
  //   user.setAnswer()
  // };
  const getJoinPage = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            nextStep={nextStep}
            lastStep={goToLastStep}
            refId={refId || ""}
          />
        );
      case 2:
        return (
          <Questionnaire
            data={q[0]}
            nextStep={nextStep}
            lastStep={goToLastStep}
            addAnswer={addAnswers(0)}
          />
        );
      case 3:
        return (
          <Questionnaire
            data={q[1]}
            nextStep={nextStep}
            lastStep={goToLastStep}
            addAnswer={addAnswers(1)}
          />
        );
      case 4:
        return (
          <Questionnaire
            data={q[2]}
            nextStep={nextStep}
            lastStep={goToLastStep}
            addAnswer={addAnswers(2)}
          />
        );
      case 5:
        return <Step5 nextStep={nextStep} lastStep={goToLastStep} isH={isH} />;
      case 6:
        return <Step6 nextStep={nextStep} lastStep={goToLastStep} isH={isH} />;
      case 7:
        return <Step7 nextStep={nextStep} lastStep={goToLastStep} isH={isH} />;
      case 8:
        return <Step8 nextStep={goToRegistration} />;
      default:
        return (
          <Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ fontSize: "1.5rem" }}
              onClick={() => setStep(1)}
            >
              שגיאה - חזרה להתחלה
            </Button>
          </Box>
        );
        break;
    }
  };

  if (user.isAuthenticated) {
    return (
      <>
        <Box>
          <h1>הינך כבר רשום למערכת</h1>
          <a href="/">חזרה לדף הבית</a>
        </Box>
      </>
    );
  }

  return (
    <Box className="sitePage" sx={{ maxWidth: "600px", margin: "auto" }}>
      {getJoinPage()}
    </Box>
  );
};

export default observer(JoinPage);
