import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  DialogActions,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from "react-share";
import { useMst } from "stores/RootStore";
import { styled } from "@mui/material/styles";
import MyAccountPage from "./MyAccountPage";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { config } from "config";
import pic from "assets/images/share.png";
interface ShareDialogProps {
  open: boolean;
  onClose: () => void;
}

const MyDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    margin: theme.spacing(1),
  },
  "& .MuiFormControlLabel-label": {
    fontSize: "13px",
  },
}));

function ShareDialog(props: ShareDialogProps) {
  const { onClose, open } = props;
  const [isH, setIsH] = useState(false);
  const { user } = useMst();
  const initialTooltip = "לחץ להעתיק";
  const [tooltip, setTooltip] = React.useState(initialTooltip);
  const handleTooltipClose = () => {
    setTooltip(initialTooltip);
  };
  const handleClose = () => {
    onClose();
  };

  let joinUrl = `https://holdon.co.il/join/${user.refId}${isH ? "?h" : ""}`;

  // const title =
  //   "הי,  רציתי לספר לך על HoldOn חנות אינטרנטית עם מוצרים מעולים שעוזרים לי לנהל אורח חיים בריא יותר בקלות" +
  //   " לדעתי זה יכול לעניין אותך. " +
  //   " דרך הלינק הזה מקבלים 15% הנחה   ";

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsH(event.target.checked);
  };

  return (
    <MyDialog onClose={handleClose} open={open}>
      <DialogTitle
        sx={{
          backgroundColor: "primary.main",
          color: "#fff",
          textAlign: "center",
          mb: 3,
        }}
      >
        <span style={{ paddingInlineEnd: "20px" }}>שיתוף הלינק האישי</span>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          label="סמן אם ברצונך בשיתוף המותאם לציבור חרדי"
          control={
            <Checkbox
              value={isH}
              checked={isH}
              onChange={handleChange}
              color="primary"
            />
          }
        />
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="body2" color="initial" gutterBottom>
            שיתוף דרך הפייסבוק או דרך הווטסאפ
          </Typography>
          <FacebookShareButton
            url={joinUrl}
            quote={config.shareQuote}
            hashtag="#holdon"
          >
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>
          <span style={{ marginInline: "16px" }}></span>
          <WhatsappShareButton
            url={joinUrl}
            title={config.shareQuote}
            separator=" | "
          >
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>
        </Box>
        <Typography
          variant="body2"
          gutterBottom
          mt={5}
          color="primary"
          textAlign="center"
        >
          או העתק את הקישור - רק ללחוץ ולשלוח
          <br />
          (הקישור יועתק בלחיצה)
        </Typography>
        <Tooltip
          // disableFocusListener
          title={tooltip}
          onClose={handleTooltipClose}
        >
          <Typography
            component="p"
            onClick={() => {
              navigator.clipboard.writeText(joinUrl);
              setTooltip("הועתק");
            }}
            variant="h6"
            dir="ltr"
            sx={{ backgroundColor: "#eeb" }}
          >
            {joinUrl}
          </Typography>
        </Tooltip>
        {/* <MyAccountPage /> */}
      </DialogContent>
    </MyDialog>
  );
}

const SharePage = () => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className="sitePage" sx={{ mt: 5 }}>
      {/* <div>LOGO</div> */}
      <Box sx={{ maxWidth: "400px", margin: "auto" }}>
        <img src={pic} alt="share-hold-on" width="100%" />
      </Box>
      <Box>
        שתפו את הלינק האישי וקבלו מעכשיו ולתמיד, קרדיטים* על כל הרכישות של
        החברים שצרפתם.
      </Box>
      <Box>
        <small>
          <i>
            *כסף שיעמוד לרשותכם באתר שיעניק לכם הנחה משמעותית עד כדי מוצרים
            חינם.
          </i>
        </small>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Button
          variant="contained"
          fullWidth
          sx={{ maxWidth: "400px" }}
          size="large"
          onClick={handleClickOpen}
        >
          כן, אני רוצה לשתף
        </Button>
      </Box>
      <ShareDialog open={open} onClose={handleClose} />
    </Box>
  );
};

export default SharePage;
