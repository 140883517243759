/* eslint-disable react/no-unescaped-entities */
import * as React from "react";
import { Counter } from "components/counter/counter";
import { CloseIcon } from "assets/icons/CloseIcon";
import { CURRENCY } from "utils/constant";
import {
  ItemBox,
  Image,
  Information,
  Name,
  Price,
  Weight,
  Total,
  RemoveButton,
} from "./cart-item.style";
import getProductImage from "utils/product-image";
import { useMst } from "stores/RootStore";

interface Props {
  data: CartItem;
  onDecrement: () => void;
  onIncrement: () => void;
  onRemove: () => void;
}

export const CartItem: React.FC<Props> = ({
  data,
  onDecrement,
  onIncrement,
  onRemove,
}) => {
  const { name, sellPrice, id, vp } = data.product;
  const quantity = data.qty;
  const displayPrice = sellPrice;
  const image = getProductImage(id, "sm");
  const { user } = useMst();

  return (
    <ItemBox>
      <Counter
        value={quantity}
        onDecrement={onDecrement}
        onIncrement={onIncrement}
        className="lightVertical"
        variant="lightVertical"
      />
      <Image src={image} />
      <Information>
        <Name>{name}</Name>
        <Price>
          {CURRENCY}
          {displayPrice}
        </Price>
        {user.isMarketer && <Weight>{vp} נק'</Weight>}
      </Information>
      <Total>
        {CURRENCY}
        {(quantity * displayPrice).toFixed(2)}
      </Total>
      <RemoveButton onClick={onRemove}>
        <CloseIcon />
      </RemoveButton>
    </ItemBox>
  );
};
