import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const API_V2_URL = process.env.REACT_APP_API_V2_URL;

export const setupAxiosInterceptors = (): void => {
    axios.defaults.withCredentials = true;

    axios.interceptors.request.use(
        (config: AxiosRequestConfig = {}) => {
            const token = localStorage.getItem("jwtToken");
            if (token) {
                if (!config.headers) {
                    config.headers = {};
                }
                config.headers["Authorization"] = `Bearer ${token}`;
            } else {
                console.log('No token found for request', config.url);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (response) => {
            // success
            return response;
        },
        (error) => {
            const status = error?.response?.status;

            if (!!status && status === 401 && (window.location.pathname !== "/login" && window.location.pathname !== "/")) {
                window.location.href = "/logout";
            }

            return Promise.reject(error);
        }
    );
};

type HttpMethod = 'get' | 'post' | 'delete';

const chooseBaseUrl = (useV2: boolean = false) => useV2 ? API_V2_URL : API_URL;

export const makeRequest = async <T>(method: HttpMethod, options: IOptionsApi): Promise<T> => {
    const baseUrl = chooseBaseUrl(options.useV2);
    const url = `${baseUrl}/${options.url}`;
    const config: AxiosRequestConfig = {
        method,
        url,
        ...(method === 'get' || method === 'delete'
            ? { params: options.params }
            : { data: options.params })
    };

    try {
        const response = await axios(config);
        return <T>response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new ApiError(`${method.toUpperCase()} request failed: ${error.message}`);
        }
        throw error;
    }
};

// Define a custom error class for API errors
class ApiError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "ApiError";
    }
}

export const postData = <T>(
    urlOrOptions: string | IOptionsApi,
    data?: Record<string, unknown>,
    useV2: boolean = false
): Promise<T> => {
    if (typeof urlOrOptions === 'string') {
        return makeRequest('post', { url: urlOrOptions, params: data, useV2 });
    } else {
        return makeRequest('post', urlOrOptions);
    }
};

export const deleteData = <T>(
    urlOrOptions: string | IOptionsApi,
    params?: Record<string, unknown>,
    useV2: boolean = false
): Promise<T> => {
    if (typeof urlOrOptions === 'string') {
        return makeRequest('delete', { url: urlOrOptions, params, useV2 });
    } else {
        return makeRequest('delete', urlOrOptions);
    }
};

export const fetchData = <T>(
    urlOrOptions: string | IOptionsApi,
    params?: Record<string, unknown>,
    useV2: boolean = false
): Promise<T> => {
    if (typeof urlOrOptions === 'string') {
        return makeRequest('get', { url: urlOrOptions, params, useV2 });
    } else {
        return makeRequest('get', urlOrOptions);
    }
};

const apiService = {
    post: postData,
    get: fetchData,
    delete: deleteData
};

export default apiService;
