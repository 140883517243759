import IFramePage from "components/IFramePage/IFramePage";
import IframeDynamicHeight from "components/IframeFullHeight/IframeDynamicHeight";
import WordPressPage from "components/WordPressPage/WordPressPage";
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { MobileHome } from "./Home/MobileHome/MobileHome";


interface Props {}



const HomePage = (props: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isNotMobile = useMediaQuery({ minWidth: 768 })

  return (
    <div>
      {isMobile && <MobileHome />}
{isNotMobile && <div>
  <IframeDynamicHeight title="דף הבית" src="https://landing.holdon.co.il" />
  </div>}


      {/* Not mobile (desktop or laptop or tablet) */}
    {/* <IframeDynamicHeight title="דף הבית" src="https://info.holdon.co.il/" /> */}

    </div>

    // <IframeDynamicHeight title="דף הבית" src="https://info.holdon.co.il/meal-shake" />
    // <WordPressPage page_id="56" />
  );
};

export default HomePage;
