import Typography from "@mui/material/Typography";
import React from "react";
import { CURRENCY } from "utils/constant";
import { useMst } from "stores/RootStore";
import { observer } from "mobx-react-lite";

import InfoIcon from "@mui/icons-material/Info";
interface Props {
  vp: number;
  sellPrice: number;
}

const PriceRow: React.FC<Props> = ({ vp, sellPrice }) => {
  const { user, settings } = useMst();

  const price = (
    <span className={user.isAuthenticated ? "line-through" : ""}>
      {Math.floor(sellPrice / 0.85).toFixed(2)}
    </span>
  );

  return (
    <>
      <Typography sx={{ color: "mypink.main", fontWeight: "bold" }}>
        <span>
          מחיר מוצר {price} {CURRENCY},
        </span>{" "}
        <br />
        <span style={{ fontSize: "larger" }}>
          מחיר לחבר רשום {sellPrice.toFixed(2)} {CURRENCY}
        </span>
      </Typography>
      {user.isAuthenticated && (
        <Typography sx={{ color: "primary.main", fontWeight: "bold" }}>
          {user.isMarketer && (
            <span>
              מחיר שלך {(sellPrice - vp * user.discountMulti).toFixed(2)}{" "}
              {CURRENCY}, {vp} נקודות
            </span>
          )}
          {user.isPremium && (
            <span>
              מזכה ב {(((vp || 0) * settings.vpRate) / 10).toFixed(2)} ₪ קרדיט
              לקניה הבאה{" "}
            </span>
          )}
          {user.isNoRole && (
            <span>
              מזכה ב {sellPrice.toFixed(2)} כוכבים <InfoIcon />
            </span>
          )}
        </Typography>
      )}
    </>
  );
};

export default observer(PriceRow);
