import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import IFramePage from "components/IFramePage/IFramePage";
import IframeFullHeight from "components/IframeFullHeight/IframeFullHeight";
import PageTitle from "components/PageTitle";
import IframeResizer from "iframe-resizer-react";
import React, { Fragment } from "react";
import Iframe from "react-iframe";

const FriendPage = () => {
  return (
    <>
      {/* <IframeFullHeight title="vip memebrship and friends" url="https://landing.fitk.co.il/vip-friend" /> */}
      <IFramePage title="חברות פרמיום באתר" src="https://landing.fitk.co.il/vip-friend" />
      {/* <iframe
        src="https://landing.fitk.co.il/vip-friend"
        title="vip"
        height="2200"
        style={{ width: "100%", minHeight: "90vh" }}
      /> */}
      {false && (
        <Fragment>
          <PageTitle>חבר מועדף - תוכנית החברים באתר הולד און</PageTitle>
          <Container>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={12}>
                <Box sx={{ padding: "0" }}>
                  <img src={"/images/site/frbg900.png"} alt="bg-1" className="ideal-pic" width="100%" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h4" fontWeight="bold">
                    להיות חבר מועדף – רק להרשם וכבר ליהנות מהטבות{" "}
                  </Typography>
                  <Typography sx={{ margin: "auto", width: "75%" }} className="color-white">
                    מיד יש לך 15% הנחה על כל החברים שתרכשו שיעזרו לכם לנהל אורח חיים בריא ומהנה ובנוסף לכך צוברים כוכבים* שיקדמו
                    אותך להיות לקוח חבר פרמיום
                  </Typography>
                  <Typography sx={{ margin: "auto" }} className="font-12 m-auto">
                    * איזה כיף, נהנים מהחברים החדשים שכל כך עוזרים לנו ובצבירה של 1000 כוכבים זה מקדם אותך למעמד הפרמיום היוקרתי
                    והמשתלם (קנייה מצטברת באתר ב-1000ש״ח)
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6} sx={{ textAlign: "left" }} className="text-right bottom-order bg-white color-blue">
                    <Typography variant="h4" sx={{ lineHeight: "1.2" }} fontWeight="bold" className="color-blue">
                      חברי פרמיום – נהנים ומרוויחים כפול{" "}
                    </Typography>
                    <Typography variant="h6" sx={{ lineHeight: "1.2" }}>
                      בנוסף ל15% הנחה, כחבר פרמיום תקבל/י קרדיטים שישמשו אותך ככסף לרכישות עוד חברים מהולד און וליהנות עוד יותר
                      ובפחות כסף מאורח חיים בריא ומהנה.
                      <br />
                      <br />
                      איזה כיף שעל הקניות הבאות משלמים פחות ואולי אפילו לא משלמים בכלל
                    </Typography>
                    <Typography variant="h5" sx={{ lineHeight: "1.2" }} className="color-blue">
                      איך אני מקבל קרדיטים ואיך אני יכול ליהנות מהם?
                    </Typography>
                    <Typography variant="h6" sx={{ lineHeight: "1.2" }}>
                      על כל מוצר ליד במחיר כתוב כמה קרדיטים הוא מזכה, כל קרדיט שווה לשקל לרכישות נוספות, לדוגמא 100 קרדיטים שנצברו
                      = 100 ש״ח לשימוש <br />
                      <br />
                      כן, גם על קנויות בקרדיטים מקבלים עוד קרדיטים לפעם הבאה - איזה כיף!
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ textAlign: "left", lineHeight: "1.2" }}
                    className="text-right top-order bg-blue color-white"
                  >
                    <Typography variant="h6" sx={{ lineHeight: "1.2" }} className="color-white">
                      אנחנו יודעים שאחרי שאורח החיים הבריא שלך הפך ולהיות הרבה יותר קל וכיפי עם החברים של הולד און, בטח חשבת על
                      עוד מכרים שלך שיוכלו גם הם ליהנות מהיתרונות, נכון?
                    </Typography>
                    <Typography variant="h5" sx={{ lineHeight: "1.2" }} className="color-white">
                      אז אנחנו רוצים לתת לך יותר כי זה מגיע לך!
                    </Typography>
                    <Typography variant="h6" sx={{ lineHeight: "1.2" }}>
                      המכרים שלך שירשמו לאתר כחבר מעודפים יוכלו כבר ליהנות מהטבות של החבר המועדף, ולך, כחבר פרמיום, אנחנו ניתן גם
                      קרדיטים על הרכישות של המומלצים שלך. ככה, גם תעזור/י למכרים שלך ליהנות מיתרות החברים של הולד און וגם לצבור
                      מהר יותר את המוצרים הבאים עד כדי חינם!
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ textAlign: "center" }}>
                  <h5>ליהנות, לעזור וגם להרוויח</h5>
                  <img src={"/images/site/earn900.png"} alt="earn" className="ideal-pic" width="100%" />
                </Box>
                <Box sx={{ mb: 6 }}>
                  <Typography variant="h5" color="initial">
                    למעשה גם עזרת למכרים שלך, וגם החברים הנפלאים שתומכים באורח החיים הבריא שלך יהיו בחינם!
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Fragment>
      )}
    </>
  );
};

export default FriendPage;
