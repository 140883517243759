import React from "react";
import { Iphone } from "./components/Iphone";
import "./style.css";

export const MobileHome = (): JSX.Element => {
  return ( 
    <div className="iphone-pro">
      <div className="overlap-wrapper-2">
        <div className="overlap-4">
          <div className="frame">
            <div className="overlap-5">
              <Iphone
                className="iphone-13"
                dscf="/img/dscf-0323.png"
                dscf1="/img/dscf-0325.png"
                dscfClassName="iphone-2"
                dscfClassNameOverride="iphone-4"
                ellipseClassName="design-component-instance-node"
                group="/img/group-5.png"
                groupClassName="iphone-instance"
                img="/img/group-4.png"
                overlapClassName="iphone-3"
                overlapGroupClassName="iphone-13-instance"
                property1="thirteen-pro-1"
              />
              <p className="hold-on">
                <span className="span">
                  הפכנו את האתגר של ניהול אורח חיים בריא עם משקל נכון, לפשוט מהנה וטעים יותר.
                  <br />
                </span>
                <span className="text-wrapper-3">
                  <br />
                  מתוך הבנת שורש הבעיה, קו המוצרים המתקדם של HoldOn תוכנן במיוחד כדי להפוך את ניהול אורח חיים בריא
                  לידידותי, כיפי, קל לתפעול וטעים במיוחד.
                  <br />
                  <br />
                  המוצרים, המהווים תמיכה מושלמת לתזונה מאוזנת תוך בקרת קלוריות, הם בעלי מרקם מושלם וטעם מדהים לחוויית
                  השימוש ברמה אחרת.
                </span>
              </p>
              <img className="HOLD-ON-LOGO-WHITE" alt="Hold ON LOGO WHITE" src="/img/hold-on-logo-white.png" />
            </div>
          </div>
          <div className="frame-2">
            <div className="frame-wrapper">
              <div className="frame-3">
                <div className="overlap-6">
                  <div className="rectangle" />
                  <img className="mask-group" alt="Mask group" src="/img/mask-group.png" />
                  <div className="KIWI">
                    <div className="overlap-group-2">
                      <img className="untitled-design" alt="Untitled design" src="/img/untitled-design-1.png" />
                      <img className="untitled-design-2" alt="Untitled design" src="/img/untitled-design-2.png" />
                    </div>
                  </div>
                  <div className="component">
                    <img className="image" alt="Image" src="/img/2.png" />
                    <div className="RIGHT">
                      <img
                        className="icon-chevron-right"
                        alt="Icon chevron right"
                        src="/img/icon-chevron-right-9.png"
                      />
                    </div>
                    <div className="view">
                      <img className="element" alt="Element" src="/img/1-4-2.png" />
                      <img className="element-2" alt="Element" src="/img/image.png" />
                      <img className="element-3" alt="Element" src="/img/1-4-2x.png" />
                      <img className="element-4" alt="Element" src="/img/1-4.png" />
                      <img className="element-5" alt="Element" src="/img/1-1.png" />
                      <img className="element-6" alt="Element" src="/img/1-1.png" />
                    </div>
                    <div className="view-wrapper">
                      <div className="view-2">
                        <p className="p">
                          <span className="text-wrapper-4">
                            החבר שיעזור לך לנהל אורח חיים בריא. פתרון פרקטי, מזין וסופר טעים לארוחה קלה ומאוזנת. ארוחה
                            עם ערך תזונתי גבוה וערך קלורי נמוךמושלם כארוחת בוקר מזינה או כארוחה קלה אחרת כשרוצים להרגיש
                            שבעים וקלילים.{" "}
                          </span>
                          <span className="text-wrapper-5">
                            מושלם כארוחת בוקר מזינה, מעולה לאחריי פעילות ספורטיבית ו״ סוגר פינה״ לכל פעם שרוצים ארוחה
                            קלה ומזינה משביעה תשאיר אותנו קלילים.
                          </span>
                        </p>
                        <div className="text-wrapper-6">שייק ארוחה מזינה</div>
                      </div>
                    </div>
                  </div>
                  <div className="text-wrapper-7">לקוחות מספרים</div>
                  <div className="rectangle-2" />
                  <div className="frame-4">
                    <div className="text-wrapper-8">מעבר לחנות &gt;</div>
                  </div>
                  <p className="div-2">
                    <span className="text-wrapper-9">בואו נהיה </span>
                    <span className="text-wrapper-10">חברים</span>
                  </p>
                  <div className="rectangle-3" />
                  <p className="hold-on-2">
                    <span className="text-wrapper-11">
                      אנחנו רוצים לעזור לך לנהל חיים בריאים ומהנים בקלות ובנוחות.{" "}
                    </span>
                    <span className="text-wrapper-12">תוכנית החברים </span>
                    <span className="text-wrapper-13">של HoldOn מאפשרת לך ליהנות מיתרונות</span>
                    <span className="text-wrapper-12">
                      {" "}
                      המוצרים, לקבל הנחות, מבצעים מיוחדים ואפילו להרוויח את המוצרים שלך במחיר מופחת עד כדי בחינם! <br />
                      <br />
                      <br />
                    </span>
                  </p>
                  <div className="frame-5">
                    <p className="element-7">
                      <span className="text-wrapper-14">
                        להיות חבר מועדף – רק להירשם וכבר ליהנות <br />
                      </span>
                      <span className="text-wrapper-15">כחבר מועדף כבר יש לך 15% הנחה בכל רכישה.</span>
                    </p>
                    <img className="warranty" alt="Warranty" src="/img/warranty.png" />
                  </div>
                  <div className="frame-6">
                    <p className="element-holdon">
                      <span className="text-wrapper-16">לאחר צבירת 800 כוכבים, </span>
                      <span className="text-wrapper-17">(קניה מצטברת ב- ₪800) </span>
                      <span className="text-wrapper-18">
                        מקבלים את מעמד הפרמיום היוקרתי <br />
                        והמשתלם של HoldOn.
                      </span>
                    </p>
                    <img className="warranty" alt="Warranty" src="/img/warranty.png" />
                  </div>
                  <div className="flexcontainer">
                    <p className="text">
                      <span className="text-wrapper-5">
                        חברי פרמיום השגרירים נהנים עוד יותר
                        <br />
                      </span>
                    </p>
                    <p className="text">
                      <span className="text-wrapper-19">
                        בנוסף, חברי פרמיום שמפנים מכרים להיות חברים באתר HoldOn, מקבלים קרדיטים על רכישות החברים שהופנו
                        דרכם. החברים החדשים נהנים ממוצרים וטיפים בניהול אורח חיים בריא ולך יהיה קרדיט נוסף ולתמיד על כל
                        רכישה ורכישה שלהם.
                      </span>
                    </p>
                  </div>
                  <img className="symbol" alt="Symbol" src="/img/symbol-4.png" />
                  <div className="flexcontainer-2">
                    <p className="text">
                      <span className="text-wrapper-5">
                        חברי פרימיום נהנים ומרוויחים כפול
                        <br />
                      </span>
                    </p>
                    <p className="text">
                      <span className="text-wrapper-19">
                        בנוסף ל 15% הנחה, חברי פרמיום צוברים קרדיטים שמשמשים ככסף לרכישות הבאות. ככה אפשר להמשיך וליהנות
                        מיתרונות המוצרים ובפחות כסף.
                      </span>
                    </p>
                  </div>
                  <img className="symbol-2" alt="Symbol" src="/img/symbol-4.png" />
                  <div className="frame-7">
                    <div className="frame-8">
                      <div className="frame-9">
                        <div className="overlap-group-3">
                          <img className="image-2" alt="Image" src="/img/5.png" />
                          <p className="text-wrapper-20">
                            אני אדם שנמצא רוב הזמן בדרכים, נסיעות ארוכות ועד שמגיעה לארוחה הראשונה זה כבר צהריים ומאוד
                            רעבה. מאז שהתחלתי עם השייק של הולדון כבר יותר משנה אני פותחת את הבוקר שלי עם קפה קר וטעים
                            מאוד שהוא בעצם ארוחה מלאה שמזינה אותי ומסייעת לי להתחיל את הבוקר עם אנרגיות ולא עצבנית מרעב.
                            חד משמעית הדבר הכי טוב שעשיתי לעצמי!
                          </p>
                          <img className="ellipse-5" alt="Ellipse" src="/img/ellipse-9-4.png" />
                        </div>
                        <div className="text-wrapper-21">רותם</div>
                        <img className="line" alt="Line" src="/img/line-1-1.png" />
                      </div>
                      <div className="frame-10">
                        <div className="overlap-group-3">
                          <img className="image-2" alt="Image" src="/img/4.png" />
                          <p className="text-wrapper-20">
                            שייק הארוחה של הולד און סוגר לי את פינת ארוחת הבוקר. כל בוקר מכינה לעצמי שייק הולד און עם
                            קפה ופולי קקאו- גם טעים לי, גם משביע אותי וגם אני יודעת שהזנתי את עצמי (לפחות בארוחה הזו)
                            במיטב הויטמינים ובחלבונים. חייבת לציין שגם בדיקות הדם השתפרו מאז שסיגלתי לעצמי תזונה זו.
                          </p>
                          <img className="ellipse-5" alt="Ellipse" src="/img/ellipse-9-3.png" />
                        </div>
                        <div className="text-wrapper-22">הילה</div>
                        <img className="line-2" alt="Line" src="/img/line-1-1.png" />
                      </div>
                      <div className="frame-11">
                        <div className="overlap-group-3">
                          <img className="image-2" alt="Image" src="/img/3.png" />
                          <p className="text-wrapper-20">
                            אני מאוד מצטערת שלא היכרתי אותכם לפני הרבה שנים משקה השייק של הולד אוןזה ארוחת הבוקר והערב
                            הכי טובה משביעה וטעימה שאיי פעם אכלתי ונהנתי מארוחה טובה.
                          </p>
                          <img className="ellipse-6" alt="Ellipse" src="/img/ellipse-9-2.png" />
                        </div>
                        <div className="text-wrapper-23">כרמלה</div>
                        <img className="line-3" alt="Line" src="/img/line-1-1.png" />
                      </div>
                      <div className="frame-12">
                        <div className="overlap-group-3">
                          <img className="image-2" alt="Image" src="/img/1-2x.png" />
                          <p className="text-wrapper-20">
                            החיים שלי לפני ואחרי השייק שונים לגמרי, אני פותח איתו את היום, הטעם שלו מעולה וזה מחזיק אותי
                            לפחות 4 שעות עד הארוחה הבאה. גם אישתי נהנית מהשייק, כבר אין לה חשקים, פחות עייפות, מרגישה
                            יותר טוב. במקום לפתוח את הבוקר עם פרוסת עוגה, היא שמה בשייק פרי או קקאו והיא נהנית. מומלץ
                            מאוד.
                          </p>
                          <img className="ellipse-5" alt="Ellipse" src="/img/ellipse-9-1.png" />
                        </div>
                        <div className="text-wrapper-24">ניסים</div>
                        <img className="line-4" alt="Line" src="/img/line-1-1.png" />
                      </div>
                      <div className="frame-13">
                        <div className="overlap-7">
                          <img className="image-2" alt="Image" src="/img/1-2x.png" />
                          <p className="text-wrapper-20">
                            בעיקרון השייק פותר לי ארוחת בוקר שתמיד היה לי קושי בבוקר להכניס משהו לפה. ככה אני שותה אותו
                            בבוקר וסוגרת 4 שעות מאז הקימה שזה שעות הקושי שלי להכניס משהו לפה.
                          </p>
                          <img className="female-profile" alt="Female profile" src="/img/female-profile.png" />
                        </div>
                        <div className="text-wrapper-23">רחל</div>
                        <img className="line-3" alt="Line" src="/img/line-1-1.png" />
                      </div>
                      <div className="frame-14">
                        <div className="overlap-8">
                          <img className="image-3" alt="Image" src="/img/image.svg" />
                          <p className="text-wrapper-25">
                            אני כבר כ5 חודשים שותה את השייק חלבון של הולד-און.השייק הוא ארוחה קלה, כ22 גרם חלבון אני אמא
                            ל3 כך שאין לי הרבה זמן להכין ארוחת בוקר עשירה בוויטמינים וחלבון אז זה חוסך לי את זמן הכנה -
                            מכינה את השייק ב2 דק בלבד.השייק טעים בטירוף ! סוגר לי את הפינה של ארוחת בוקר מלאה בחלבון
                            וויטמינים, ומשביע ל3 שעות ממליצה מאוד על השייק של Hold-on
                          </p>
                          <img className="ellipse-7" alt="Ellipse" src="/img/ellipse-9.png" />
                        </div>
                        <div className="text-wrapper-26">עדן</div>
                        <img className="line-5" alt="Line" src="/img/line-1.svg" />
                      </div>
                    </div>
                    <div className="ellipse-8" />
                    <div className="ellipse-9" />
                    <div className="ellipse-10" />
                    <div className="ellipse-11" />
                    <div className="ellipse-12" />
                    <div className="ellipse-13" />
                    <div className="ellipse-14" />
                    <div className="ellipse-15" />
                    <div className="ellipse-16" />
                    <div className="ellipse-17" />
                    <div className="ellipse-18" />
                    <div className="ellipse-19" />
                    <div className="ellipse-20" />
                    <div className="ellipse-21" />
                    <div className="ellipse-22" />
                    <div className="ellipse-23" />
                    <div className="ellipse-24" />
                    <div className="ellipse-25" />
                    <div className="ellipse-26" />
                    <div className="ellipse-27" />
                    <div className="ellipse-28" />
                    <div className="ellipse-29" />
                    <div className="ellipse-30" />
                    <div className="ellipse-31" />
                    <div className="ellipse-32" />
                    <div className="ellipse-33" />
                    <div className="ellipse-34" />
                    <div className="ellipse-35" />
                    <div className="ellipse-36" />
                    <div className="ellipse-37" />
                    <div className="ellipse-38" />
                    <div className="ellipse-39" />
                    <div className="ellipse-40" />
                    <div className="ellipse-41" />
                    <div className="ellipse-42" />
                    <div className="ellipse-43" />
                  </div>
                  <div className="frame-15">
                    <div className="overlap-9">
                      <img className="element-8" alt="Element" src="/img/1.png" />
                      <div className="frame-16">
                        <img className="element-9" alt="Element" src="/img/1-2.png" />
                        <img className="element-10" alt="Element" src="/img/1-1-2x.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-17">
              <div className="frame-18">
                <div className="text-wrapper-27">מעבר לחנות &gt;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
