import { Box, Button, Typography } from "@mui/material";
import pic7 from "assets/images/site/premium.png";
import pic7h from "assets/images/site/premium_h.png";

type Props = {
  nextStep: () => void;
  lastStep: () => void;
  isH: boolean;
};

const Step7 = (props: Props) => {
  const { nextStep, lastStep, isH } = props;

  return (
    <Box>
      <Box sx={{ maxWidth: "400px", margin: "auto" }}>
        {isH && <img src={pic7h} alt="premium" width="100%" />}
        {!isH && <img src={pic7} alt="premium" width="100%" />}
      </Box>
      <Box>
        <Typography variant="h4" color="primary.main" textAlign="center">
          <span>החברים באתר נהנים מ:</span>
        </Typography>
      </Box>
      <Box sx={{ mb: 4, textAlign: "start" }}>
        <ul className="check" style={{ marginTop: "5px" }}>
          <li>טיפים לניהול אורח חיים בריא</li>
          <li>הנחות והטבות מטורפות</li>
          <li>מוצרים איכותיים וסופר טעימים</li>
          <li>ועוד הפתעות</li>
        </ul>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ fontSize: "1.5rem" }}
          onClick={nextStep}
        >
          הבנתי, מצויין
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography
          variant="body1"
          color="primary.main"
          onClick={lastStep}
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "1.2rem",
          }}
        >
          כבר מכיר, מעוניין להירשם
        </Typography>
      </Box>
    </Box>
  );
};

export default Step7;
