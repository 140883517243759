import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
const LoadingButtonWrapper = (props: any) => {
  const { children, loading, ...otherProps } = props;
  //const [loading, setLoading] = useState(false);
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    //setLoading(true);
    submitForm();
    //.finally(() => {
    // setLoading(false);
    //});
  };

  // const configButton = {
  //   variant: "contained",
  //   color: "primary",
  //   fullWidth: true,
  //   onClick: handleSubmit,
  // };
  //{...configButton}
  return (
    <LoadingButton
      variant="contained"
      color="primary"
      loading={loading}
      fullWidth
      onClick={handleSubmit}
      {...otherProps}
    >
      {children}
    </LoadingButton>
  );
};

export default LoadingButtonWrapper;
