import { types, flow, applySnapshot } from "mobx-state-tree";
import apiService from "api/api.service";

//import { types, Instance, flow, applySnapshot, getParent, cast } from "mobx-state-tree";

export interface ISettingsStoreState {
  vpRate: number;
  handleFee: number;
  creditGain: number;
  shippingFee: number;
  shippingThreshold: number;
  premiumThreshold: number;
}

export const settingsStoreInitialState: ISettingsStoreState = {
  vpRate: 1.1,
  handleFee: 13,
  creditGain: 10,
  shippingFee: 37,
  shippingThreshold: 350,
  premiumThreshold: 800,
};

export const SettingsStore = types
  .model("SettingsStore", { ...settingsStoreInitialState })
  .views((store) => ({
    get handlFeeMultiplier() {
      return (store.handleFee + 100) / 100;
    },
    get creditGainMultiplier() {
      return store.creditGain / 100;
    },
    get multiVpRateHandleFee() {
      return (store.vpRate * (100 + store.handleFee)) / 100;
    },
    get vpToAmount() {
      return (store.vpRate * (100 + store.handleFee)) / 100;
    },
  }))
  .actions((store) => ({
    afterCreate: flow(function* afterCreate() {
      try {
        const response = yield apiService.get<ISettingsStoreState>("shopConfig");
        applySnapshot(store, response);
      } catch (error) {
        console.log("applying default settings values");
        console.log(error);
        applySnapshot(store, settingsStoreInitialState);
      }
    }),
  }));
