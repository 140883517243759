import React from "react";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import { useMst } from "stores/RootStore";
import Grid from "@mui/material/Grid";
import { CURRENCY, VP } from "utils/constant";
import Typography from "@mui/material/Typography";

interface Props {}

const TotalToPay = (props: Props) => {
  const { user, shop } = useMst();

  //calculate FinalPrice
  //calculate orderVp
  //calculate discount
  //calculate credit gained or stars
  const GainOrder = () => {
    const message = user.isMarketer
      ? `הזמנה זו מזכה ב ${shop.orderVp.toFixed(2)} נקודות`
      : user.isPremium
      ? `הזמנה זו מזכה ב ${shop.creditGain.toFixed(2)} ${CURRENCY} קרדיטים לקניה הבאה`
      : `הזמנה זו מזכה ב: ${shop.cartPrice.toFixed(2)} כוכבים`;

    return (
      <Typography variant="caption" color="initial" textAlign="start" component="div">
        {message}
      </Typography>
    );
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={8} sx={{ textAlign: "start" }}>
          דמי משלוח:
          {!shop.shippingFee && (
            <Box component="span" sx={{ p: 2, color: "#454947", fontSize: "smaller" }}>
              משלוח חינם
            </Box>
          )}
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "end" }}>
          {shop.shippingFee.toFixed(2)} {CURRENCY}
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={1}>
        <Grid item xs={8} sx={{ textAlign: "start" }}>
          סה"כ לתשלום:
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "end", fontSize: "large" }}>
          {shop.orderToPay.toFixed(2)} {CURRENCY}
        </Grid>
      </Grid>
      <GainOrder />
      <hr />
    </Box>
  );
};

export default observer(TotalToPay);
