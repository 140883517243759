import { createContext, useState, useContext } from "react";
import * as React from "react";

type DrawerContextType = {
  open: boolean;
  handleMenuToggle: () => void;
};

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

const DrawerContextProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);

  const handleMenuToggle = () => {
    setOpen(!open);
  };

  return (
    <DrawerContext.Provider value={{ open, handleMenuToggle }}>
      {children}
    </DrawerContext.Provider>
  );
};

const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error(
      "useDrawerContext must be used within a DrawerContextProvider"
    );
  }
  return context;
};

export { DrawerContextProvider, useDrawerContext };
