import { AppBar, Badge, Hidden, IconButton, Toolbar, Typography } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import LogoImage from "assets/images/vlogo.png";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/system";
import Navbar from "./Navbar";
import SideDrawer from "./SideDrawer";
import { useDrawerContext } from "contexts/drawer-context";
import { useMst } from "stores/RootStore";

import { observer } from "mobx-react-lite";
import { CURRENCY, VP } from "utils/constant";
import { paths } from "route-paths";

interface Props {}

const navLinks = [
  { title: "דף הבית", path: paths.home },
  { title: "הסיפור שלנו", path: paths.about },
  { title: "המוצרים שלנו", path: paths.shop },
  { title: "טיפים", path: paths.tips + "/2minShake" },
  // { title: "בלוג", path: "/blog" },
  { title: "איזור אישי", path: paths.myAccount, type: "user" },
  { title: "מה זה חבר מועדף", path: paths.premium },
  { title: "הירשם לאתר", path: paths.registration, type: "anonymous" },
  { title: "צור קשר", path: paths.contact },
  { title: "שתף", path: paths.share, type: "user" },
];

const Coupon = styled("span")`
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  padding-left: 5px;
  padding-right: 10px;
`;

const HeaderMt = (props: Props) => {
  const { handleMenuToggle } = useDrawerContext();
  const { user, shop } = useMst();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginToggle = () => {
    if (user.isAuthenticated) {
      navigate("/logout");
    } else {
      user.toggleLogin();
    }
  };

  const getTopMessage = () => {
    let stars = "";
    let coupon = "";
    let balance = "";
    let credit = "";
    if (user.isNoRole) {
      stars = `צברת עד עכשיו:${user.stars.toFixed(2)} כוכבים`;
    }
    if (user.isMarketer && user.coupon > 0) {
      coupon = `לזכותך: ${user.coupon.toFixed(2)} ${VP} בקופונים `;
    }
    // if (user.isPremium && user.credit > 0) {
    //   credit = `לזכותך: ${CURRENCY}${user.credit} בקרדיט `;
    // }
    if (user.isPremium && user.balance > 0) {
      balance = `יתרת זכות לקניה באתר: ${CURRENCY}${user.balance.toFixed(2)}`;
    }
    //return `${stars}${coupon}${credit}`;
    return (
      <span>
        <Coupon>{stars}</Coupon>
        <Coupon>{coupon}</Coupon>
        {/* <Coupon>{credit}</Coupon> */}
        <Coupon>{balance}</Coupon>
      </span>
    );
  };
  //const handleMenuToggle = () => {};
  //const isAuthenticated = user.isAuthenticated;
  const topMessage = user.isAuthenticated ? getTopMessage() : "לקבלת הנחת חבר וצבירת קרדיטים היכנס או הירשם לאתר";

  // ! remove if header is not needed
  //if (!user.isAuthenticated && location.pathname.indexOf("/join") > -1)
  //   return null;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "secondary.main",
          color: "#eee",
          height: "1rem",
        }}
      ></Box>

      <AppBar
        position="static"
        sx={{
          background: "#FFF",
          color: "#444",
          //zIndex: zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Box
            sx={{
              flex: 1,
              textAlign: "start",
            }}
          >
            <Box
              sx={{
                display: { sm: "block", xs: "block" },
              }}
            >
              <IconButton aria-label="כניסה למערכת" onClick={handleLoginToggle}>
                <PersonIcon />
                <Typography sx={{ display: { xs: "inherit", sm: "inherit" } }} variant="caption">
                  {user.isAuthenticated ? (
                    <>
                      <Hidden xsDown> שלום {user.firstName}</Hidden> התנתק
                    </>
                  ) : (
                    <>כניסה</>
                  )}
                </Typography>
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ margin: "auto" }}>
            <img src={LogoImage} alt="HoldOn" style={{ maxHeight: "70px" }} />
          </Box>
          <Box
            sx={{
              flex: 1,
              textAlign: "end",
            }}
          ></Box>
        </Toolbar>
        <SideDrawer navLinks={navLinks} />
      </AppBar>
    </>
  );
};

export default observer(HeaderMt);
