import { types } from "mobx-state-tree";
export const AddressModel = types.model("AddressModel", {
  firstName: "",
  lastName: "",
  phone: "",
  street: "",
  home: "",
  apartment: "",
  city: "",
  zipcode: "",
  shipmentComment: "",
});
